import Button from "./Button";
import Modal from "./Modal";
import { signout } from "../services/firebase";
import { setUserId, setUserType } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// appears when a user clicks on 'Logout' button on the landing page
export const LogoutModal = ({ open, setOpen, calledBy }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    dispatch(setUserId(""));
    dispatch(setUserType(""));
    await signout();
    navigate("/", { replace: true });
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {/* Text lines */}
      <div className="text-center">
        <span className="text-lg font-bold">Logout</span>

        <hr className="mt-4 lg:mt-8 w-full text-gray-400" />

        <p className="text-sm font-bold mt-10">
          Are you sure you want to logout?
        </p>
      </div>

      {/* buttons at the bottom of the modal */}
      <div className="mt-12 mb-4 lg:mb-8 grid grid-cols-2 justify-items-center gap-6">
        <Button label="No" onClick={() => setOpen(false)} type="secondary" />
        <Button label="Yes" onClick={async () => await logout()} />
      </div>
    </Modal>
  );
};
