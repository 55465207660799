import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationPanel } from "../../components/NavigationPanel";
import SendBidModal from "./Modals/SendBidModal";
import AllImagesModal from "./Modals/AllImagesModal";
import PLImage from "../../assets/images/bg.png";
import { Loader } from "../../components/Loader";
import {
  getPostByRef,
  getPostBidsByDealerRef,
  getDealerByRef,
  getPostBids,
} from "../../services/firebase";
import { useSelector } from "react-redux";
import { Logout } from "../../components/Logout";
import { Footer } from "../../components/Footer";
import { distanceMatrix } from "../../common/maps";

export const CarDetails = () => {
  const { id } = useParams();
  const userId = useSelector((state) => state.user.userId);
  const [post, setPost] = useState("");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [bid, setBid] = useState("");
  const [distance, setDistance] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [bidAmount, setBidAmount] = useState("");

  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  useEffect(() => {
    const fetchPost = async () => {
      const response = await getPostByRef(id);
      const postBids = await getPostBids(id);
      setTimeout(() => {
        const thisBid = postBids.filter((bids) => bids.dealer === userId);
        setBidAmount(thisBid[0]?.amount);
        setPost(response);
        setImageList(Object.values(response.vehicleDetails.carImageURLs));
      }, 1000);
      const bids = await getPostBidsByDealerRef(userId);
      bids.forEach((bid) => {
        if (bid.post === id) {
          setBid({ ...bid });
        }
      });
      const dealer = await getDealerByRef(userId);
      const distance = await distanceMatrix(response?.postedBy?.placeId, [
        { placeId: dealer?.placeId },
      ]);
      setDistance(Math.ceil(distance[0]));
    };
    if (!post || post === "") {
      fetchPost();
    }
  }, [id, post, bid, userId]);

  const getFeatures = (props) => {
    let features = "";
    if (props.ABS) {
      features = features + "ABS, ";
    }
    if (props.AC) {
      features = features + "AC, ";
    }
    if (props.bluetooth) {
      features = features + "Bluetooth, ";
    }
    if (props.childLock) {
      features = features + "Child Lock, ";
    }
    if (props.navigation) {
      features = features + "Navigation, ";
    }
    if (props.speakers) {
      features = features + "Speakers, ";
    }
    if (props.wingMirrors) {
      features = features + "Wing Mirrors, ";
    }
    if (features === "") {
      features = features + "None";
    }
    return features;
  };

  const getVisibleDefects = (props) => {
    let features = "";
    if (props.chippedPaint) {
      features = features + "Chipped Paint, ";
    }
    if (props.dents) {
      features = features + "Dents, ";
    }
    if (props.dings) {
      features = features + "Dings, ";
    }
    if (props.hailDamage) {
      features = features + "Hail Damage, ";
    }
    if (props.rust) {
      features = features + "Rust, ";
    }
    if (props.scratches) {
      features = features + "Scratches, ";
    }
    if (props.scuffs) {
      features = features + "Scuffs, ";
    }
    if (features === "") {
      features = features + "None";
    }
    return features;
  };

  const getDamageTypes = (props) => {
    let features = "";
    if (props.accident === "Yes") {
      features = features + "Accident, ";
    }
    if (props.floodDamage === "Yes") {
      features = features + "Flood, ";
    }
    if (props.frameDamage === "Yes") {
      features = features + "Frame, ";
    }
    if (features === "") {
      features = features + "None";
    }
    return features;
  };

  const handleChange = (value) => {
    setBidAmount(value);
  };

  return (
    <div className={post === "" ? "flex h-screen" : "flex"}>
      <NavigationPanel user="dealer" allCars={true} />
      <div className="flex flex-col w-full px-6 sm:pl-12 sm:pr-10">
        <div className="flex mt-8">
          <div className="w-full mt-20 sm:mt-0">
            {/* page title */}
            <span className="text-3xl font-bold">All Cars / Car Details</span>
          </div>
        </div>

        <Logout calledBy={"Dealer"} />

        {/* Page Content */}
        <div className="mt-8 flex flex-col h-full overscroll-contain overflow-auto justify-between">
          {post === "" ? (
            <div
              className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
              style={{
                marginTop: `${windowHeight / 3}px`,
              }}
            >
              <Loader showLoader={""} />
            </div>
          ) : (
            <>
              {post && (
                <>
                  <div className="mt-8 items-center">
                    <div
                      className="grid grid-cols-3 grid-row-3 sm:grid-cols-4 sm:grid-rows-2 sm:grid-flow-col gap-4"
                      style={{ height: "400px" }}
                    >
                      <div className="w-full col-span-3 row-span-2 sm:col-span-2 sm:row-span-2">
                        <img
                          src={post?.coverPhoto || PLImage}
                          alt="sample"
                          className="inset-0 h-full w-full object-cover object-center rounded-lg opacity-75 hover:opacity-100"
                        />
                      </div>
                      <div className="w-full sm:col-span-2 sm:row-span-1">
                        <img
                          src={imageList?.[1] || PLImage}
                          alt="sample"
                          className="inset-0 h-full w-full object-cover object-center rounded-lg opacity-75 hover:opacity-100"
                        />
                      </div>
                      <div className="w-full">
                        <img
                          src={imageList?.[2] || PLImage}
                          alt="sample"
                          className="inset-0 h-full w-full object-cover object-center rounded-lg opacity-75 hover:opacity-100 "
                        />
                      </div>
                      <AllImagesModal images={imageList} />
                    </div>
                  </div>
                  <div className="my-8 xl:grid xl:grid-cols-10 xl:grid-rows-10 gap-4">
                    <div className="col-span-6 row-span-6 px-">
                      <div className="col-span-6">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                          {`${post?.vehicleDetails.year} ${post?.vehicleDetails.make} ${post?.vehicleDetails.model}`}
                        </h5>
                        <p className="text-gray-400">
                          Mileage : {post?.vehicleDetails?.mileage}
                        </p>
                      </div>
                      <div className="col-span-6 start-row-2">
                        <div className="grid md:grid-cols-1 lg:grid-cols-3">
                          <div className="text-left  mt-5">
                            <h5 className="text-2xl font-bold tracking-tight">
                              {post.vehicleDetails.transmission}
                            </h5>
                            <p className="text-gray-400">transmission</p>
                          </div>
                          <div className="text-left  mt-5">
                            <h5 className="text-2xl font-bold tracking-tight">
                              {post.vehicleDetails.drive}
                            </h5>
                            <p className="text-gray-400">Type</p>
                          </div>
                          <div className="text-start mt-5">
                            <h5 className="text-2xl font-bold tracking-tight">
                              {post.VIN}
                            </h5>
                            <p className="text-gray-400">VIN</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6 start-row-2 bg-white">
                        <p className="pb-2 pt-5 border-b">
                          <span>Miles on tyres</span>
                          <span className="font-bold float-right">
                            {post?.additionalInformation?.milesOnTyres ===
                            undefined
                              ? "None"
                              : post?.additionalInformation?.milesOnTyres}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Feature</span>
                          <span className="font-bold float-right">
                            {getFeatures(post.vehicleDetails.features) ===
                            "None"
                              ? "None"
                              : getFeatures(post.vehicleDetails.features).slice(
                                  0,
                                  -2
                                )}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Visible Defects</span>
                          <span className="font-bold float-right">
                            {getVisibleDefects(
                              post.exteriorInteriorCondition
                            ) === "None"
                              ? "None"
                              : getVisibleDefects(
                                  post.exteriorInteriorCondition
                                ).slice(0, -2)}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Damage</span>
                          <span className="font-bold float-right">
                            {getDamageTypes(
                              post.vehicleDetails.additionalInformation
                            ) === "None"
                              ? "None"
                              : getDamageTypes(
                                  post.vehicleDetails.additionalInformation
                                ).slice(0, -2)}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Windshield replacement</span>
                          <span className="font-bold float-right">
                            {post.exteriorInteriorCondition.replaceWindSheild}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Frame Damage</span>
                          <span className="font-bold float-right">
                            {post.exteriorInteriorCondition.frameDamage}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Burns or stains</span>
                          <span className="font-bold float-right">
                            {post.exteriorInteriorCondition.seatBurnsRipsTear}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Vehicle been smoked in?</span>
                          <span className="font-bold float-right">
                            {post.exteriorInteriorCondition.smokedIn}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Vehicle Drive</span>
                          <span className="font-bold float-right">
                            {post.mechanicalCondition.drive}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Dashboard Warning</span>
                          <span className="font-bold float-right">
                            {post.mechanicalCondition.warningLights}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Odometer replaced or modified?</span>
                          <span className="font-bold float-right">
                            {post.mechanicalCondition.odometerReplace}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Mechanical repairs needed?</span>
                          <span className="font-bold float-right">
                            {post.mechanicalCondition.repairsNeeded}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Number of keys?</span>
                          <span className="font-bold float-right">
                            {post.additionalInformation.keys}
                          </span>
                        </p>
                        <p className="pb-2 pt-5 border-b">
                          <span>Aftermarket parts installed?</span>
                          <span className="font-bold float-right">
                            {post.additionalInformation.aftermarksParts}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-span-4 start-col-7 row-span-6 xl:ml-8 mt-10 xl:mt-4">
                      <div className="bg-white rounded-lg xl:border xl:border-gray-200 xl:p-3">
                        <div className="xl:p-6">
                          <h5 className="mb-6 xl:mb-2 text-2xl font-bold tracking-tight">
                            Seller Details
                          </h5>
                          <div>
                            {bid !== "" && (
                              <p className="pb-2 pt-3 border-b">
                                <span>Offered Price</span>
                                <span className="font-bold float-right">
                                  ${bidAmount}
                                </span>
                              </p>
                            )}
                            <p className="pb-2 pt-3 border-b">
                              <span>Name</span>
                              <span className="font-bold float-right">
                                {post.postedBy.name}
                              </span>
                            </p>
                            <p className="pb-2 pt-3 border-b">
                              <span>Email</span>
                              <span className="font-bold float-right">
                                {post.postedBy.email}
                              </span>
                            </p>
                            <p className="pb-5 pt-3">
                              <span>Away from you</span>
                              <span className="font-bold float-right">
                                {distance} miles
                              </span>
                            </p>
                          </div>
                          <SendBidModal
                            bidPost={post}
                            isPlaced={bid}
                            bidAmount={bidAmount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* footer */}
          <div className="flex items-stretch mt-10 -ml-9">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
