import { ReactComponent as CarPosted } from "../assets/SVG/SuccessTick.svg";
import { ReactComponent as OfferReminder } from "../assets/SVG/AlertReminder.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRealTimeNotifications } from "../services/notification";
import { setNotificationView } from "../redux/slices/userSlice";

export const Notifications = ({ calledBy }) => {
  const [notifications, setNotifications] = useState([]);
  const userId = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();

  useEffect(() => {
    const getNotifications = async () => {
      getRealTimeNotifications(userId, calledBy, setNotifications);
      dispatch(setNotificationView(true));
    };

    if (notifications.length === 0) getNotifications();
  }, [notifications, calledBy, userId, dispatch]);

  return (
    <div className="sm:mr-8 mt-5 py-4 px-6">
      {/* update heading section */}
      <div className="w-full border-0 rounded-xl mb-5 bgImage py-14 pl-10">
        <p className="text-white font-bold text-2xl">Updates for you</p>
      </div>

      {/* Today heading */}
      <div className="flex mt-10">
        <div className="w-full">
          <span className="text-2xl font-bold text-gray-500">Today</span>
        </div>
      </div>

      {/* in-depth details of notifications */}
      <div className="grid grid-rows mt-6 border-2 rounded-xl p-5 space-y-5">
        {/* rows */}
        {notifications.length > 0 ? (
          notifications.map((notification, i) => {
            return (
              <div key={i} className="shadow-lg rounded-lg shadow-gray-200 p-5">
                {notification.type === "postCreation" ||
                notification.type === "reminder" ? (
                  <>
                    {notification.type === "postCreation" ? (
                      <div className="flex flex-row items-center gap-5">
                        {/* post creation notification */}
                        <div>
                          <CarPosted />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-bold">Car Posted!</p>
                          {calledBy === "Seller" ? (
                            <p className="text-gray-400">
                              Your Car{" "}
                              {notification?.postYear +
                                " " +
                                notification?.postMake +
                                " " +
                                notification?.postModel}{" "}
                              has been successfully listed for dealers, soon
                              you'll receive offers
                            </p>
                          ) : (
                            <p className="text-gray-400">
                              A car{" "}
                              {notification?.postYear +
                                " " +
                                notification?.postMake +
                                " " +
                                notification?.postModel}{" "}
                              has been posted in your region. Kindly take a look
                              and place your bid.
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-5">
                        {/* car post details */}
                        <div>
                          <OfferReminder />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-bold">Offer Reminder!</p>
                          <p className="text-gray-400">
                            You have not yet placed a bid on{" "}
                            {notification?.postYear +
                              " " +
                              notification?.postMake +
                              " " +
                              notification?.postModel}{" "}
                            that has been posted in your region. Kindly take a
                            look and place your bid.
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex flex-row items-center gap-5">
                    {/* car bid details */}
                    <div>
                      <CarPosted />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-bold">Bid Placed!</p>
                      {calledBy === "Dealer" ? (
                        <p className="text-gray-400">
                          Your bid on{" "}
                          {notification?.postYear +
                            " " +
                            notification?.postMake +
                            " " +
                            notification?.postModel}
                          , is placed successfully. You'll get response from the
                          seller soon.
                        </p>
                      ) : (
                        <p className="text-gray-400">
                          A bid is placed on{" "}
                          {notification?.postYear +
                            " " +
                            notification?.postMake +
                            " " +
                            notification?.postModel}
                          , Kindly check it out.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <center>
            <i className="text-gray-500 font-bold">No notification found!</i>
          </center>
        )}
      </div>
    </div>
  );
};
