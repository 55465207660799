import { Link } from "react-router-dom";
import { Image } from "../components/Image";
import Logo from "../assets/images/Logo.png";
import UserIcon from "../assets/SVG/UserIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { initComState, resetCarDetails } from "../redux/slices/comSlice";

// appears on the top of Home page
export const NavBar = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);

  const nextSellerPage =
    userType === "seller" && userId !== "" ? "/landingPage" : "/login/form";
  const nextDealerPage =
    userType === "dealer" && userId !== ""
      ? "/dealer/dashboard"
      : "/login/dealerForm";

  return (
    <div className="flex justify-between w-10/12 mt-10 gap-2">
      {/* Logo on left */}
      <div className="">
        <img src={Logo} alt="logo" />
      </div>

      {/* other buttons on right */}
      <div className="mt-2 flex gap-2 sm:gap-6">
        <Link
          to="/signup"
          className="font-bold top-text"
          onClick={() => {
            dispatch(resetCarDetails(initComState));
          }}
        >
          Sell my car
        </Link>

        <Link to={nextDealerPage} className="font-bold top-text">
          Dealer portal
        </Link>

        <div className="flex gap-2 items-center -mt-2">
          <Image image={UserIcon} />

          <Link to={nextSellerPage} className="text-login font-bold">
            {userType === "seller" && userId !== "" ? "Posts" : "Login"}
          </Link>
        </div>
      </div>
    </div>
  );
};
