import { createSlice } from "@reduxjs/toolkit";

export const initPostState = {
  postId: "",
};

const postSlice = createSlice({
  name: "post",
  initialState: initPostState,
  reducers: {
    setPostId: (state, action) => {
      state.postId = action.payload;
    },
  },
});

export const { setPostId } = postSlice.actions;

export default postSlice.reducer;
