import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "../../services/firebase";
import { Loader } from "../../components/Loader";
import { ReactComponent as LostPasswordSVG } from "../../assets/SVG/LostPassword.svg";
import { ReactComponent as EmailSVG } from "../../assets/SVG/Email.svg";
import { LoginGraphicsLeft } from "../../components/LoginGraphics";

export const RecoverPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  // Field Animations
  const [emailPlaceholder, setEmailPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if email field is left empty
  const missingFields = () =>
    setErrorMessage("Recovery email address not provided.");

  // validation alert if provided email is empty
  const invalidEmail = () => setErrorMessage("Invalid Email address provided");

  // validation alert if provided email isn't registered with mtd
  const email404 = () =>
    setErrorMessage("Provided email isn't associated with any existing user");

  // validation alert if recovery email has been sent to the provided email
  const sendingPassed = () =>
    setErrorMessage("Recovery email has been sent to the provided address.");

  // validation alert if recovery email couldn't be sent to the provided email
  const sendingFailed = () =>
    setErrorMessage("Could not send recovery email. Please try again.");

  // updating if anything is missing or not, whenever user inputs something
  useEffect(() => {
    if (email?.length <= 1) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [email]);

  // process of sending recovery email
  const sendResetEmail = async () => {
    try {
      await resetPassword(email);
      setSuccessfullySent(true);
      setAnythingMissing(false);
      setShowLoader(false);
      sendingPassed();
    } catch (error) {
      setShowLoader(false);
      setSuccessfullySent(false);
      sendingFailed();
      if (error.code === "auth/invalid-email") {
        invalidEmail();
      }
      if (error.code === "auth/user-not-found") {
        email404();
      }
      console.log("Error: ", error);
    }
  };

  return (
    <div className="justify-center items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center">
        {/* graphics on the left half */}
        <LoginGraphicsLeft
          text="Don't have an account?"
          label="Sign up"
          path="/signup"
        />

        {/* fields on the right half */}
        <div className="flex flex-col justify-center items-center h-screen">
          {/* Graphics on top */}
          <div>
            <LostPasswordSVG />
          </div>

          {/* Headlines */}
          <span className="text-3xl font-bold text-center mt-10">
            Lost your password? <br /> Enter your details to recover
          </span>
          <span className="font-normal text-sm text-center text-gray-400 mt-4">
            Enter your details to proceed further
          </span>

          {/* Email input text fields */}
          <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-10 rounded-lg py-2 px-4 justify-between">
            <div className="w-11/12">
              <span className={emailPlaceholder} style={{ zIndex: "-1" }}>
                Email
              </span>
              <input
                onBlur={() => {
                  setEmailFocused(false);
                  if (email.length >= 1) {
                    setEmailPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setEmailPlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setEmailFocused(true);
                  setEmailPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type="text"
                title="Email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            {/* email icon */}
            <div>
              <EmailSVG
                fill={emailFocused || email?.length > 0 ? "black" : "#9A9EA7"}
              />
            </div>
          </div>

          {/* Error Message */}
          <div className="w-11/12 sm:w-96">
            <p
              style={{
                color: !anythingMissing && successfullySent ? "green" : "red",
                fontSize: "15px",
              }}
            >
              {errorMessage}
            </p>
          </div>

          {/* Recover button */}
          <Link to="" className="w-11/12 sm:w-96">
            <button
              onClick={async () => {
                if (anythingMissing) {
                  missingFields();
                } else {
                  setShowLoader(true);
                  await sendResetEmail();
                }
              }}
              className="flex justify-center items-center w-full h-14 rounded-xl bg-blue-500 text-sm text-white font-bold mt-7"
            >
              <Loader showLoader={showLoader ? "" : "hidden"} />
              Recover
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
