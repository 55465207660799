import { createSlice } from '@reduxjs/toolkit'

export const initMainState = {
	test: true,
}

const mainSlice = createSlice({
	name: 'main',
	initialState: initMainState,
	reducers: {
		setTest: (state, action) => {
			state.test = action.payload
		},
	},
})

export const { setTest } = mainSlice.actions

export default mainSlice.reducer
