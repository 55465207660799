// appears on the very bottom of the multi-steps form, indicating user his progress
export const ProgressIndicator = (props) => {
  return (
    <div className="bg-white fixed left-0 bottom-3 w-full border-t-8 border-white">
      <div className="border-t-8 border-white" aria-hidden="true">
        {props?.sections === 2 ? (
          <div className="grid grid-cols-2 text-xs sm:text-sm font-bold pb-2">
            {/* first step -> car details */}
            {props.width === "45%" ? (
              <>
                <div className="text-center text-gray-900">Car Details</div>
                <div className="text-center text-gray-400">Confirmation</div>
              </>
            ) : (
              <>
                {/* last step -> sign up confirmation page */}
                <div className="text-center text-gray-900">Car Details</div>
                <div className="text-center text-gray-900">Confirmation</div>
              </>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-3 text-xs sm:text-sm font-bold pb-2">
            {/* first step -> car details */}
            {props.width === "30%" ? (
              <>
                <div className="text-center text-gray-900">Car Details</div>
                <div className="text-center text-gray-400">
                  Personal Details
                </div>
                <div className="text-center text-gray-400">Confirmation</div>
              </>
            ) : (
              <>
                {/* second step -> personal details */}
                {props.width === "60%" ? (
                  <>
                    <div className="text-center text-gray-900">Car Details</div>
                    <div className="text-center text-gray-900">
                      Personal Details
                    </div>
                    <div className="text-center text-gray-400">
                      Confirmation
                    </div>
                  </>
                ) : (
                  <>
                    {/* last step -> sign up confirmation page */}
                    <div className="text-center text-gray-900">Car Details</div>
                    <div className="text-center text-gray-900">
                      Personal Details
                    </div>
                    <div className="text-center text-gray-900">
                      Confirmation
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* actual blue line on the bottom that moves left/right */}
        <div className="flex overflow-hidden rounded-full bg-gray-200">
          <div
            className="fixed h-1.5 rounded-full bg-blue-500 bottom-2"
            style={{ width: props?.width }}
          />
          <svg
            className="fixed bottom-0 -left-5"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: props?.width }}
          >
            <circle cx="10.5" cy="10.5" r="10.5" fill="#3b82f6" />
            <circle cx="10.5" cy="10.5" r="3.5" fill="#F9FAFC" />
          </svg>
        </div>
      </div>
    </div>
  );
};
