import { useEffect, useState } from "react";
import {
  getAllBids,
  getDealerByRef,
  getSellerByRef,
} from "../services/firebase";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { Link } from "react-router-dom";
import NoResult from "../assets/images/no-results.png";
import { ReactComponent as ArrowhedUpSVG } from "../assets/SVG/ArrowheadUp.svg";
import { ReactComponent as ArrowhedDownSVG } from "../assets/SVG/ArrowheadDown.svg";
import { useSelector } from "react-redux";
import { ReactComponent as ViewSVG } from "../assets/SVG/View.svg";
import { SkeletonLoader } from "../components/SkeletonLoader";
import { Logout } from "../components/Logout";
import { distanceMatrix } from "../common/maps";

export const AllBids = () => {
  const userId = useSelector((state) => state.user.userId);

  const [bids, setBids] = useState("");
  const [unique, setUnique] = useState("");
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(0);
  const [sellerRef] = useState(userId);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // getting height of browser's internal window
  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  // to get number of bids received against each post
  const getBidsCount = (post) => {
    let count = 0;
    bids?.forEach((bid) => {
      if (post.post === bid?.post) {
        count += 1;
      }
    });
    return count;
  };

  // fetching posts to be displayed to the user logged in and categorizing it afterwards
  useEffect(() => {
    const getBids = async () => {
      const bids = await getAllBids(sellerRef);

      const uniquePosts = [
        ...new Map(bids.map((item) => [item["post"], item])).values(),
      ];

      setUnique(uniquePosts);
      if (bids === false) {
        setBids(false);
      } else {
        const newBids = await Promise.all(
          bids.map(async (bid) => {
            const dealer = await getDealerByRef(bid.dealer);
            const seller = await getSellerByRef(bid.seller);

            bid.milesAway = await getDistance(dealer, seller);
            return bid;
          })
        );
        setBids(newBids);
      }
    };

    if (!bids || bids === "") getBids();
  }, [bids, sellerRef]);

  const getDistance = async (dealer, seller) => {
    const distance = await distanceMatrix(seller?.placeId, [
      { placeId: dealer?.placeId },
    ]);

    const milesAway = Math.ceil(distance[0]);

    return milesAway;
  };

  return (
    <div className={bids === "" || bids.length < 5 ? "flex h-screen" : "flex"}>
      {/* left navigation */}
      <NavigationPanel bids={true} />

      {/* page title */}
      <div className="w-full flex flex-col px-6 sm:pl-12 sm:pr-8">
        <div className="flex mt-8 pb-4">
          <div className="w-full mt-20 sm:mt-0">
            <span className="text-3xl font-bold">Bids Received</span>
          </div>
        </div>

        <Logout calledBy={"Seller"} />
        <div className="flex flex-col space-y-5 justify-between mb-20">
          {bids === "" ? (
            <SkeletonLoader total={3} />
          ) : (
            <>
              {bids.length > 0 ? (
                <>
                  {/* car posts on which bids have been received */}
                  {unique?.map((post, i) => {
                    return (
                      <div
                        key={i}
                        className="mt-8 py-3 px-4 border-2 border-gray-100 rounded-xl"
                      >
                        <div className="flex flex-col sm:flex-row items-center gap-8">
                          {/* car image */}
                          <img
                            src={post?.postCoverURL}
                            alt="cover"
                            className="sm:h-20 aspect-video rounded-lg bg-no-repeat bg-cover"
                          />

                          <div className="flex flex-row items-center w-full justify-between">
                            {/* car into */}
                            <div className="flex flex-col gap-4">
                              {/* year, make, model */}
                              <p className="text-base font-bold">
                                {post?.postYear} {post?.postMake}{" "}
                                {post?.postModel}
                              </p>
                              <div className="flex flex-row gap-16 text-sm font-normal">
                                <div className="flex flex-col gap-2">
                                  <p>Miles away</p>
                                  <p>0 miles</p>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <p>Total Offers</p>
                                  <p>{getBidsCount(post)}</p>
                                </div>
                              </div>
                            </div>

                            {/* expand/retract button on right */}
                            <div className="" onClick={() => setOpen(!open)}>
                              <button
                                className="flex justify-center items-center w-10 h-10 rounded-lg bg-blue-500"
                                onClick={() => setExpanded(i)}
                              >
                                {expanded === i ? (
                                  open ? (
                                    <ArrowhedUpSVG />
                                  ) : (
                                    <ArrowhedDownSVG />
                                  )
                                ) : (
                                  <ArrowhedDownSVG />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* bids to display against each car post */}
                        {expanded === i && open && (
                          <div>
                            {/* bids metadata */}
                            <div className="bg-gray-100 mt-8 p-4 -mx-4 text-xs font-bold text-gray-400 hidden md:grid grid-cols-11 gap-4">
                              <p className="col-span-2">Buyer Name</p>
                              <p className="col-span-3">Buyer Email</p>
                              <p className="col-span-2">Offered On</p>
                              <p className="col-span-2">Price Offered</p>
                              <p className="col-span-2">Miles Away</p>
                            </div>

                            {/* dealer and bid details */}
                            {bids?.map((bid, j) => {
                              return (
                                post?.post === bid?.post &&
                                (windowWidth < 768 ? (
                                  <div
                                    key={j}
                                    className="w-full flex flex-col gap-2 mt-2"
                                  >
                                    <div className="flex flex-row justify-between items-center py-1 border-b">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Buyer Name
                                      </span>
                                      <span> {bid?.dealerName}</span>
                                    </div>
                                    <div className="flex flex-row justify-between items-center py-1 border-b">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Buyer Email
                                      </span>
                                      <span>{bid?.dealerEmail}</span>
                                    </div>
                                    <div className="flex flex-row justify-between items-center py-1 border-b">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Offered On
                                      </span>
                                      <span>
                                        {new Date(
                                          bid?.offeredOn?.seconds * 1000
                                        ).toLocaleDateString("en-US")}
                                      </span>
                                    </div>
                                    <div className="flex flex-row justify-between items-center py-1 border-b">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Price Offered
                                      </span>
                                      <span>{bid?.amount}</span>
                                    </div>
                                    <div className="flex flex-row justify-between items-center py-1 border-b">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Miles Away
                                      </span>
                                      <span>{bid?.milesAway}</span>
                                    </div>
                                    <div className="flex flex-row justify-between items-center pt-1">
                                      <span className="text-xs text-gray-400 font-bold">
                                        Buyer Details
                                      </span>
                                      <button>
                                        <Link
                                          to="/buyerDetails"
                                          state={{ bid }}
                                        >
                                          <ViewSVG />
                                        </Link>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="py-4 text-sm font-bold grid grid-cols-11 gap-4 mt-3"
                                    key={j}
                                  >
                                    <p className="col-span-2">
                                      {bid?.dealerName}
                                    </p>
                                    <p className="col-span-3">
                                      {bid?.dealerEmail}
                                    </p>
                                    <p className="col-span-2">
                                      {new Date(
                                        bid?.offeredOn?.seconds * 1000
                                      ).toLocaleDateString("en-US")}
                                    </p>
                                    <p className="col-span-2">{bid?.amount}</p>
                                    <p>{bid?.milesAway}</p>
                                    <div className="flex -mt-2 absolute right-12">
                                      <button>
                                        <Link
                                          to="/buyerDetails"
                                          state={{ bid }}
                                        >
                                          <ViewSVG />
                                        </Link>
                                      </button>
                                    </div>
                                  </div>
                                ))
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
                  style={{
                    marginTop: `${windowHeight / 4}px`,
                  }}
                >
                  <img className="w-44 " src={NoResult} alt="logo" />
                  <span className="text-4xl font-bold mt-8">
                    No Results Found
                  </span>
                  <span className="text-sm font-normal text-gray-400 mt-4">
                    Please try to search other vehicles
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {/* footer */}
        <div className="flex items-stretch h-full -ml-4">
          <div className="self-end w-full">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
