import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { createBid, updateBidByDealer } from "../../../services/firebase";
import { getDealerByEmail, getUserRef } from "../../../services/firebase";
import { Loader } from "../../../components/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NumericFormat } from "react-number-format";

const SendBidModal = ({ bidPost, isPlaced, bidAmount, onChange }) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [bid, setBid] = useState("");
  const [sellerMessage, setSellerMessage] = useState("");
  const [message, setMessage] = useState(isPlaced?.message);
  const [showLoader, setShowLoader] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [bidPlaceholder, setbidPlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if required fields are left empty
  const missingFields = () => setErrorMessage("Add missing fields first");

  const setShow = () => {
    if (isPlaced !== "") {
      setBid(isPlaced?.amount);
      setSellerMessage(isPlaced?.message);
      setbidPlaceholder(
        "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
      );
      setShowModal(true);
    } else {
      setShowModal(true);
    }
  };

  const updateBid = async () => {
    setShowLoader(true);
    const data = {
      message: sellerMessage,
      amount: bid,
    };
    const response = await updateBidByDealer(isPlaced?.bidId, data);
    if (response === true) {
      setMessage(sellerMessage);
      setShowLoader(false);
      onChange(bid);
      setShowModal(false);
    } else {
      setShowLoader(false);
      setShowModal(false);
      setErrorMessage("Error Updating Bid.");
    }
  };

  const sendBid = async () => {
    setShowLoader(true);
    getUserRef().then(async (response) => {
      const dealerDoc = await getDealerByEmail(response?.email);
      const payload = {
        post: id,
        postYear: bidPost.vehicleDetails.year,
        postMake: bidPost.vehicleDetails.make,
        postModel: bidPost.vehicleDetails.model,
        postCoverURL: bidPost.coverPhoto,
        dealer: dealerDoc?.docId,
        dealerEmail: dealerDoc?.email,
        dealerName: dealerDoc?.name,
        dealershipName: dealerDoc?.dealershipName,
        dealerContact: dealerDoc?.contactNumber,
        dealerAddress: dealerDoc?.address,
        amount: bid,
        message: sellerMessage,
        seller: bidPost.postedBy.id,
        sellerEmail: bidPost.postedBy.email,
        offeredOn: new Date(),
      };

      const success = await createBid(payload);
      setShowLoader(false);
      setShowModal(false);
      if (success) {
        setErrorMessage("Bid Sent Successfull.");
        window.location.reload(false);
      } else setErrorMessage("Error Sending Bid.");
    });
  };

  // finding if any field is left empty by the dealer
  useEffect(() => {
    if (bid.length < 1 || sellerMessage.length < 1) {
      setAnythingMissing(true);
      return;
    } else {
      setAnythingMissing(false);
    }
  }, [bid, sellerMessage]);

  return (
    <>
      {isPlaced?.message && (
        <>
          <p className="pt-3">Message to seller</p>
          <ReactQuill
            value={message}
            readOnly={true}
            theme={"bubble"}
            className="font-bold"
          />
        </>
      )}
      <button
        className={
          isPlaced === ""
            ? "w-full mt-5 h-14 rounded-lg bg-blue-500 text-md text-white font-bold"
            : "w-full mt-5 h-14 border-2 rounded-lg text-md text-blue-500 font-bold"
        }
        style={
          isPlaced !== ""
            ? {
                borderColor: "rgb(59 130 246 / var(--tw-text-opacity))",
              }
            : {
                borderColor: "none",
              }
        }
        type="button"
        onClick={() => setShow(true)}
      >
        {isPlaced === "" ? "Send Bid" : "Edit Bid"}
      </button>

      {showModal ? (
        <>
          <div
            className="fixed z-10 overflow-y-auto w-full inset-0 top-1/4"
            id="modal"
          >
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center block p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden inline-block align-middle h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle w-11/12 sm:w-96"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="flex items-center text-center p-5 rounded-t border-b dark:border-gray">
                  <h3 className="text-lg font-bold text-gray-900 w-full">
                    {isPlaced === "" ? "Send Bid" : "Edit Bid"}
                  </h3>
                  <button
                    type="button"
                    className="absolute right-5 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-900 rounded-lg items-center hover:text-white"
                    data-modal-toggle="small-modal"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-6">
                  <div className="px-5  space-y-6">
                    <div className="items-center flex border-2 border-gray-100 h-20 rounded-lg py-2 px-4">
                      <span className={bidPlaceholder} style={{ zIndex: "-1" }}>
                        Bid Price (USD $)
                      </span>
                      <NumericFormat
                        onBlur={() => {
                          if (bid.length >= 1) {
                            setbidPlaceholder(
                              "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                            );
                          } else {
                            setbidPlaceholder(
                              "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                            );
                          }
                        }}
                        onFocus={() => {
                          setbidPlaceholder(
                            "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                          );
                        }}
                        style={{ background: "transparent" }}
                        defaultValue={bidAmount}
                        className="mt-2 w-full text-sm font-bold text-black focus:outline-0"
                        thousandSeparator=","
                        title="Bid"
                        onChange={(e) => setBid(e.target.value)}
                      ></NumericFormat>
                    </div>
                    <div className="">
                      <ReactQuill
                        theme="snow"
                        value={sellerMessage}
                        placeholder="Your Message to Seller"
                        onChange={setSellerMessage}
                      />
                    </div>

                    {/* Error Message */}
                    <div className="w-11/12 sm:w-96">
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {errorMessage}
                      </p>
                    </div>

                    {isPlaced === "" ? (
                      <button
                        className="w-full flex justify-center items-center h-14 rounded-lg bg-blue-500 text-md text-white font-bold"
                        type="button"
                        onClick={async () => {
                          if (anythingMissing) {
                            missingFields();
                          } else {
                            await sendBid();
                          }
                        }}
                        disabled={showLoader}
                      >
                        <Loader showLoader={showLoader ? "" : "hidden"} />
                        Send Bid
                      </button>
                    ) : (
                      <button
                        className="w-full flex justify-center items-center h-14 rounded-lg bg-blue-500 text-md text-white font-bold"
                        type="button"
                        onClick={async () => {
                          if (anythingMissing) {
                            missingFields();
                          } else {
                            await updateBid();
                          }
                        }}
                        disabled={showLoader}
                      >
                        <Loader showLoader={showLoader ? "" : "hidden"} />
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SendBidModal;
