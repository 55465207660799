// appears at the top of car posting multi-step form
export const FormSteps = () => {
  return (
    <div className="mt-3 gap-4 sm:space-x-16 grid grid-cols-3 sm:flex text-center">
      <span className="text-sm font-bold text-gray-900">Vehicle Details</span>
      <span className="text-sm font-bold text-gray-900">
        Additional Details
      </span>
      <span className="text-sm font-bold text-gray-900">Upload Images</span>
    </div>
  );
};
