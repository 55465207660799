import { useEffect, useState } from "react";
import { TopBar } from "../../components/TopBar";
import { QuickViewBanner } from "../../components/QuickViewBanner";
import { ReactComponent as ProgressSVG } from "../../assets/SVG/Progress3of3.svg";
import { ReactComponent as UploadSVG } from "../../assets/SVG/Upload.svg";
import { ReactComponent as LineBreakSVG } from "../../assets/SVG/LineBreak.svg";
import { ReactComponent as InfoSVG } from "../../assets/SVG/Info.svg";
import { ReactComponent as ImageDiscardSVG } from "../../assets/SVG/ImageDiscard.svg";
import { ReactComponent as ImageAppendSVG } from "../../assets/SVG/ImageAppend.svg";
import Sample0 from "../../assets/images/Sample0.png";
import Sample1 from "../../assets/images/BackSideSample.png";
import Sample2 from "../../assets/images/Sample2.png";
import Sample3 from "../../assets/images/RightSideSample.png";
import Sample4 from "../../assets/images/Sample4.png";
import Sample5 from "../../assets/images/Sample5.png";
import { FormSteps } from "../../components/FormSteps";
import Button from "../../components/Button";
import { v4 as uuidv4 } from "uuid";
import {
  createPost,
  getSellerByRef,
  uploadImages,
} from "../../services/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setPostId } from "../../redux/slices/postSlice";
import { SampleImages } from "../../components/SampleImages";

export const UploadPhotosPage = ({
  calledFrom,
  formData,
  setFormData,
  stateChanger,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [successfullCarPosting, setSuccessfullCarPosting] = useState(false);
  const userId = useSelector((state) => state.user.userId);

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if required field is left empty
  const missingFields = () =>
    setErrorMessage("Please upload images of your car first.");

  // validation alert if selected images are less than 7
  const fewImages = () => setErrorMessage("Please select minimum 7 images");

  // validation alert in case of failing to sign up
  const carPostingFailed = () =>
    setErrorMessage("Car posting failed. Please try again.");

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, []);

  // preview of selected images before uploading them
  useEffect(() => {
    if (images.length < 1) {
      setAnythingMissing(true);
      return;
    } else {
      setAnythingMissing(false);
    }
  }, [images]);

  // setting images to be uploaded
  function onImageChange(e) {
    setImages([...e.target.files]);
    setFormData({
      ...formData,
      carImages: [...e.target.files],
    });
    setSelected(true);
  }

  // adding images at the end to be uploaded
  function onImageAdd(e) {
    let temp = Object.assign([], images);
    let items = e.target.files;
    items = Object.assign([], items);
    items.forEach((item) => {
      temp.push(item);
    });
    setImages(temp);
    setFormData({
      ...formData,
      carImages: temp,
    });
    setSelected(true);
  }

  // process of creating a new car post
  const handleCarPosting = async () => {
    try {
      // car post data
      const seller = await getSellerByRef(userId);
      const postDocRef = uuidv4();
      dispatch(setPostId(postDocRef));
      const postData = {
        plateNumber: formData?.plateNumber,
        registrationPlace: formData?.registrationPlace,
        VIN: formData?.VIN,
        zipCode: formData?.zipCode,
        vehicleDetails: {
          year: formData?.year,
          make: formData?.make,
          model: formData?.model,
          // trim: formData?.trim,
          trim: "benz",
          style: formData?.carStyle,
          drive: formData?.driveType,
          transmission: formData?.transmissionType,
          mileage: formData?.carMileage,
          features: {
            bluetooth: formData?.bluetooth,
            navigation: formData?.navigation,
            speakers: formData?.speakers,
            AC: formData?.AC,
            childLock: formData?.childLock,
            ABS: formData?.ABS,
            wingMirrors: formData?.wingMirrors,
          },

          additionalInformation: {
            accident: formData?.carAccidentState,
            frameDamage: formData?.frameDamageState,
            floodDamage: formData?.floodDamageState,
          },
          carImages: formData?.carImages?.length,
          carImageURLs: {},
          optional: {
            expectedPrice: formData?.expectedPrice,
          },
        },
        postedAt: new Date(),
        exteriorInteriorCondition: {
          dents: formData?.dents,
          dings: formData?.dings,
          scuffs: formData?.scuffs,
          scratches: formData?.scratches,
          chippedPaint: formData?.chippedPaint,
          rust: formData?.rust,
          hailDamage: formData?.hailDamage,
          replaceWindSheild: formData?.windsheildState,
          frameDamage: formData?.frameDamageState,
          seatBurnsRipsTear: formData?.seatBurnsState,
          smokedIn: formData?.smokedState,
        },
        mechanicalCondition: {
          drive: formData?.drive,
          warningLights: formData?.warningLights,
          ifWarningLights: formData?.ifWarningLights,
          odometerReplace: formData?.odometerReplaced,
          repairsNeeded: formData?.repairsNeeded,
          ifRepairsNeeded: formData?.ifRepairsNeeded,
        },
        additionalInformation: {
          milesOnTyres: formData?.milesOnTyres,
          keys: formData?.keys,
          aftermarksParts: formData?.aftermarksParts,
          newVehicle: formData?.newVehicle,
        },
        postedBy: {
          id: userId,
          name: seller?.name,
          contactNumber: seller?.contactNumber,
          email: seller?.email,
          placeId: seller?.placeId || "",
          address: seller?.address || "",
        },
        removed: false,
        removeReason: "",
        archived: false,
      };
      await createPost(postData, postDocRef);
      await uploadImages(formData.carImages, postDocRef);
      setSuccessfullCarPosting(true);
      setAnythingMissing(false);
      setShowLoader(false);
      stateChanger(4);
    } catch (error) {
      setShowLoader(false);
      setSuccessfullCarPosting(false);
      console.log("Error: ", error);
    }
  };

  return (
    <div>
      {/* Logo and Cancel button */}
      <TopBar calledFrom={calledFrom} />

      <div className="mt-32 md:mt-40 flex flex-col justify-center items-center h-fit">
        {/* Headlines */}
        <span className="text-4xl font-bold w-96 text-center">
          Enter you car details
        </span>
        <span className="font-normal text-sm text-center text-gray-400 mt-6 mb-10">
          Kindly fill your car details below
        </span>

        {/* Quick view banner */}
        <QuickViewBanner formData={formData} />

        {/* Multi-steps indicator 'graphics' */}
        <ProgressSVG />

        {/* Multi-steps indicator 'texts' */}
        <FormSteps />

        {/* Upload photos box */}
        <div className="flex flex-col w-11/12 sm:w-3/5 h-fit justify-center items-center outline-dashed outline-1 outline-offset-2 outline-gray-200 mt-14 rounded-lg shadow-lg p-4">
          {!selected ? (
            <div className="flex flex-col my-16 justify-center items-center">
              {/* Button for uploading images */}
              <label
                htmlFor="file-upload"
                className="custom-file-upload w-56 h-14 justify-center space-x-4 relative inline-flex items-center text-sm font-bold text-gray-50 bg-blue-500 border border-gray-300 md:mt-0 leading-5 rounded-lg"
              >
                <span className="flex flex-row justify-center space-x-2 relative mt-2.5 pr-5">
                  <UploadSVG />
                  <p>Upload photos</p>
                </span>
              </label>

              <input
                id="file-upload"
                type="file"
                accept="image/*"
                multiple={true}
                onChange={(e) => {
                  onImageChange(e);
                }}
              />
            </div>
          ) : (
            // Images preview
            <div
              className={`grid sm:grid-cols-2 md:grid-cols-3 ${
                images?.length > 3 ? `xl:grid-cols-4` : ""
              } gap-2 w-full`}
            >
              {/* grid of selected images */}
              {images.map((image) => (
                <div
                  key={URL.createObjectURL(image)}
                  className="rounded-lg bg-cover no-repeat object-fill aspect-video object-scale-down"
                  style={{
                    backgroundImage: `url(${URL.createObjectURL(image)})`,
                  }}
                >
                  {/* cross-button on top of an image */}
                  <button
                    className="m-2 float-right"
                    onClick={() => {
                      const newImages = images.filter((thisImage) => {
                        return thisImage !== image;
                      });
                      setImages(newImages);
                      setFormData({
                        ...formData,
                        carImages: newImages,
                      });
                    }}
                  >
                    <ImageDiscardSVG />
                  </button>
                </div>
              ))}

              {/* Add image button */}
              <button className="flex w-full justify-center items-center outline-dashed outline-1 outline-offset-2 outline-gray-200 rounded-lg aspect-video">
                <label htmlFor="file-upload" className="custom-file-upload-2">
                  <ImageAppendSVG />
                </label>

                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  multiple={true}
                  onChange={(e) => {
                    onImageAdd(e);
                  }}
                />
              </button>
            </div>
          )}

          {/* horizontal line in-between */}
          <div className="mt-10 mb-5">
            <LineBreakSVG />
          </div>

          {/* check instructions modal opening button */}
          <div className="flex justify-center items-center gap-2">
            <InfoSVG />
            <div className="text-sm text-gray-600 font-normal">
              Add atleast 7 pictures and below are the preferred angles to
              receive the most accurate quotations.
            </div>
          </div>

          {/* sample images */}
          <div className="grid grid-cols-2 md:grid-cols-6 gap-4 mt-10 items-center justify-center mx-20">
            <SampleImages img={Sample0} textMsg="Front" />
            <SampleImages img={Sample1} textMsg="Back" />
            <SampleImages img={Sample2} textMsg="Left Side" />
            <SampleImages img={Sample5} textMsg="Dashboard" />
            <SampleImages img={Sample4} textMsg="Interior Seats" />
            <SampleImages img={Sample3} textMsg="Right Side" />
          </div>
        </div>

        {/* Error Message */}
        <div className="w-3/5 my-2">
          <p style={{ color: "red", fontSize: "15px" }}>{errorMessage}</p>
        </div>

        {/* Back and Next buttons on the bottom*/}
        <div className="flex justify-between sm:justify-end w-11/12 sm:w-3/5 mt-8 mb-24">
          <Button
            label="Back"
            className="mr-8"
            onClick={() => stateChanger(2)}
            type="secondary"
          />

          <Button
            label="Next"
            className="flex justify-center items-center"
            onClick={async () => {
              if (anythingMissing) {
                missingFields();
              } else if (images?.length < 7) {
                fewImages();
              } else {
                setShowLoader(true);
                await handleCarPosting();
                if (!successfullCarPosting) {
                  setShowLoader(false);
                  carPostingFailed();
                }
              }
            }}
            loader={showLoader}
          />
        </div>
      </div>
    </div>
  );
};
