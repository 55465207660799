import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { getChats } from "../../services/chat";

export const Chats = ({ selectedChat, selectChat }) => {
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);

  const [chats, setChats] = useState([]);

  useEffect(() => {
    const fetchChats = async () => {
      const chats = await getChats(userId, userType);
      setChats(chats);
    };
    fetchChats();
  }, [userId, userType]);

  return (
    <div>
      {chats &&
        chats.map((chat, i) => (
          <div
            key={i}
            className={cx(
              "flex flex-row py-4 px-2 justify-center items-center border-b-2",
              { "bg-blue-100": chat.id === selectedChat }
            )}
            onClick={() => selectChat(chat.id)}
          >
            <div className="w-1/4">
              <img
                src="https://source.unsplash.com/_7LbC5J-jw4/600x600"
                className="object-cover h-12 w-12 rounded-full"
                alt=""
              />
            </div>
            <div className="w-full">
              <div className="text-lg font-semibold">
                {chat.sellerInfo.name}
              </div>
              <span className="text-gray-500">
                {chat.bidInfo.postMake} {chat.bidInfo.postModel}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};
