import React from "react";
import cx from "classnames";

const selectedClass =
  "w-1/2 sm:w-48 h-14 rounded-lg bg-blue-500 text-sm text-white font-bold";
const unselectedClass =
  "w-1/2 sm:w-48 h-14 rounded-lg bg-white text-sm text-black font-bold";

const TabSwitch = ({ label1, onClick1, label2, onClick2, selected }) => {
  return (
    <div className="flex rounded-lg shadow-md w-11/12 sm:w-96">
      <button
        className={cx({
          [selectedClass]: selected === 1,
          [unselectedClass]: selected !== 1,
        })}
        onClick={onClick1}
      >
        {label1}
      </button>

      <button
        className={cx({
          [selectedClass]: selected === 2,
          [unselectedClass]: selected !== 2,
        })}
        onClick={onClick2}
      >
        {label2}
      </button>
    </div>
  );
};

export default TabSwitch;
