import { TopBar } from "../TopBar";
import { QuickViewBanner } from "../QuickViewBanner";
import { useEffect, useState } from "react";
import { Asterisk } from "../Asterisk";
import { ReactComponent as ProgressSVG } from "../../assets/SVG/Progress2of3.svg";
import { FormSteps } from "../FormSteps";
import { NumericFormat } from "react-number-format";
import Button from "../Button";

const drive = [
  { id: "q1yes", title: "Yes" },
  { id: "q1no", title: "No" },
  { id: "q1maybe", title: "Maybe" },
];

const warningLights = [
  { id: "q2yes", title: "Yes" },
  { id: "q2no", title: "No" },
  { id: "q2maybe", title: "Maybe" },
];

const odometerReplaced = [
  { id: "q3yes", title: "Yes" },
  { id: "q3no", title: "No" },
  { id: "q3maybe", title: "Maybe" },
];

const repairsNeeded = [
  { id: "q4yes", title: "Yes" },
  { id: "q4no", title: "No" },
  { id: "q4maybe", title: "Maybe" },
];

const milesOnTyres = [
  { id: "<5,000 miles", title: "<5,000 miles" },
  { id: "5,000-10,000 miles", title: "5,000-10,000 miles" },
  { id: "10,000-20,000 miles", title: "10,000-20,000 miles" },
  { id: ">20,000 miles", title: ">20,000 miles" },
];

const keys = [
  { id: "q61", title: "1" },
  { id: "q62-4", title: "2" },
  { id: "q6more", title: "More" },
];

const aftermarksParts = [
  { id: "q7yes", title: "Yes" },
  { id: "q7no", title: "No" },
  { id: "q7maybe", title: "Maybe" },
];

const newVehicle = [
  { id: "q8yes", title: "Yes" },
  { id: "q8no", title: "No" },
  { id: "q8maybe", title: "Maybe" },
];

export const AdditionalDetailsPage = ({
  calledFrom,
  formData,
  setFormData,
  stateChanger,
  ...rest
}) => {
  // Field Animations
  const [expectedPricePlaceholder, setExpectedPricePlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Logo and Cancel button */}
      <TopBar calledFrom={calledFrom} />

      <div className="mt-32 md:mt-40 flex flex-col justify-center items-center h-fit">
        {/* Headlines */}
        <span className="text-4xl font-bold w-96 text-center">
          Enter your car details
        </span>
        <span className="font-normal text-sm text-center text-gray-400 mt-6 mb-10">
          Kindly fill your car details below
        </span>

        {/* Quick view banner */}
        <QuickViewBanner formData={formData} />

        {/* Multi-steps indicator 'graphics' */}
        <ProgressSVG />

        {/* Multi-steps indicator 'texts' */}
        <FormSteps />

        {/* Drive question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-14 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Does your vehicle drive?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Drive</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {drive.map((drive) => (
                <div key={drive.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={drive.id}
                      name="drive"
                      type="radio"
                      defaultChecked={drive.id === "q1yes"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          drive: drive.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={drive.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {drive.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Warning lights question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Are there any dashboard warning lights on?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Warning lights</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {warningLights.map((warningLights) => (
                <div
                  key={warningLights.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={warningLights.id}
                      name="warningLights"
                      type="radio"
                      defaultChecked={warningLights.id === "q2no"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          warningLights: warningLights.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={warningLights.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {warningLights.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>

          {/* In case of dashboard warning lights */}
          {formData.warningLights === "Yes" ? (
            <>
              <div className="flex w-full h-16 border-2 border-gray-100 mt-4 rounded-lg px-4 bg-gray-100">
                <input
                  className="text-gray-700 text-sm font-bold focus:outline-0 w-full bg-gray-100"
                  type="text"
                  placeholder="If yes, please enter your answer"
                  defaultValue={formData?.ifWarningLights}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      ifWarningLights: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {/* Odometer replaced question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Has the odometer ever been replaced or modified?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">odometer replaced</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {odometerReplaced.map((odometerReplaced) => (
                <div
                  key={odometerReplaced.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={odometerReplaced.id}
                      name="odometerReplaced"
                      type="radio"
                      defaultChecked={odometerReplaced.id === "q3no"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          odometerReplaced: odometerReplaced.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={odometerReplaced.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {odometerReplaced.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Repairs needed question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Any mechanical repairs needed?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">repairs needed</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {repairsNeeded.map((repairsNeeded) => (
                <div
                  key={repairsNeeded.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={repairsNeeded.id}
                      name="repairsNeeded"
                      type="radio"
                      defaultChecked={repairsNeeded.id === "q4no"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          repairsNeeded: repairsNeeded.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={repairsNeeded.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {repairsNeeded.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>

          {/* In case of repairs needed */}
          {formData.repairsNeeded === "Yes" ? (
            <>
              <div className="flex w-full h-16 border-2 border-gray-100 mt-4 rounded-lg px-4 bg-gray-100">
                <input
                  className="text-gray-700 text-sm font-bold focus:outline-0 w-full bg-gray-100"
                  type="text"
                  placeholder="If yes, please enter your answer"
                  defaultValue={formData?.ifRepairsNeeded}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      ifRepairsNeeded: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {/* Miles on tyres radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            How many miles are on your current tyres?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Type of drive</legend>
            <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
              {milesOnTyres.map((milesOnTyres) => (
                <div
                  key={milesOnTyres.id}
                  className="flex items-center lg:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={milesOnTyres.id}
                      name="milesOnTyres"
                      type="radio"
                      defaultChecked={milesOnTyres.id === "<5,000 miles"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          milesOnTyres: milesOnTyres.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={milesOnTyres.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {milesOnTyres.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Keys question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            How many keys do you have?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">keys</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {keys.map((keys) => (
                <div key={keys.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={keys.id}
                      name="keys"
                      type="radio"
                      defaultChecked={keys.id === "q61"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          keys: keys.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={keys.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {keys.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* aftermarket parts question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Are there aftermarket parts installed?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">aftermarks parts</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {aftermarksParts.map((aftermarksParts) => (
                <div
                  key={aftermarksParts.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={aftermarksParts.id}
                      name="aftermarksParts"
                      type="radio"
                      defaultChecked={aftermarksParts.id === "q7no"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          aftermarksParts: aftermarksParts.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={aftermarksParts.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {aftermarksParts.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* New vehicle question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Are you planning on buying another vehicle?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">new vehicle</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {newVehicle.map((newVehicle) => (
                <div key={newVehicle.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={newVehicle.id}
                      name="newVehicle"
                      type="radio"
                      defaultChecked={newVehicle.id === "q8yes"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          newVehicle: newVehicle.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={newVehicle.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {newVehicle.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Expected price number field */}
        <div className="w-11/12 sm:w-3/5 mt-5">
          <div className="items-center flex h-20 border-2 border-gray-100 rounded-lg p-5">
            <div className="w-full">
              <span
                className={`hidden sm:flex ${expectedPricePlaceholder}`}
                style={{ zIndex: "-1" }}
              >
                Please enter the price you expect for your car (USD $)
              </span>
              <span
                className={`${expectedPricePlaceholder} sm:hidden`}
                style={{ zIndex: "-1" }}
              >
                Expected Price (USD $)
              </span>
              <NumericFormat
                onBlur={() => {
                  if (formData?.expectedPrice?.length >= 1) {
                    setExpectedPricePlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setExpectedPricePlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setExpectedPricePlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 text-sm w-full font-bold text-gray-500 focus:outline-0"
                title="expectedPrice"
                thousandSeparator=","
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    expectedPrice: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>

        {/* Back and Next buttons on the bottom*/}
        <div className="flex justify-between sm:justify-end w-11/12 sm:w-3/5 mt-8 mb-24">
          <Button
            label="Back"
            className="mr-8"
            onClick={() => stateChanger(1)}
            type="secondary"
          />

          <Button label="Next" onClick={() => stateChanger(3)} />
        </div>
      </div>
    </div>
  );
};
