import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, db } from "./firebase";

const NOTIFICATION_COLLECTION = "notification";

export const createNotification = async (notiData) => {
  try {
    const { id } = await addDoc(
      collection(db, NOTIFICATION_COLLECTION),
      notiData
    );
    return id;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/notification/createNotification funtion: ",
      error
    );
  }
};

export const getNotification = async (uid) => {
  try {
    const notiRef = doc(collection(db, NOTIFICATION_COLLECTION), uid);
    const notification = await getDoc(notiRef);
    if (notification.exists()) {
      return notification.data();
    }
  } catch (error) {
    console.error(error);
  }
};

export const getRealTimeNotifications = (uid, calledBy, setNotifications) => {
  try {
    const q =
      calledBy === "Seller"
        ? query(
            collection(db, NOTIFICATION_COLLECTION),
            where("type", "in", ["postCreation", "bidCreation"]),
            where("sellerId", "==", uid)
          )
        : query(
            collection(db, NOTIFICATION_COLLECTION),
            where("dealerId", "==", uid)
          );

    let notifications = [];
    if (calledBy === "Dealer") {
      onSnapshot(
        query(
          collection(db, NOTIFICATION_COLLECTION),
          where("dealerIds", "array-contains", uid)
        ),
        (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            notifications.push(doc.data());
          });
        }
      );
    }

    return onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        notifications.push(doc.data());
      });
      notifications = notifications.sort((doc1, doc2) =>
        doc1.createdAt < doc2.createdAt
          ? 1
          : doc1.createdAt > doc2.createdAt
          ? -1
          : 0
      );
      return setNotifications(notifications);
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/notification/getRealTimeNotifications funtion: ",
      error
    );
  }
};

export const sendEmailNotification = async (to, subject, messageText) => {
  const functions = getFunctions(app);
  const emailNotifyFunc = httpsCallable(functions, "sendEmailNotification");
  await emailNotifyFunc({ to, subject, messageText });
};
