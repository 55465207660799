export const SkeletonLoader = ({ total }) => {
  //   const { count = 3 } = this.props;
  return (
    <div className="flex flex-col mb-24 py-2">
      {Array(total)
        .fill(null)
        .map((x, idx) => (
          <div className="rounded-md w-full pr-6 py-8" key={Math.random()}>
            <div className="animate-pulse flex space-x-4 items-center">
              {/* image */}
              <div className="rounded-xl bg-gray-400 h-14 w-24"></div>
              <div className="flex-1 space-y-4 w-full">
                {/* one line */}
                <div className="h-2 bg-gray-400 rounded"></div>
                <div className="space-y-3">
                  {/* multiple lines */}
                  <div className="grid grid-cols-7 gap-5">
                    <div className="h-2 bg-gray-400 rounded col-span-1"></div>
                    <div className="h-2 bg-gray-400 rounded col-span-2"></div>
                    <div className="h-2 bg-gray-400 rounded col-span-1"></div>
                    <div className="h-2 bg-gray-400 rounded col-span-2"></div>
                    <div className="h-2 bg-gray-400 rounded col-span-1"></div>
                  </div>
                  {/* two lines */}
                  <div className="grid grid-cols-2 gap-5">
                    <div className="h-2 bg-gray-400 rounded"></div>
                    <div className="h-2 bg-gray-400 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
