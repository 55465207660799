import { Link } from "react-router-dom";

// appears at the bottom of main section in pages
export const Footer = () => {
  return (
    <div className="grid grid-flow-col space-x-6 sm:flex sm:space-x-8 text-sm font-bold bg-white ml-9 mb-6">
      {/* redirecting to privacy policy page */}
      <Link to="/privacyPolicy">
        <span>Privacy Policy</span>
      </Link>

      {/* redirecting to terms of use page */}
      <Link to="/termsOfUse">
        <span>Terms of Use</span>
      </Link>

      {/* static disclaimer/authority text*/}
      <span className="font-normal">@ 2022 All rights reserved</span>
    </div>
  );
};
