import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/Logo.png";
import { ReactComponent as SellCarSVG } from "../assets/SVG/SellCar.svg";
// import { ReactComponent as InboxSVG } from "../assets/SVG/Inbox.svg";
import { useDispatch } from "react-redux";
import { initComState, resetCarDetails } from "../redux/slices/comSlice";
import { useState } from "react";

// appears on the left hand side of pages
export const NavigationPanel = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openStatus, setOpenStatus] = useState(
    windowWidth < 640 ? false : true
  );

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedClass =
    "flex items-center space-x-6 bg-blue-50 text-blue-500 text-sm p-5 m-auto w-4/5 rounded-xl";
  const unSelectedClass =
    "flex items-center space-x-6 text-gray-400 p-5 m-auto w-4/5 rounded-xl";

  const openNav = () => {
    setOpenStatus(true);
  };

  const closeNav = () => {
    setOpenStatus(false);
  };

  return (
    <>
      {!openStatus && windowWidth < 640 ? (
        <div className="absolute left-6">
          <button
            className={`${
              windowWidth < 640 ? "flex" : "hidden"
            } mt-9 text-3xl text-gray-500`}
            onClick={openNav}
          >
            ☰
          </button>
        </div>
      ) : (
        <div className="flex flex-col sm:w-80 float-left text-sm font-bold border-r-2 border-gray-100 -mt-8 justify-between">
          <div className="flex flex-col justify-between h-full">
            <div className="space-y-4 space-x-6 mr-6 sm:mr-0">
              {/* mtd logo on top left*/}
              <div className="flex justify-between">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-10 h-10 ml-8 mt-16 mb-7"
                />
                <button
                  className={`${
                    windowWidth < 640 ? "flex" : "hidden"
                  } mt-14 p-4 text-xl text-gray-500`}
                  onClick={closeNav}
                >
                  x
                </button>
              </div>

              {/* my cars button */}
              {props.user === "dealer" ? (
                <button
                  className={props?.allCars ? selectedClass : unSelectedClass}
                  onClick={() => {
                    navigate("/dealer/dashboard");
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.13014 5.48828H12.6067L11.4817 2.11328H3.25514L2.13014 5.48828ZM10.6731 8.93359C10.9075 9.14453 11.177 9.25 11.4817 9.25C11.7864 9.25 12.0442 9.14453 12.2551 8.93359C12.4895 8.72266 12.6067 8.45312 12.6067 8.125C12.6067 7.79688 12.4895 7.52734 12.2551 7.31641C12.0442 7.10547 11.7864 7 11.4817 7C11.177 7 10.9075 7.10547 10.6731 7.31641C10.4622 7.52734 10.3567 7.79688 10.3567 8.125C10.3567 8.45312 10.4622 8.72266 10.6731 8.93359ZM2.44655 8.93359C2.68092 9.14453 2.95045 9.25 3.25514 9.25C3.55983 9.25 3.81764 9.14453 4.02858 8.93359C4.26295 8.72266 4.38014 8.45312 4.38014 8.125C4.38014 7.79688 4.26295 7.52734 4.02858 7.31641C3.81764 7.10547 3.55983 7 3.25514 7C2.95045 7 2.68092 7.10547 2.44655 7.31641C2.23561 7.52734 2.13014 7.79688 2.13014 8.125C2.13014 8.45312 2.23561 8.72266 2.44655 8.93359ZM12.5715 1.76172L14.1184 6.26172V12.2383C14.1184 12.4492 14.0481 12.6367 13.9075 12.8008C13.7669 12.9414 13.5911 13.0117 13.3801 13.0117H12.6067C12.3958 13.0117 12.22 12.9414 12.0794 12.8008C11.9387 12.6367 11.8684 12.4492 11.8684 12.2383V11.5H2.86842V12.2383C2.86842 12.4492 2.79811 12.6367 2.65748 12.8008C2.51686 12.9414 2.34108 13.0117 2.13014 13.0117H1.3567C1.14576 13.0117 0.969983 12.9414 0.829358 12.8008C0.688733 12.6367 0.618421 12.4492 0.618421 12.2383V6.26172L2.1653 1.76172C2.32936 1.24609 2.69264 0.988281 3.25514 0.988281H11.4817C12.0442 0.988281 12.4075 1.24609 12.5715 1.76172Z"
                      fill={props?.allCars ? "#5E83F4" : "#9A9EA7"}
                    />
                  </svg>

                  <span>All Cars</span>
                </button>
              ) : (
                <button
                  className={props?.allCars ? selectedClass : unSelectedClass}
                  onClick={() => {
                    navigate("/landingPage");
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4023 11.75V2.59766H2.55469V11.75H6.25C6.25 12.4948 6.52214 13.1393 7.06641 13.6836C7.61068 14.2279 8.25521 14.5 9 14.5C9.74479 14.5 10.3893 14.2279 10.9336 13.6836C11.4779 13.1393 11.75 12.4948 11.75 11.75H15.4023ZM15.4023 0.75C15.918 0.75 16.3477 0.936198 16.6914 1.30859C17.0638 1.65234 17.25 2.08203 17.25 2.59766V15.4023C17.25 15.8893 17.0638 16.319 16.6914 16.6914C16.319 17.0638 15.8893 17.25 15.4023 17.25H2.55469C2.03906 17.25 1.60938 17.0781 1.26562 16.7344C0.921875 16.362 0.75 15.918 0.75 15.4023V2.59766C0.75 2.08203 0.921875 1.65234 1.26562 1.30859C1.60938 0.936198 2.03906 0.75 2.55469 0.75H15.4023Z"
                      fill={props?.allCars ? "#5E83F4" : "#9A9EA7"}
                    />
                  </svg>
                  <span>All Cars</span>
                </button>
              )}

              {/* bids received button */}
              {props.user === "dealer" ? (
                <button
                  className={props?.bids ? selectedClass : unSelectedClass}
                  onClick={() => {
                    navigate("/dealer/myBids");
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6797 9V2.32031H11.5V9L13.5703 7.75L15.6797 9ZM15.6797 0.679688C16.1224 0.679688 16.5 0.848958 16.8125 1.1875C17.151 1.5 17.3203 1.8776 17.3203 2.32031V12.3203C17.3203 12.763 17.151 13.1536 16.8125 13.4922C16.5 13.8307 16.1224 14 15.6797 14H5.67969C5.23698 14 4.84635 13.8307 4.50781 13.4922C4.16927 13.1536 4 12.763 4 12.3203V2.32031C4 1.8776 4.16927 1.5 4.50781 1.1875C4.84635 0.848958 5.23698 0.679688 5.67969 0.679688H15.6797ZM2.32031 4V15.6797H14V17.3203H2.32031C1.8776 17.3203 1.48698 17.1641 1.14844 16.8516C0.835938 16.513 0.679688 16.1224 0.679688 15.6797V4H2.32031Z"
                      fill={props?.bids ? "#5E83F4" : "#9A9EA7"}
                    />
                  </svg>
                  <span>My Bids</span>
                </button>
              ) : (
                <button
                  className={props?.bids ? selectedClass : unSelectedClass}
                  onClick={() => {
                    navigate("/allBids");
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.47266 5.34766V9.21484L12.6523 11.1484L12.0078 12.2656L8.09766 9.90234V5.34766H9.47266ZM17.25 7.28125H11.0195L13.5547 4.70312C12.2943 3.44271 10.776 2.8125 9 2.8125C7.2526 2.78385 5.7487 3.38542 4.48828 4.61719C3.97266 5.13281 3.52865 5.83464 3.15625 6.72266C2.78385 7.58203 2.59766 8.36979 2.59766 9.08594C2.59766 9.80208 2.78385 10.6042 3.15625 11.4922C3.52865 12.3516 3.97266 13.0391 4.48828 13.5547C5.00391 14.0703 5.70573 14.5143 6.59375 14.8867C7.48177 15.2591 8.28385 15.4453 9 15.4453C9.71615 15.4453 10.5182 15.2591 11.4062 14.8867C12.3229 14.5143 13.0391 14.0703 13.5547 13.5547C14.7865 12.3229 15.4023 10.8333 15.4023 9.08594H17.25C17.25 11.349 16.4479 13.2682 14.8438 14.8438C13.2396 16.4479 11.2917 17.25 9 17.25C6.70833 17.25 4.76042 16.4479 3.15625 14.8438C1.55208 13.2682 0.75 11.3633 0.75 9.12891C0.75 6.86589 1.55208 4.93229 3.15625 3.32812C3.8151 2.66927 4.70312 2.11068 5.82031 1.65234C6.96615 1.16536 8.01172 0.921875 8.95703 0.921875C9.90234 0.921875 10.9336 1.16536 12.0508 1.65234C13.1966 2.11068 14.099 2.66927 14.7578 3.32812L17.25 0.75V7.28125Z"
                      fill={props?.bids ? "#5E83F4" : "#9A9EA7"}
                    />
                  </svg>

                  <span>Bids Received</span>
                </button>
              )}

              {/* <button
          className={props?.inbox ? selectedClass : unSelectedClass}
          onClick={() => {
            navigate("/inbox");
          }}
        >
          <InboxSVG
            calledBy={props.user}
            fill={props?.inbox ? "#5E83F4" : "#9A9EA7"}
          />
          <span>Inbox</span>
        </button> */}
            </div>

            <div className="flex justify-center mb-10">
              {/* sell you car button on bottom */}
              {props.user !== "dealer" && (
                <div className="flex flex-col items-center justify-center p-5 w-4/5 -mb-4 rounded-xl bg-gray-100">
                  <span className="text-xs font-bold text-gray-400 items-center justify-center">
                    Enlist your car to sell
                  </span>
                  <Link to="/newPost" className="w-full">
                    <button
                      className="flex bg-white w-full items-center justify-center rounded-md p-2 mt-3 shadow-md space-x-2"
                      onClick={() => {
                        dispatch(resetCarDetails(initComState));
                      }}
                    >
                      <SellCarSVG />
                      <span className="text-xs font-bold text-gray-800 items-center justify-center">
                        Sell Your Car
                      </span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
