import { createSlice } from "@reduxjs/toolkit";

export const initUserState = {
  userId: "",
  userType: "",
  sessionEmail: "",
  notificationView: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initUserState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setNotificationView: (state, action) => {
      state.notificationView = action.payload;
    },
    setSessionEmail: (state, action) => {
      state.sessionEmail = action.payload;
    },
  },
});

export const { setUserId, setUserType, setNotificationView, setSessionEmail } =
  userSlice.actions;

export default userSlice.reducer;
