import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import { addMsg, getMsgsRealtime } from "../../services/chat";

const ChatWindow = ({ id }) => {
  const userId = useSelector((state) => state.user.userId);

  const [msgs, setMsgs] = useState([]);
  const msgRef = useRef();

  useEffect(() => {
    let unsub = null;
    const fetchMessages = () => {
      unsub = getMsgsRealtime(id, setMsgs);
    };
    fetchMessages();
    return () => {
      unsub && unsub();
    };
  }, [id]);

  const sendMessage = async () => {
    const payload = {
      chatId: id,
      sender: userId,
      messageText: msgRef.current.value,
    };

    await addMsg(payload);
    msgRef.current.value = "";
  };

  return (
    <>
      <div className="flex flex-col mt-5">
        {msgs &&
          msgs.map((msg, i) => {
            const sender = msg.sender === userId;
            return (
              <>
                <div
                  className={cx("flex mb-4", {
                    "justify-end": sender,
                    "justify-start": !sender,
                  })}
                >
                  <div
                    className={cx("py-3 px-4 text-black", {
                      "mr-2 bg-blue-100 rounded-bl-3xl rounded-tl-3xl rounded-br-xl":
                        sender,
                      "ml-2 bg-gray-100 rounded-br-3xl rounded-tr-3xl rounded-bl-xl":
                        !sender,
                    })}
                  >
                    {msg.messageText}
                  </div>
                </div>
              </>
            );
          })}
      </div>
      <div className="flex flex-row justify-between py-5">
        <input
          className="border-2 w-full py-3 px-3 rounded-xl"
          type="text"
          placeholder="type your message here..."
          ref={msgRef}
        />
        <Button label="Send" className="mx-3" onClick={sendMessage} />
      </div>
    </>
  );
};

export default ChatWindow;
