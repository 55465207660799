import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  applyActionCode,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { addMsg, createChat } from "./chat";
import { createNotification, sendEmailNotification } from "./notification";
import { distanceMatrix } from "../common/maps";

// my-top-dollar web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANU6HENlwjZkBSfjcFKz2jZIt0JwaYrdM",
  authDomain: "my-top-dollar.firebaseapp.com",
  projectId: "my-top-dollar",
  storageBucket: "my-top-dollar.appspot.com",
  messagingSenderId: "591411033817",
  appId: "1:591411033817:web:0c5db8e0a761688db4b0c8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
export const auth = getAuth();

//  Initialize firebase-storage
export const storage = getStorage(app);

const BID_COLLECTION = "bid";
// const POST_COLUMN = "post";

// for fetching seller document reference
export async function getUserDocRef(email, userType) {
  try {
    const sellerCollectionRef = collection(db, userType);
    const querySnapshot = query(
      sellerCollectionRef,
      where("email", "==", email)
    );
    const response = await getDocs(querySnapshot);
    let sellerDocRef;
    response.forEach((doc) => {
      sellerDocRef = doc.id;
    });
    return sellerDocRef;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getSellerDocRef function: ",
      error
    );
  }
}

// for user to log in
export async function login(email, password, rememberMe, userType) {
  try {
    const creds = await signInWithEmailAndPassword(auth, email, password);
    if (rememberMe) {
      await auth.setPersistence(browserLocalPersistence);
    }
    const sellerDocRef = await getUserDocRef(creds?.user?.email, userType);
    return sellerDocRef;
  } catch (error) {
    console.log("Something went wrong in firebase/login funtion: ", error);
    return error;
  }
}

// for user to sign up
export async function signup(email, password) {
  try {
    const ret = await createUserWithEmailAndPassword(auth, email, password);
    return ret;
  } catch (error) {
    console.log("Something went wrong in firebase/signup funtion: ", error);
    return error;
  }
}

// for user to recieve account recovery email
export async function resetPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log(
      "Something went wrong in firebase/resetPassword funtion: ",
      error
    );
  }
}

// for user to sign out
export async function signout() {
  try {
    await auth.signOut();
  } catch (error) {
    console.log("Something went wrong in firebase/signout funtion: ", error);
  }
}

export async function verifyUserEmail(actionCode) {
  try {
    if (auth.currentUser?.emailVerified) return true;
    await applyActionCode(auth, actionCode);
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await user.reload();
        unsub();
      }
    });
    return true;
  } catch (error) {
    console.error("Something went wrong in verifyEmail: ", error);
    return false;
  }
}

export function emailIsVerified() {
  try {
    if (auth?.currentUser?.emailVerified) return true;
    else return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const getDocumentByUid = async (uid, col) => {
  try {
    const userRef = doc(collection(db, col), uid);
    const bid = await getDoc(userRef);
    if (bid.exists()) return bid.data();
  } catch (error) {
    console.error(error);
  }
};

// to create a seller
export async function createSeller(userData, sellerDocRef) {
  try {
    await setDoc(doc(db, "seller", sellerDocRef), userData);
  } catch (error) {
    console.log(
      "Something went wrong in firebase/createSeller funtion: ",
      error
    );
  }
}

// to create a car post
export async function createPost(postData, postDocRef) {
  try {
    await setDoc(doc(db, "post", postDocRef), postData);

    // filter dealers within 200 miles
    const response = await getDealers();
    const destinations = response.filter((d) => d?.data?.["placeId"]);

    const distances = await distanceMatrix(
      postData?.postedBy?.placeId,
      destinations.map((d) => ({ placeId: d?.data?.["placeId"] }))
    );

    const filteredDealers = destinations.filter(
      (d, idx) => distances[idx] < 200
    );
    const filteredDealerIds = filteredDealers.map((d) => d["id"]);
    const filteredDealerEmails = filteredDealers.map((d) => d.data.email);

    await createNotification({
      type: "postCreation",
      postId: postDocRef,
      sellerId: postData?.postedBy?.id,
      dealerIds: filteredDealerIds,
      sellerAddress: postData?.postedBy?.address,
      registeredAt: postData?.registrationPlace,
      postMake: postData?.vehicleDetails?.["make"],
      postModel: postData?.vehicleDetails?.["model"],
      postYear: postData?.vehicleDetails?.["year"],
      createdAt: new Date(),
    });

    // send email notifications to dealers
    filteredDealerEmails.forEach((email) => {
      sendEmailNotification(
        email,
        "Car for Sale - MyTopDollar",
        "A car has been posted in your region. Kindly take a look and place your bid. https://mytopdollar.com/"
      );
    });
  } catch (error) {
    console.log("Something went wrong in firebase/createPost funtion: ", error);
  }
}

// to get dealers
export async function getDealers() {
  try {
    const sellerCollectionRef = collection(db, "dealer");
    const response = await getDocs(sellerCollectionRef);
    let dealers = response.docs.map((doc) => ({
      data: doc.data(),
      id: doc.id,
    }));
    return dealers;
  } catch (error) {
    console.log("Something went wrong in firebase/getDealers funtion: ", error);
  }
}

// to get sellers
export async function getSeller() {
  try {
    const sellerCollectionRef = collection(db, "customer");
    const response = await getDocs(sellerCollectionRef);
    let sellers = response.docs.map((doc) => ({
      data: doc.data(),
      id: doc.id,
    }));
    return sellers;
  } catch (error) {
    console.log("Something went wrong in firebase/getSeller funtion: ", error);
  }
}

// to upload images to firebase storage
export async function uploadImages(carImages, storageFolder) {
  if (carImages?.length === 0) return;
  try {
    const promisesArray = carImages.map((image, i) => {
      const imageRef = ref(storage, `${storageFolder}/image${i + 1}`);
      return uploadBytes(imageRef, image);
    });
    await Promise.all(promisesArray);

    // update post's carImageURLs
    let images = {};
    let post = await getPostByRef(storageFolder);
    for (let i = 1; i <= post?.vehicleDetails?.carImages; i++) {
      await getDownloadURL(ref(storage, `${storageFolder}/image${i}`))
        .then((url) => {
          images[`image${i}`] = url;
        })
        .catch((error) => {
          console.log(
            "Couldn't generate URL for the cover photo in getPostImages function: ",
            error
          );
        });
    }
    post.vehicleDetails.carImageURLs = images;
    delete post["id"];
    delete post["coverPhoto"];
    await updateDoc(doc(db, "post", storageFolder), post);
  } catch (error) {
    console.log(
      "Something went wrong in firebase/uploadImages function: ",
      error
    );
  }
}

// to get car posts of a particular seller
export async function getPostsBySellerRef(sellerRef) {
  try {
    if (sellerRef === undefined) return "";
    const postCollectionRef = collection(db, "post");
    let posts = [];
    const querySnapshot = query(
      postCollectionRef,
      where("postedBy.id", "==", sellerRef)
    );
    const response = await getDocs(querySnapshot);

    const promise = new Promise((resolve, reject) => {
      response.forEach(async (doc) => {
        const data = doc.data();
        const postedBy = data?.postedBy?.id;
        if (postedBy === sellerRef && data?.removed === false) {
          let resp = data;
          resp.docRef = doc.id;
          resp.quotesReceived = await getNumberOfBids(doc.id);
          const storage = getStorage();
          const url = await getDownloadURL(ref(storage, `${doc.id}/image1`));
          resp.coverPhoto = url;
          posts.push(resp);
        }
        resolve();
      });
    });

    return await promise.then(() => {
      posts = posts.sort(function (a, b) {
        return new Date(b.postedAt.seconds) - new Date(a.postedAt.seconds);
      });
      return posts;
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getPostsBySellerRef function: ",
      error
    );
  }
}

// to archive a car post
export async function archivePost(postId) {
  try {
    const postDocRef = doc(db, "post", postId);
    await updateDoc(postDocRef, {
      archived: true,
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/archivePost function: ",
      error
    );
  }
}

// to un-archive a car post
export async function unarchivePost(postId) {
  try {
    const postDocRef = doc(db, "post", postId);
    await updateDoc(postDocRef, {
      archived: false,
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/unarchivePost function: ",
      error
    );
  }
}

// get indexes of the discarded images that were in the storage
export async function getDiscardedImagesIndexes(
  discardedImages,
  exSize,
  storageFolder
) {
  try {
    let discardedImagesIndexes = [];

    if (discardedImages?.length > 0) {
      for (let i = 1; i <= exSize; i++) {
        await getDownloadURL(ref(storage, `${storageFolder}/image${i}`))
          .then((url) => {
            if (discardedImages.includes(url)) {
              discardedImagesIndexes.push(i);
            }
          })
          .catch((error) => {
            console.log(
              "Couldn't generate URL for the cover photo in getDiscardedImagesIndexes function: ",
              error
            );
          });
      }
    }

    return discardedImagesIndexes;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getPostsBySellerRef function: ",
      error
    );
  }
}

// delete the discarded images from the storage
async function deleteTheDiscardedImages(discardedImagesIndexes, storageFolder) {
  try {
    for (let i = 1; i <= discardedImagesIndexes.length; i++) {
      // Create a reference to the file to delete
      const desertRef = ref(
        storage,
        `${storageFolder}/${discardedImagesIndexes.pop()}`
      );
      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          console.log(
            "Something went wrong in firebase/resetPostImages function: ",
            error
          );
        });
    }
  } catch (error) {
    console.log(
      "Something went wrong in firebase/deleteTheDiscardedImages function: ",
      error
    );
  }
}

// to update post images to firebase storage
export async function updatePostImages(
  carImages,
  storageFolder,
  exSize = 0,
  postData
) {
  if (carImages === undefined || carImages?.length === 0) return postData;
  let prevSize = exSize;

  carImages = carImages.filter((thisImage) => {
    return typeof thisImage !== "string";
  });

  try {
    const promisesArray = carImages.map((image) => {
      const imageRef = ref(
        storage,
        `${storageFolder}/image${(exSize = exSize + 1)}`
      );
      return uploadBytes(imageRef, image);
    });
    await Promise.all(promisesArray);

    // update post's carImageURLs
    let images = postData.vehicleDetails.carImageURLs;
    for (let i = prevSize; i <= exSize; i++) {
      await getDownloadURL(ref(storage, `${storageFolder}/image${i}`))
        .then((url) => {
          images[`image${i}`] = url;
        })
        .catch((error) => {
          console.log(
            "Couldn't generate URL for the cover photo in getPostImages function: ",
            error
          );
        });
    }
    postData.vehicleDetails.carImageURLs = images;
    return postData;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/uploadImages function: ",
      error
    );
  }
}

// to update post images to firebase storage
export async function resetPostImages(
  carImages,
  exSize = 0,
  discardedImages,
  discardedIndexes,
  storageFolder,
  postData
) {
  try {
    if (carImages === undefined || carImages?.length === 0) return;

    // get index for the images to upload/reset
    carImages = carImages.filter((thisImage) => {
      return typeof thisImage !== "string";
    });

    // filter out the discarded images that are already present in db
    discardedImages = discardedImages.filter((thisImage) => {
      return typeof thisImage === "string";
    });
    let discardedImagesIndexes = discardedIndexes;
    let prevSize = exSize;

    // get the index of the file to delete or upload on the storage
    if (discardedImagesIndexes.length > 0) {
      await deleteTheDiscardedImages(discardedImagesIndexes, storageFolder);
    }

    const promisesArray = carImages.map((image, i) => {
      const imageRef = ref(
        storage,
        `${storageFolder}/image${(exSize = exSize + 1)}`
      );
      return uploadBytes(imageRef, image);
    });
    await Promise.all(promisesArray);

    // update post's carImageURLs
    let images = postData.vehicleDetails.carImageURLs;
    for (let i = prevSize; i <= exSize; i++) {
      await getDownloadURL(ref(storage, `${storageFolder}/image${i}`))
        .then((url) => {
          images[`image${i}`] = url;
        })
        .catch((error) => {
          console.log(
            "Couldn't generate URL for the cover photo in getPostImages function: ",
            error
          );
        });
    }
    postData.vehicleDetails.carImageURLs = images;
    return postData;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/resetPostImages function: ",
      error
    );
  }
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// to update a car post
export async function updatePost(postId, postData) {
  try {
    const postDocRef = doc(db, "post", postId);
    delete postData["coverPhoto"];
    delete postData["docRef"];
    delete postData["images"];

    let ids = Object.keys(postData.vehicleDetails.carImageURLs);
    ids = ids.map((id) => {
      id = id.replace(/[^\d.]/g, "");
      return parseInt(id, 10);
    });
    let exSize = Math.max(...ids);
    if (postData["discardedImages"].length === 0) {
      postData = await updatePostImages(
        postData.carImages,
        postId,
        exSize,
        postData
      );
    } else {
      let discardedIndexes = [];
      let discardedImages = postData.discardedImages.filter((thisImage) => {
        return typeof thisImage === "string";
      });
      discardedImages.forEach((value) => {
        const index = getKeyByValue(
          postData.vehicleDetails.carImageURLs,
          value
        );
        delete postData.vehicleDetails.carImageURLs[index];
        discardedIndexes.push(index);
      });

      postData = await resetPostImages(
        postData.carImages,
        exSize - discardedIndexes.length,
        postData.discardedImages,
        discardedIndexes,
        postId,
        postData
      );
    }

    delete postData["carImages"];
    delete postData["existingImagesCount"];
    delete postData["discardedImages"];

    await updateDoc(postDocRef, postData);
  } catch (error) {
    console.log(
      "Something went wrong in firebase/updatePost function: ",
      error
    );
  }
}

// to delete a car post
export async function deletePost(postId, removeReason) {
  try {
    const postDocRef = doc(db, "post", postId);
    await updateDoc(postDocRef, {
      removed: true,
      removeReason: removeReason,
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/deletePost function: ",
      error
    );
  }
}

// to get images of a car post
export function getPostImages(postId, iterations) {
  let images = [];
  const storage = getStorage();
  for (let i = 1; i <= iterations; i++) {
    getDownloadURL(ref(storage, `${postId}/image${i}`))
      .then((url) => {
        images.push(url);
      })
      .catch((error) => {
        console.log(
          "Couldn't generate URL for the cover photo in getPostImages function: ",
          error
        );
      });
  }

  return images;
}

export const createBid = async (payload) => {
  // payload: {post, dealer, amount, message, sellerEmail}
  try {
    const { id } = await addDoc(collection(db, BID_COLLECTION), payload);
    const chatId = await createChat({
      bid: id,
      seller: payload.seller,
      dealer: payload.dealer,
    });
    await addMsg({
      chatId,
      sender: payload.seller,
      messageText: payload.message,
    });
    await createNotification({
      type: "bidCreation",
      bidId: id,
      postId: payload.post,
      sellerId: payload.seller,
      dealerId: payload.dealer,
      postMake: payload.postMake,
      postModel: payload.postModel,
      postYear: payload.postYear,
      createdAt: new Date(),
    });

    // send email notification to seller
    await sendEmailNotification(
      payload.sellerEmail,
      "New Bid - MyTopDollar",
      "A bid has been placed on your post. Kindly check it out. https://mytopdollar.com/"
    );

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// to get seller document from its reference
export async function getSellerByRef(sellerRef) {
  try {
    const docRef = doc(db, "seller", sellerRef);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such seller document exists.");
    }
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getSellerByRef function: ",
      error
    );
  }
}

export async function getDealerByRef(dealerRef) {
  try {
    const docRef = doc(db, "dealer", dealerRef);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such seller document exists.");
    }
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getSellerByRef function: ",
      error
    );
  }
}

export async function getPosts() {
  try {
    const postCollectionRef = collection(db, "post");
    const response = await getDocs(postCollectionRef);
    let posts = response.docs.map((doc) => ({
      data: doc.data(),
      id: doc.id,
    }));
    return posts;
  } catch (error) {
    console.log("Something went wrong in firebase/getSeller funtion: ", error);
  }
}

// to get post document from its reference
export async function getPostByRef(postRef) {
  try {
    const docRef = doc(db, "post", postRef);
    const docSnap = await getDoc(docRef);
    const storage = getStorage();

    if (docSnap.exists()) {
      let post = {
        id: docSnap.id,
        ...docSnap.data(),
      };
      let imageName = 1;
      if (post.vehicleDetails?.carImageURLs !== undefined) {
        let ids = Object.keys(post.vehicleDetails.carImageURLs);
        ids = ids.map((id) => {
          id = id.replace(/[^\d.]/g, "");
          return parseInt(id, 10);
        });
        imageName = Math.min(...ids);
      }
      await getDownloadURL(ref(storage, `${postRef}/image${imageName}`))
        .then((url) => {
          post["coverPhoto"] = url;
        })
        .catch((error) => {
          console.log(
            "Couldn't generate URL for the cover photo in getDownloadURL function: ",
            error
          );
        });
      return post;
    } else {
      console.log("No such post document exists.");
    }
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getPostByRef function: ",
      error
    );
  }
}

export async function createDealer(userData, dealerDocRef) {
  try {
    await setDoc(doc(db, "dealer", dealerDocRef), userData);
  } catch (error) {
    console.log(
      "Something went wrong in firebase/createDealer funtion: ",
      error
    );
  }
}

export async function getAllPosts() {
  try {
    const postCollectionRef = collection(db, "post");
    let posts = [];
    const first = query(
      postCollectionRef,
      where("removed", "==", false),
      where("archived", "==", false)
    );
    const response = await getDocs(first);
    console.log(response);
    const promise = new Promise((resolve, reject) => {
      response.forEach(async (post) => {
        const data = post.data();
        let resp = data;
        resp.id = post.id;
        resp.totalPages = data.length;
        let imageName = 1;
        if (post.vehicleDetails?.carImageURLs !== undefined) {
          let ids = Object.keys(post.vehicleDetails?.carImageURLs);
          ids = ids.map((id) => {
            id = id.replace(/[^\d.]/g, "");
            return parseInt(id, 10);
          });
          imageName = Math.min(...ids);
        }
        const url = await getDownloadURL(
          ref(storage, `${post.id}/image${imageName}`)
        );
        resp.coverPhoto = url;
        posts.push(resp);
        resolve();
      });
    });
    return await promise.then(() => {
      posts = posts.sort(function (a, b) {
        return new Date(b.postedAt.seconds) - new Date(a.postedAt.seconds);
      });
      return posts;
    });
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getAllPosts function: ",
      error
    );
  }
}

// to get the associated bids for a car post
export async function getPostBids(postId) {
  try {
    const bidCollectionRef = collection(db, "bid");
    let bids = [];
    const querySnapshot = query(bidCollectionRef, where("post", "==", postId));
    const response = await getDocs(querySnapshot);

    response.forEach((doc) => {
      const data = doc.data();
      const post = data?.post;
      if (post === postId || data?.removed === false) {
        let resp = data;
        resp.docRef = doc.id;
        bids.push(resp);
      } else {
        return false;
      }
    });

    return bids;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getPostBids function: ",
      error
    );
  }
}

export async function getDealerByEmail(email) {
  let dealer;
  try {
    const q = query(collection(db, "dealer"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dealer = { ...doc.data(), docId: doc.id };
    });
    return dealer;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getDealerByEmail function: ",
      error
    );
  }
}

// to get bids received on posts of seller logged-in
export async function getAllBids(sellerRef) {
  try {
    const bidCollectionRef = collection(db, "bid");
    const querySnapshot = query(
      bidCollectionRef,
      where("seller", "==", sellerRef)
    );
    let bids = [];
    const response = await getDocs(querySnapshot);
    response.forEach((doc) => {
      bids.push(doc.data());
    });
    bids = bids.sort(function (a, b) {
      return new Date(b.offeredOn.seconds) - new Date(a.offeredOn.seconds);
    });
    return bids;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getAllBids function: ",
      error
    );
  }
}

export async function getBid(uid) {
  try {
    const bidRef = doc(collection(db, BID_COLLECTION), uid);
    const bid = await getDoc(bidRef);
    if (bid.exists()) return bid.data();
  } catch (error) {
    console.error(error);
  }
}

export async function getUserRef() {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    return user;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getUserRef function: ",
      error
    );
  }
}

export async function getPostBidsByDealerRef(dealerId) {
  try {
    const bidCollectionRef = collection(db, "bid");
    const querySnapshot = query(
      bidCollectionRef,
      where("dealer", "==", dealerId)
    );
    const response = await getDocs(querySnapshot);
    let bids = [];
    response.docs.forEach((e) => bids.push({ ...e.data(), bidId: e.id }));
    bids = bids.sort(function (a, b) {
      return new Date(b.offeredOn.seconds) - new Date(a.offeredOn.seconds);
    });
    return bids;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getPostBidsByDealerRef function: ",
      error
    );
  }
}

export async function updateBidByDealer(bidRef, data) {
  try {
    const bidDoc = doc(db, "bid", bidRef);
    await updateDoc(bidDoc, data);
    return true;
  } catch (error) {
    console.log("Something went wrong in firebase/updateBid function: ", error);
    return false;
  }
}

// to get number of bids on a post
export async function getNumberOfBids(postRef) {
  try {
    const bidCollectionRef = collection(db, "bid");
    const querySnapshot = query(bidCollectionRef, where("post", "==", postRef));
    const response = await getDocs(querySnapshot);
    return response.docs.length;
  } catch (error) {
    console.log(
      "Something went wrong in firebase/getNumberOfBids function: ",
      error
    );
  }
}
