import { Loader } from "@googlemaps/js-api-loader";

// env & API_KEY

const APIKeyProd = "AIzaSyD7mniycSi7ABkEH4WhnXyYQougr0ajfgY";
const APIKeyDev = process.env.REACT_APP_DEV_API_KEY;
const devEnv = process.env.NODE_ENV === "development";

if (!APIKeyDev) throw new Error("APIKeyDev is not set in environment");

const APIKey = devEnv ? APIKeyDev : APIKeyProd;

// loader

let ACSvc = null;
let GeoSvc = null;
let DistanceSvc = null;
let UnitSystem = null;

const googleApisLoader = new Loader({
  apiKey: APIKey,
  libraries: ["places", "geometry"],
  id: "google-apis",
});

export const loadGoogleApis = async () => {
  await googleApisLoader.load().then((google) => {
    ACSvc = new google.maps.places.AutocompleteService();
    GeoSvc = new google.maps.Geocoder();
    DistanceSvc = new google.maps.DistanceMatrixService();
    UnitSystem = google.maps.UnitSystem.IMPERIAL;
  });
};

export const getACSvc = async () => {
  if (ACSvc) return ACSvc;
  await loadGoogleApis();
  return ACSvc;
};

export const getGeoSvc = async () => {
  if (GeoSvc) return GeoSvc;
  await loadGoogleApis();
  return GeoSvc;
};

export const getDistanceSvc = async () => {
  if (DistanceSvc) return DistanceSvc;
  await loadGoogleApis();
  return DistanceSvc;
};

// main

// PlacePred = {
// 	desc: string
// 	placeId: string
// }

export const placePrediction = async (input) => {
  const ACS = await getACSvc();
  const res = await ACS.getPlacePredictions({ input, componentRestrictions: { country: 'US' } }) // prettier-ignore

  return res.predictions.map((c) => ({
    desc: c.description.replace(", USA", ""),
    placeId: c.place_id,
  }));
};

// PlaceDetails = {
// 	coords: Coords
// 	zipCode: string
// 	zipPre: number
// }

export const placeDetails = async (placeId) => {
  const GeoSvc = await getGeoSvc();
  const res = await GeoSvc.geocode({ placeId });

  const zipCode = res.results[0].address_components.filter(e => e.types.includes('postal_code'))[0].long_name // prettier-ignore
  const zipPre = Number(zipCode.slice(0, 3));
  const location = res.results[0].geometry.location;
  const lat = location.lat();
  const lng = location.lng();
  const coords = { lat, lng };

  return { coords, zipCode, zipPre };
};

// DistanceMatrix = {
// 	distance: number
// }

export const distanceMatrix = async (origin, destinations) => {
  const DistanceSvc = await getDistanceSvc();
  const res = await DistanceSvc.getDistanceMatrix({
    origins: [{ placeId: origin }],
    destinations: destinations,
    travelMode: "DRIVING",
    unitSystem: UnitSystem,
  });

  return res.rows[0].elements.map((e) => {
    const meters = e.distance.value;
    const miles = meters * 0.000621371192;
    return miles;
  });
};
