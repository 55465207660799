import { Link } from "react-router-dom";
import { ReactComponent as ConfirmationSVG } from "../../assets/SVG/Confirmation.svg";
import Button from "../../components/Button";
import Logo from "../../assets/images/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { initComState, resetCarDetails } from "../../redux/slices/comSlice";

export const ConfirmationPage = () => {
  const dispatch = useDispatch();

  const docRef = useSelector((state) => state.post.postId);

  dispatch(resetCarDetails(initComState));

  return (
    <div>
      {/* Logo */}
      <div className="absolute top-0 left-0 mt-10 ml-32">
        <img src={Logo} alt="logo" />
      </div>

      <div className="flex flex-col justify-center items-center h-screen">
        {/* Graphics on top */}
        <ConfirmationSVG />

        {/* Headlines */}
        <span className="text-4xl font-bold w-96 mt-8 text-center">
          Successfuly Posted!
        </span>
        <span className="font-normal text-sm text-center w-96 text-gray-400 p-4 mt-2">
          Your car post has been succesfully created
        </span>

        <div className="flex flex-row gap-32 mt-8">
          {/*Home page button on the bottom-left */}
          <Link to="/landingPage">
            <Button label="Home" type="secondary" />
          </Link>

          {/* See post button on the bottom-right */}
          <Link to="/viewPost" state={{ docRef }}>
            <Button label="See Post" />
          </Link>
        </div>
      </div>
    </div>
  );
};
