import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { Loader } from "../components/Loader";
import { Logout } from "../components/Logout";
import { Notifications } from "./Notifications";

export const SellerNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  useEffect(() => {
    if (notifications === []) setNotifications(location.state.notifications);
  }, [notifications, location]);

  return (
    <>
      {notifications === [] ? (
        <div
          className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
          style={{
            marginTop: `${windowHeight / 3}px`,
          }}
        >
          <Loader showLoader={""} />
        </div>
      ) : (
        <>
          <div className="flex h-screen">
            {/* left navigation */}
            <NavigationPanel allCars={true} />

            {/* main section */}
            <div className="flex flex-col w-full sm:pl-5">
              {/* page title */}
              <div className="flex flex-col mt-8 pl-6">
                <div className="w-full mt-20 sm:mt-0">
                  <span className="text-3xl font-bold">Notifications</span>
                </div>
              </div>

              <Logout calledBy={"Seller"} notifications={true} />
              {/* notifications list */}
              <Notifications calledBy={"Seller"} />

              {/* footer */}
              <div className="flex items-stretch h-full">
                <div className="self-end w-full">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
