import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo.png";
import { useDispatch } from "react-redux";
import { resetCarDetails } from "../redux/slices/comSlice";

// appears on the top of multi-step form for canceling the process
export const TopBar = ({ calledFrom }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {/* Logo of left */}
      <div className="absolute top-0 left-0 mt-8 ml-6 sm:mt-10 sm:ml-32">
        <img src={Logo} alt="logo" />
      </div>

      {/* Cancel Button on right */}
      <div className="absolute top-0 right-0 mt-6 mr-6 sm:mt-8 sm:mr-32">
        <Link to={calledFrom}>
          <button
            className="w-28 h-14 rounded-lg bg-gray-100 text-gray-600 text-sm text-bold"
            onClick={() => dispatch(resetCarDetails())}
          >
            Cancel
          </button>
        </Link>
      </div>
    </div>
  );
};
