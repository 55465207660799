import { TopBar } from "../../components/TopBar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import TabSwitch from "../../components/TabSwitch";
import { Loader } from "../Loader";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../services/firebase";
const functions = getFunctions(app);

export const CarInfoPage = ({
  calledFrom,
  formData,
  setFormData,
  stateChanger,
  ...rest
}) => {
  const [selectedLiscense, setSelectedLiscense] = useState(false);
  const [, setSelectedVIN] = useState(true);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [showLoader, setShowLoader] = useState("hidden");

  const selectedClass =
    "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]";
  const unSelectedClass =
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0";

  // Fields Animation
  const [VINPlaceholder, setVINPlaceHolder] = useState(
    formData?.VIN?.length > 0 ? selectedClass : unSelectedClass
  );
  const [licensePlaceholder, setLicensePlaceHolder] = useState(
    formData?.plateNumber?.length > 0 ? selectedClass : unSelectedClass
  );
  const [statePlaceholder, setStatePlaceHolder] = useState(
    formData?.registrationPlace?.length > 0 ? selectedClass : unSelectedClass
  );
  const [zipCodePlaceholder, setZipCodePlaceHolder] = useState(
    formData?.zipCode?.length > 0 ? selectedClass : unSelectedClass
  );

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if required fields are left empty
  const missingFields = () => setErrorMessage("Add missing fields first.");

  // validation alert if VIN lookup fails
  const invalidVINLookup = () =>
    setErrorMessage("Couldn't find any car against the VIN you entered.");

  // validation alert if license-plate lookup fails
  const invalidLicensePlateLookup = () =>
    setErrorMessage(
      "Couldn't find any car against the license-plate and state-code you entered."
    );

  // updating if anything is missing or not, whenever user inputs something
  useEffect(() => {
    if (
      (formData.plateNumber?.length <= 1 ||
        formData.registrationPlace?.length <= 1 ||
        formData.zipCode?.length <= 1) &&
      (formData.VIN?.length <= 1 || formData.zipCode?.length <= 1)
    ) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [
    formData.plateNumber,
    formData.registrationPlace,
    formData.zipCode,
    formData.VIN,
  ]);

  return (
    <div>
      {/* Logo and Cancel button */}
      <TopBar calledFrom={calledFrom} />

      <div className="flex flex-col justify-center items-center md:h-screen my-40 md:my-20">
        {/* Headlines */}
        <span className="text-4xl font-bold w-96 text-center">
          Enter your car details
        </span>
        <span className="font-normal text-sm text-center text-gray-400 mt-6 mb-14">
          Kindly fill your car details below
        </span>

        {/* Liscence Plate and VIN selectors */}
        <TabSwitch
          label1="VIN"
          onClick1={() => {
            setSelectedVIN(true);
            setSelectedLiscense(false);
          }}
          label2="License Plate"
          onClick2={() => {
            setSelectedLiscense(true);
            setSelectedVIN(false);
          }}
          selected={selectedLiscense ? 2 : 1}
        />

        {/* Liscence Plate and VIN interfaces */}
        {selectedLiscense ? (
          // if user selects license plate interface to proceed further
          <>
            {/* plate number text field */}
            <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
              <div>
                <span className={licensePlaceholder} style={{ zIndex: "-1" }}>
                  Plate Number
                </span>
                <input
                  onBlur={() => {
                    if (formData.plateNumber.length >= 1) {
                      setLicensePlaceHolder(selectedClass);
                    } else {
                      setLicensePlaceHolder(unSelectedClass);
                    }
                  }}
                  onFocus={() => {
                    setLicensePlaceHolder(selectedClass);
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="text"
                  title="License"
                  maxLength={8}
                  value={formData?.plateNumber}
                  onChange={(e) => {
                    setErrorMessage("");
                    setFormData({
                      ...formData,
                      plateNumber: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </div>
            </div>

            {/* location of car regitration field  */}
            <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
              <div>
                <span className={statePlaceholder} style={{ zIndex: "-1" }}>
                  Registration Place
                </span>
                <input
                  onBlur={() => {
                    if (formData.registrationPlace.length >= 1) {
                      setStatePlaceHolder(selectedClass);
                    } else {
                      setStatePlaceHolder(unSelectedClass);
                    }
                  }}
                  onFocus={() => {
                    setStatePlaceHolder(selectedClass);
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="text"
                  title="State"
                  value={formData?.registrationPlace}
                  onChange={(e) => {
                    setErrorMessage("");
                    if (e.target.value.length > 2) {
                      setErrorMessage("Invalid State Code Entered");
                    } else {
                      setErrorMessage("");
                      setFormData({
                        ...formData,
                        registrationPlace: e.target.value.toUpperCase(),
                      });
                    }
                  }}
                />
              </div>
            </div>

            {/* number field for zip code */}
            <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
              <div>
                <span className={zipCodePlaceholder} style={{ zIndex: "-1" }}>
                  Zip Code
                </span>
                <input
                  onBlur={() => {
                    if (formData.zipCode.length >= 1) {
                      setZipCodePlaceHolder(selectedClass);
                    } else {
                      setZipCodePlaceHolder(unSelectedClass);
                    }
                  }}
                  onFocus={() => {
                    setZipCodePlaceHolder(selectedClass);
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="number"
                  title="zipCode"
                  onInput={(e) => {
                    if (e.target.value.length > 5) {
                      e.target.value = e.target.value.slice(0, 5);
                    }
                  }}
                  value={formData?.zipCode}
                  onChange={(e) => {
                    setErrorMessage("");
                    setFormData({
                      ...formData,
                      zipCode: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          // if user selects VIN interface to proceed further
          <>
            {/* number field for VIN */}
            <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
              <div>
                <span className={VINPlaceholder} style={{ zIndex: "-1" }}>
                  VIN
                </span>
                <input
                  onBlur={() => {
                    if (formData.VIN.length >= 1) {
                      setVINPlaceHolder(selectedClass);
                    } else {
                      setVINPlaceHolder(unSelectedClass);
                    }
                  }}
                  onFocus={() => {
                    setVINPlaceHolder(selectedClass);
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="text"
                  title="VIN"
                  maxLength={17}
                  value={formData?.VIN}
                  onChange={(e) => {
                    setErrorMessage("");
                    setFormData({
                      ...formData,
                      VIN: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </div>
            </div>

            {/* number field for zip code */}
            <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
              <div>
                <span className={zipCodePlaceholder} style={{ zIndex: "-1" }}>
                  Zip Code
                </span>
                <input
                  onBlur={() => {
                    if (formData.zipCode.length >= 1) {
                      setZipCodePlaceHolder(selectedClass);
                    } else {
                      setZipCodePlaceHolder(unSelectedClass);
                    }
                  }}
                  onFocus={() => {
                    setZipCodePlaceHolder(selectedClass);
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="number"
                  title="zipCode"
                  maxLength={5}
                  value={formData?.zipCode}
                  onInput={(e) => {
                    if (e.target.value.length > 5) {
                      e.target.value = e.target.value.slice(0, 5);
                    }
                  }}
                  onChange={(e) => {
                    setErrorMessage("");
                    setFormData({
                      ...formData,
                      zipCode: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}

        {/* Error Message */}
        <div className="w-96">
          <p className="ml-2 mt-2" style={{ color: "red", fontSize: "15px" }}>
            {errorMessage}
          </p>
        </div>

        {/* Back and Next buttons on the bottom*/}
        <div className="flex justify-between w-11/12 sm:w-96 mt-8 mb-24">
          <Link to={calledFrom}>
            <Button label="Back" type="secondary" />
          </Link>

          <button
            className="flex w-32 h-14 rounded-lg text-sm font-bold bg-blue-500 text-white justify-center items-center"
            onClick={async () => {
              if (anythingMissing) {
                missingFields();
              } else {
                if (!selectedLiscense) {
                  if (formData.VIN.length !== 17) {
                    setErrorMessage("VIN must be 17 digit.");
                  } else {
                    setShowLoader("");
                    const callVindecoderAPI = httpsCallable(
                      functions,
                      "callVindecoderAPI"
                    );
                    const vindecoderResponse = await callVindecoderAPI(
                      formData.VIN
                    );

                    if (vindecoderResponse.data.body) {
                      setFormData({
                        ...formData,
                        ...vindecoderResponse.data,
                      });

                      setShowLoader("hidden");
                      stateChanger(1);
                    } else {
                      setShowLoader("hidden");
                      console.log(
                        "Something went wrong while calling Vindecoder API"
                      );
                      invalidVINLookup();
                    }
                  }
                } else {
                  if (formData.plateNumber.length < 6) {
                    setErrorMessage(
                      "Lisence plate numbers can only be 6 to 8 digits."
                    );
                  } else {
                    setShowLoader("");
                    const callCarsxeAPI = httpsCallable(
                      functions,
                      "callCarsxeAPI"
                    );
                    const payload = {
                      plate: formData.plateNumber,
                      state: formData.registrationPlace,
                    };
                    try {
                      const carsxeResponse = await callCarsxeAPI(payload);

                      setFormData({
                        ...formData,
                        ...carsxeResponse.data,
                      });

                      setShowLoader("hidden");
                      stateChanger(1);
                    } catch (error) {
                      setShowLoader("hidden");
                      console.log(
                        "Something went wrong while calling Carsxe API"
                      );
                      invalidLicensePlateLookup();
                    }
                  }
                }
              }
            }}
          >
            <Loader showLoader={showLoader} />
            Find Car
          </button>
        </div>
      </div>
    </div>
  );
};
