export const SkeletonLoaderGrid = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5">
      <OneSection />
      <OneSection />
      <OneSection />
      <OneSection />
      <OneSection />
      <OneSection />
    </div>
  );
};

const OneSection = () => {
  return (
    <div className="animate-pulse">
      <div className="flex flex-col items-center mb-4 p-6 gap-4 border-2 border-gray-300 rounded-xl">
        {/* image */}
        <div className="rounded-xl bg-gray-400 w-full aspect-video"></div>
        <div className="flex-1 space-y-4 mt-4 w-full">
          {/* one two-lined row, five times */}
          <div className="grid grid-cols-4">
            <div className="h-2 bg-gray-400 rounded"></div>
            <div className="h-2 bg-white rounded"></div>
            <div className="h-2 bg-gray-400 rounded col-span-2"></div>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="h-2 bg-gray-400 rounded"></div>
            <div className="h-2 bg-white rounded col-span-2"></div>
            <div className="h-2 bg-gray-400 rounded"></div>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="h-2 bg-gray-400 rounded"></div>
            <div className="h-2 bg-white rounded"></div>
            <div className="h-2 bg-gray-400 rounded col-span-2"></div>
          </div>
          <div className="grid grid-cols-4">
            <div className="h-2 bg-gray-400 rounded"></div>
            <div className="h-2 bg-white rounded col-span-2"></div>
            <div className="h-2 bg-gray-400 rounded"></div>
          </div>
          <div className="grid grid-cols-4">
            <div className="h-2 bg-gray-400 rounded"></div>
            <div className="h-2 bg-white rounded"></div>
            <div className="h-2 bg-gray-400 rounded col-span-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
