import React, { useState } from "react";
import MainTemplate from "../../Templates/MainTemplate";
import { Logout } from "../../components/Logout";
import { Chats } from "./Chats";
import ChatWindow from "./ChatWindow";

const Inbox = ({calledBy}) => {
  const [selectedChat, setSelectedChat] = useState();

  return (
    <MainTemplate>
      <div className="flex mt-8 pb-4 pl-6 border-b-2 border-gray-100">
        <span className="text-3xl font-bold">Inbox</span>
        <Logout calledBy={calledBy} />
      </div>
      <div className="container mx-3 my-3 shadow-sm rounded-lg border-2 min-h-[70%]">
        <div className="flex flex-row justify-between bg-white">
          <div className="flex flex-col w-2/5 border-r-2 overflow-y-auto bg-gray-100">
            <div className="border-b-2 py-4 px-2">
              <input
                type="text"
                placeholder="Search by car name"
                className="py-4 px-2 border-2 border-gray-200 rounded-2xl w-full"
              />
            </div>
            <Chats selectedChat={selectedChat} selectChat={setSelectedChat} />
          </div>

          <div className="w-full px-5 flex flex-col justify-between">
            {selectedChat && <ChatWindow id={selectedChat} />}
          </div>
        </div>
      </div>
    </MainTemplate>
  );
};

export default Inbox;
