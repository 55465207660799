import React from "react";
import cx from "classnames";
import { Loader } from "./Loader";

const common = "w-32 h-14 rounded-lg text-sm font-bold";
const tab = "w-32 h-10 rounded-lg text-sm font-bold";
const primary = "bg-blue-500 text-white";
const secondary = "bg-gray-200 text-gray-400";

const Button = ({
  label = "Button",
  onClick = null,
  type = "primary",
  className = "",
  loader = false,
}) => {
  return (
    <button
      className={cx(common, className, {
        [tab]: type === "tab",
        [primary]: type === "primary",
        [secondary]: type === "secondary",
      })}
      onClick={onClick}
    >
      {loader && <Loader />}
      {label}
    </button>
  );
};

export default Button;
