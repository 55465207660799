import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as ViewSVG } from "../../assets/SVG/View.svg";
import NoResult from "../../assets/images/no-results.png";
import { NavigationPanel } from "../../components/NavigationPanel";
import { getPostBidsByDealerRef } from "../../services/firebase";
import { useSelector } from "react-redux";
import { Logout } from "../../components/Logout";
import { Footer } from "../../components/Footer";
import { SkeletonLoader } from "../../components/SkeletonLoader";

export const MyBids = () => {
  const userId = useSelector((state) => state.user.userId);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [allBids, setAllBids] = useState("");
  const [currentBids, setCurrentBids] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [currentButton] = useState(
    "flex justify-center items-center w-12 h-12 bg-blue-500 rounded-lg  text-white font-bold"
  );
  const [pageButton] = useState(
    "flex justify-center items-center w-12 h-12 rounded-lg  text-dark font-bold"
  );

  // getting height of browser's internal window
  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  useEffect(() => {
    const getBids = async () => {
      await getPostBidsByDealerRef(userId).then((response) => {
        setTimeout(() => {
          if (response?.length > 0) {
            setAllBids(response);
            setCurrentBids(response.slice(0, 6));
            setTotalPages(Math.ceil(response.length / 6));
          } else {
            setAllBids(false);
          }
        }, 1000);
      });
    };

    if (!allBids || allBids === "") getBids();
  }, [allBids, userId]);

  useEffect(() => {
    const updateContent = async () => {
      if (currentPage === 1) {
        setCurrentBids(allBids.slice(0, 6));
      } else if (currentPage === totalPages) {
        setCurrentBids(allBids.slice((currentPage - 1) * 6, allBids.length));
      } else {
        setCurrentBids(allBids.slice((currentPage - 1) * 6, currentPage * 6));
      }
    };
    if (allBids !== "") {
      updateContent();
    }
  }, [currentPage, allBids, totalPages]);

  const pageChange = (page) => {
    setCurrentPage(page);
  };

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, [currentPage]);

  const pageButtons = (pages) => {
    let buttons = [];
    for (let i = 0; i < pages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            pageChange(i + 1);
          }}
          className={currentPage === i + 1 ? currentButton : pageButton}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className={currentBids === "" ? "flex h-screen" : "flex"}>
      <NavigationPanel user="dealer" bids={true} />
      <div className="flex flex-col w-full px-6 sm:pl-12 sm:pr-10">
        <div className="flex flex-row mt-8 pb-4 items-center border-gray-100 justify-between">
          <div className="w-full mt-20 sm:mt-0">
            {/* page title */}
            <span className="text-3xl font-bold">My Bids</span>
            {/* <p>{JSON.stringify(bids)}</p> */}
          </div>

          <Logout calledBy={"Dealer"} />
        </div>

        <div className="hidden md:grid grid-cols-7 items-center gap-4 mt-8 pb-4 text-xs text-gray-400 font-bold border-b-2">
          <span>
            {(currentBids?.length || "0") +
              " out of " +
              (allBids?.length || "0")}
          </span>
          <span>Make</span>
          <span>Model</span>
          <span>Year</span>
          <span>My Bid</span>
          <span>Submitted On</span>
        </div>

        {/* showing loader while fetching car bids of this user */}
        {allBids === "" ? (
          <SkeletonLoader total={6} />
        ) : (
          <>
            {/* showing car bids of this user if they exist */}
            {currentBids ? (
              <div className="overscroll-contain">
                {currentBids.map((post) =>
                  windowWidth > 768 ? (
                    <div
                      key={post?.offeredOn}
                      className="mt-8 grid grid-cols-7 gap-4 text-sm font-bold items-center"
                    >
                      <img
                        key={Date.now()}
                        src={post?.postCoverURL}
                        alt="cover"
                        className="aspect-video h-20 lg:h-24 xl:h-28 2xl:h-auto rounded-lg"
                      />
                      <span>{post?.postMake}</span>
                      <span>{post?.postModel}</span>
                      <span>{post?.postYear}</span>
                      <span>{post?.amount}</span>
                      <span>
                        {moment(moment.unix(post?.offeredOn?.seconds)).format(
                          "MM/DD/YYYY"
                        )}
                      </span>

                      {/* button for viewing a post */}
                      <div className="absolute right-12">
                        <Link
                          to={`/dealer/carDetails/${post?.post}`}
                          state={{ post }}
                        >
                          <ViewSVG />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={post?.offeredOn}
                      to={`/dealer/carDetails/${post?.post}`}
                      state={{ post }}
                    >
                      <div
                        key={post?.offeredOn}
                        className="mt-8 flex flex-col gap-4 text-sm font-bold items-center border border-gray-200 p-5 rounded-lg"
                      >
                        <img
                          key={Date.now()}
                          src={post?.postCoverURL}
                          alt="cover"
                          className="aspect-video rounded-lg"
                        />
                        <div className="w-full flex flex-col gap-2">
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Make
                            </span>
                            <span>{post?.postMake}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Modal
                            </span>
                            <span>{post?.postYear}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Year
                            </span>
                            <span>{post?.postYear}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              My Bid
                            </span>
                            <span>{post?.amount}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center pt-1">
                            <span className="text-xs text-gray-400 font-bold">
                              Submitted On
                            </span>
                            <span>
                              {moment(
                                moment.unix(post?.offeredOn?.seconds)
                              ).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                )}
              </div>
            ) : (
              <>
                {/* showing no results graphics, if this user doesn't have a car post */}
                <div
                  className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
                  style={{
                    marginTop: `${windowHeight / 4}px`,
                  }}
                >
                  <img className="w-44 " src={NoResult} alt="logo" />
                  <span className="text-4xl font-bold mt-8">
                    No Results Found
                  </span>
                  <span className="text-sm font-normal text-gray-400 mt-4">
                    Please try to search other vehicles
                  </span>
                </div>
              </>
            )}
          </>
        )}

        <div className="flex items-stretch h-full self-end w-full py-14 gap-3">
          {pageButtons(totalPages)}
        </div>
        {/* footer */}
        <div className="flex items-stretch -ml-9">
          <div className="self-end w-full">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
