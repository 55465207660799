import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, getBid, getDocumentByUid } from "./firebase";

const CHAT_COLLECTION = "chat";
const MSG_COLLECTION = "message";

// chat = {bid, seller, dealer}
export const createChat = async (chat) => {
  try {
    const { id } = await addDoc(collection(db, CHAT_COLLECTION), chat);
    await setDoc(doc(db, MSG_COLLECTION, id), { messages: [] });
    return id;
  } catch (error) {
    console.error("chat/createChat", error);
  }
};

export const getChat = async (uid) => {
  try {
    const chatRef = doc(collection(db, CHAT_COLLECTION), uid);
    const chat = await getDoc(chatRef);
    if (chat.exists()) {
      return getChatInfo(chat.data());
    }
  } catch (error) {
    console.error(error);
  }
};

export const getChatInfo = async (chat) => {
  const { bid, dealer, seller } = chat;
  const bidInfo = await getBid(bid);
  const dealerInfo = await getDocumentByUid(dealer, "dealer");
  const sellerInfo = await getDocumentByUid(seller, "seller");
  return { ...chat, bidInfo, dealerInfo, sellerInfo };
};

// userType = seller/dealer
export const getChats = async (uid, userType) => {
  try {
    const ref = collection(db, CHAT_COLLECTION);
    const chatsquery = query(ref, where(userType, "==", uid));
    const chats = await getDocs(chatsquery);
    return Promise.all(
      chats.docs.map(async (c) => {
        const chatDetails = await getChatInfo(c.data());
        return { ...chatDetails, id: c.id };
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const addMsg = async ({ chatId, sender, messageText }) => {
  const message = { messageText, sender, addedAt: Date.now() };

  try {
    const msgsRef = doc(db, MSG_COLLECTION, chatId);
    await updateDoc(msgsRef, { messages: arrayUnion(message) });
  } catch (error) {
    console.error(error);
  }
};

export const getMsgs = async (chatId) => {
  try {
    const ref = doc(collection(db, MSG_COLLECTION), chatId);
    const msgs = await getDoc(ref);
    console.log(msgs);
    if (msgs.exists()) return msgs.data();
    // return msgs.docs.map((c) => ({ ...c.data(), id: c.id }));
  } catch (error) {
    console.error(error);
  }
};

export const getMsgsRealtime = (chatId, setMsgs) => {
  const ref = doc(collection(db, MSG_COLLECTION), chatId);
  return onSnapshot(ref, (doc) => {
    return setMsgs(doc.data().messages);
  });
};
