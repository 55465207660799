import Button from "./Button";
import Modal from "./Modal";
import { archivePost } from "../services/firebase";

// appears when a user clicks on 'Archive' option from the dropdown menu on viewPost page
export const ArchivePostModal = ({ open, setOpen, post, setPost }) => {
  const turnArchive = async () => {
    post.archived = true;
    setPost({
      ...post,
      archived: true,
    });
    await archivePost(post?.docRef);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {/* Text lines */}
      <div className="text-center">
        <span className="text-lg font-bold">Archive Post</span>

        <hr className="mt-4 lg:mt-8 w-full text-gray-400" />

        <p className="text-sm font-bold mt-10">
          Are you sure you want to archive this post?
        </p>
      </div>

      {/* buttons at the bottom of the modal */}
      <div className="mt-12 mb-4 lg:mb-8 grid grid-cols-2 justify-items-center gap-6">
        <Button label="No" onClick={() => setOpen(false)} type="secondary" />
        <Button label="Yes" onClick={async () => await turnArchive()} />
      </div>
    </Modal>
  );
};
