import { useLocation, useNavigate } from "react-router-dom";
import { updatePost } from "../services/firebase";
import { useEffect, useState } from "react";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { ReactComponent as ImageAppendSVG } from "../assets/SVG/ImageAppend.svg";
import { ReactComponent as ImageDiscardSVG } from "../assets/SVG/ImageDiscard.svg";
import { Logout } from "../components/Logout";
import { NumericFormat } from "react-number-format";

// styling class for the tab selected by the user
const selectedTab =
  "w-32 h-10 rounded-lg bg-blue-50 text-xs text-blue-500 font-bold";

// styling class for the tab not selected by the user
const unselectedTab =
  "w-32 h-10 rounded-lg bg-gray-100 text-xs text-gray-400 font-normal";

const carStyle = [
  { id: "", title: "Select Car Style" },
  { id: "4door", title: "4 Door" },
  { id: "1.8L", title: "1.8L" },
];

const typeOfDrive = [
  { id: "awd", title: "All wheel drive" },
  { id: "fwd", title: "Front wheel drive" },
  { id: "rwd", title: "Rear wheel drive" },
  { id: "4wd", title: "4 wheel drive" },
];

const typeOfTransmission = [
  { id: "manual", title: "Manual" },
  { id: "automatic", title: "Automatic" },
];

const vehicleDrive = [
  { id: "q1yes", title: "Yes" },
  { id: "q1no", title: "No" },
  { id: "q1maybe", title: "Maybe" },
];

const dashboardWarnings = [
  { id: "q2yes", title: "Yes" },
  { id: "q2no", title: "No" },
  { id: "q2maybe", title: "Maybe" },
];

const odomenterReplacement = [
  { id: "q3yes", title: "Yes" },
  { id: "q3no", title: "No" },
  { id: "q3maybe", title: "Maybe" },
];

const mechanicalCondition = [
  { id: "q4yes", title: "Yes" },
  { id: "q4no", title: "No" },
  { id: "q4maybe", title: "Maybe" },
];

const milesOnTyres = [
  { id: "<5,000 miles", title: "<5,000 miles" },
  { id: "5,000-10,000 miles", title: "5,000-10,000 miles" },
  { id: "10,000-20,000 miles", title: "10,000-20,000 miles" },
  { id: ">20,000 miles", title: ">20,000 miles" },
];

export const EditPost = () => {
  const [activeStatus, setActiveStatus] = useState(1);
  const [post, setPost] = useState("");
  const [editedPost, setEditedPost] = useState({});
  const [imageList, setImageList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/viewPost", {
      state: { docRef: location.state.post?.docRef },
    });
  };

  useEffect(() => {
    if (location?.state?.post) {
      setPost(location.state.post);
      setImageList(
        Object.values(location.state.post.vehicleDetails.carImageURLs)
      );
      setEditedPost({
        ...location.state.post,
        existingImagesCount: location.state.post.vehicleDetails["carImages"],
        discardedImages: [],
      });
    }
  }, [location]);

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, []);

  // adding images at the end to be uploaded
  function onImageAdd(e) {
    let temp = Object.assign([], imageList);
    let items = e.target.files;
    items = Object.assign([], items);
    items.forEach((item) => {
      temp.push(item);
    });
    setImageList(temp);
    setEditedPost({
      ...editedPost,
      carImages: temp,
      vehicleDetails: {
        ...editedPost.vehicleDetails,
        carImages: temp.length,
      },
    });
  }

  return (
    <div className="flex h-screen">
      {/* left navigation */}
      <NavigationPanel allCars={true} />

      {/* main section */}
      <div className="w-full flex flex-col px-6 sm:pl-12 sm:pr-10">
        {/* page title */}
        <div className="flex mt-8 pb-4">
          <div className="w-full mt-20 sm:mt-0">
            <span className="text-3xl font-bold">
              All Cars / View Post / Edit Post
            </span>
          </div>
        </div>

        <Logout calledBy={"Seller"} />

        <div className="mt-6 py-3 px-4 border-2 border-gray-100 rounded-xl">
          {/* Car image, milage section */}
          <div className="flex flex-col sm:flex-row items-center gap-8">
            {/* car image */}
            <img
              src={post?.coverPhoto}
              alt="cover"
              className="sm:h-20 aspect-video rounded-lg bg-no-repeat bg-cover"
            />

            {/* car info */}
            <div className="flex flex-col gap-4">
              {/* year, make, model */}
              <p className="text-base font-bold">
                {post?.vehicleDetails?.["year"] +
                  " " +
                  post?.vehicleDetails?.["make"] +
                  " " +
                  post?.vehicleDetails?.["model"]}
              </p>
              <div className="flex flex-row gap-16 text-sm font-normal">
                <div className="flex flex-col gap-2">
                  <p>Miles away</p>
                  <p className="text-gray-400">2 km</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p>VIN</p>
                  <p className="text-gray-400">
                    {post?.plateNumber || post?.VIN}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 3 tabs on top */}
        <div className="flex space-x-2 my-8">
          {/* body details tab */}
          <Button
            label="Body Details"
            className={activeStatus === 1 ? selectedTab : unselectedTab}
            type="tab"
            onClick={() => {
              setActiveStatus(1);
            }}
          />

          {/* generic details tab */}
          <Button
            label="Generic Details"
            className={activeStatus === 2 ? selectedTab : unselectedTab}
            type="tab"
            onClick={() => {
              setActiveStatus(2);
            }}
          />

          {/* car images tab */}
          <Button
            label="Car Images"
            className={activeStatus === 3 ? selectedTab : unselectedTab}
            type="tab"
            onClick={() => {
              setActiveStatus(3);
            }}
          />
        </div>

        <div
          className={
            activeStatus === 1
              ? "sm:pl-6 grid grid-cols-12 gap-4 text-sm items-center py-4 px-6 border-2 border-gray-100 rounded-xl"
              : "hidden"
          }
        >
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl focus:outline-0">
            <select
              id="carStyle"
              name="carStyle"
              className="w-full text-gray-500 bg-white focus:outline-0 font-bold"
              placeholder="Select Car Style"
              value={editedPost?.vehicleDetails?.["style"] || ""}
              onChange={(e) => {
                setEditedPost({
                  ...editedPost,
                  vehicleDetails: {
                    ...editedPost.vehicleDetails,
                    style: e.target.value,
                  },
                });
              }}
            >
              {carStyle.map((style) => (
                <option
                  key={
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15)
                  }
                >
                  {style.title}
                </option>
              ))}
            </select>
          </div>

          {/* Drive type radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Please select the type of drive
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Type of drive</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {typeOfDrive.map((driveType) => (
                  <div
                    key={driveType.id}
                    className="flex items-center lg:w-1/4"
                  >
                    <label className="flex items-center">
                      <input
                        id={driveType.id}
                        name="typeOfDrive"
                        type="radio"
                        checked={
                          editedPost.vehicleDetails?.["drive"] ===
                          driveType.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            vehicleDetails: {
                              ...editedPost.vehicleDetails,
                              drive: driveType.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={driveType.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {driveType.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          {/* Transmission type radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Please select the transmission type of your car
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Type of transmission</legend>
              <div className="space-y-4 md:flex md:items-center md:space-y-0 md:space-x-10">
                {typeOfTransmission.map((transmissionType) => (
                  <div
                    key={transmissionType.id}
                    className="flex items-center md:w-1/4"
                  >
                    <label className="flex items-center">
                      <input
                        id={transmissionType.id}
                        name="typeOfTransmission"
                        type="radio"
                        checked={
                          editedPost?.vehicleDetails?.["transmission"] ===
                          transmissionType.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            vehicleDetails: {
                              ...editedPost.vehicleDetails,
                              transmission: transmissionType.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={transmissionType.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {transmissionType.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          {/* Car features check boxes */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <fieldset>
              <p className="text-gray-400">
                Please select the features of your car
              </p>

              <div className="md:flex lg:flex-col">
                {/* first row of checkboxes */}
                <div className="mr-24 lg:mr-0 mt-4 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                  {/* bluetooth checkbox */}
                  <div className="flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="bluetooth"
                          aria-describedby="bluetooth-description"
                          name="bluetooth"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  bluetooth: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"][
                              "bluetooth"
                            ]
                          }
                        />
                        <span
                          htmlFor="bluetooth"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Bluetooth
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* navigation checkbox */}
                  <div className="flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="navigation"
                          aria-describedby="navigation-description"
                          name="navigation"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  navigation: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"][
                              "navigation"
                            ]
                          }
                        />
                        <span
                          htmlFor="navigation"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Navigation
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* speakers checkbox */}
                  <div className="flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="speakers"
                          aria-describedby="speakers-description"
                          name="speakers"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  speakers: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"]["speakers"]
                          }
                        />
                        <span
                          htmlFor="speakers"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Speakers
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* AC checkbox */}
                  <div className=" flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="ac"
                          aria-describedby="ac-description"
                          name="ac"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  AC: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"]["AC"]
                          }
                        />
                        <span
                          htmlFor="ac"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          AC
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* second row of check boxes */}
                <div className="mt-4 lg:mt-3 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                  <div className=" flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="childLock"
                          aria-describedby="childLock-description"
                          name="childLock"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  childLock: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"][
                              "childLock"
                            ]
                          }
                        />
                        <span
                          htmlFor="childLock"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Child Lock
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* ABS checkbox */}
                  <div className=" flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="abs"
                          aria-describedby="abs-description"
                          name="abs"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  ABS: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"]["ABS"]
                          }
                        />
                        <span
                          htmlFor="abs"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Anti Lock Braking
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* backup camera checkbox */}
                  <div className=" flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="backupCamera"
                          aria-describedby="backupCamera-description"
                          name="backupCamera"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  backupCamera: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"][
                              "backupCamera"
                            ]
                          }
                        />
                        <span
                          htmlFor="backupCamera"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Backup Camera
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* wing mirrors checkbox */}
                  <div className=" flex items-start lg:w-1/4">
                    <div className="flex h-5 items-center">
                      <label className="flex items-center">
                        <input
                          id="wingMirrors"
                          aria-describedby="wingMirrors-description"
                          name="wingMirrors"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          onClick={(e) => {
                            setEditedPost({
                              ...editedPost,
                              vehicleDetails: {
                                ...editedPost.vehicleDetails,
                                features: {
                                  ...editedPost.vehicleDetails?.["features"],
                                  wingMirrors: e.target.checked,
                                },
                              },
                            });
                          }}
                          defaultChecked={
                            editedPost?.vehicleDetails?.["features"][
                              "wingMirrors"
                            ]
                          }
                        />
                        <span
                          htmlFor="wingMirrors"
                          className="ml-3 text-sm font-normal text-gray-400"
                        >
                          Wing Mirrors
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* Mileage of car radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Please select the mileage of car tires
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Car Mileage</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {milesOnTyres.map((milesOnTyres) => (
                  <div
                    key={milesOnTyres.id}
                    className="flex items-center lg:w-1/4"
                  >
                    <label className="flex items-center">
                      <input
                        id={milesOnTyres.id}
                        name="milesOnTyres"
                        type="radio"
                        checked={
                          milesOnTyres.title ===
                          editedPost?.additionalInformation?.["milesOnTyres"]
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            additionalInformation: {
                              ...editedPost?.additionalInformation,
                              milesOnTyres: milesOnTyres.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={milesOnTyres.id}
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        {milesOnTyres.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>

        <div
          className={
            activeStatus === 2
              ? "grid grid-cols-12 gap-4 text-sm items-center py-4 px-6 border-2 border-gray-100 rounded-xl"
              : "hidden"
          }
        >
          <div className="col-span-12">
            <NumericFormat
              className="w-full p-7 bg-white border border-2 border-gray-100 rounded-xl focus:outline-0 font-bold"
              title="Expected Price"
              thousandSeparator=","
              placeholder="Enter your expected price"
              value={
                editedPost?.vehicleDetails?.["optional"]?.["expectedPrice"] ||
                ""
              }
              onChange={(e) => {
                setEditedPost({
                  ...editedPost,
                  vehicleDetails: {
                    ...editedPost.vehicleDetails,
                    optional: {
                      ...editedPost.vehicleDetails?.["optional"],
                      expectedPrice: e.target.value,
                    },
                  },
                });
              }}
            ></NumericFormat>
          </div>

          {/* vehicle driver radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">Does your vehicle drive?</p>
            <fieldset className="mt-4">
              <legend className="sr-only">Vehicle Drive</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {vehicleDrive.map((drive) => (
                  <div key={drive.id} className="flex items-center lg:w-1/4">
                    <label className="flex items-center">
                      <input
                        id={drive.id}
                        name="vehicleDrive"
                        type="radio"
                        checked={
                          editedPost?.mechanicalCondition?.["drive"] ===
                          drive.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            mechanicalCondition: {
                              ...editedPost.mechanicalCondition,
                              drive: drive.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={drive.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {drive.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          {/* dashboard warning radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Are there any dashboard warning lights on?
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Dashboard Warning</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {dashboardWarnings.map((warning) => (
                  <div key={warning.id} className="flex items-center lg:w-1/4">
                    <label className="flex items-center">
                      <input
                        id={warning.id}
                        name="typeOfWarning"
                        type="radio"
                        checked={
                          editedPost?.mechanicalCondition?.["warningLights"] ===
                          warning.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            mechanicalCondition: {
                              ...editedPost.mechanicalCondition,
                              warningLights: warning.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={warning.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {warning.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            {/* In case of dashboard warning lights */}
            {editedPost?.mechanicalCondition?.["warningLights"] === "Yes" ? (
              <>
                <input
                  className="w-full px-2 py-5 sm:p-5 mt-3 border border-2 border-gray-100 rounded-xl focus:outline-0"
                  type="text"
                  title="Mechanical Condition Explanation"
                  placeholder="If yes, please enter your answer"
                  value={
                    editedPost?.mechanicalCondition?.["ifWarningLights"] || ""
                  }
                  onChange={(e) => {
                    setEditedPost({
                      ...editedPost,
                      mechanicalCondition: {
                        ...editedPost.mechanicalCondition,
                        ifWarningLights: e.target.value,
                      },
                    });
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          {/* odometer replacement radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Has the odometer ever been replaced or modified?
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Odometer Replacement</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {odomenterReplacement.map((replacement) => (
                  <div
                    key={replacement.id}
                    className="flex items-center lg:w-1/4"
                  >
                    <label className="flex items-center">
                      <input
                        id={replacement.id}
                        name="typeOfReplacement"
                        type="radio"
                        checked={
                          editedPost?.mechanicalCondition?.[
                            "odometerReplace"
                          ] === replacement.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            mechanicalCondition: {
                              ...editedPost.mechanicalCondition,
                              odometerReplace: replacement.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={replacement.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {replacement.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          {/* mechanical repair radio buttons */}
          <div className="col-span-12 p-5 border border-2 border-gray-100 rounded-xl">
            <p className="text-sm text-gray-400">
              Are any mechanical repairs needed?
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Mechanical Repair</legend>
              <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {mechanicalCondition.map((condition) => (
                  <div
                    key={condition.id}
                    className="flex items-center lg:w-1/4"
                  >
                    <label className="flex items-center">
                      <input
                        id={condition.id}
                        name="typeOfCondition"
                        type="radio"
                        checked={
                          editedPost?.mechanicalCondition?.["repairsNeeded"] ===
                          condition.title
                        }
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setEditedPost({
                            ...editedPost,
                            mechanicalCondition: {
                              ...editedPost.mechanicalCondition,
                              repairsNeeded: condition.title,
                            },
                          });
                        }}
                      />
                      <span
                        htmlFor={condition.id}
                        className="ml-3 text-sm font-normal text-gray-400"
                      >
                        {condition.title}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            {/* In case of repairs needed */}
            {editedPost?.mechanicalCondition?.["repairsNeeded"] === "Yes" ? (
              <>
                <input
                  className="w-full px-2 py-5 sm:p-5 mt-3 border border-2 border-gray-100 rounded-xl focus:outline-0"
                  type="text"
                  title="Mechanical Condition Explanation"
                  placeholder="If yes, please enter your answer"
                  value={
                    editedPost?.mechanicalCondition?.["ifRepairsNeeded"] || ""
                  }
                  onChange={(e) => {
                    setEditedPost({
                      ...editedPost,
                      mechanicalCondition: {
                        ...editedPost.mechanicalCondition,
                        ifRepairsNeeded: e.target.value,
                      },
                    });
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className={
            activeStatus === 3
              ? `mb-60 py-4 px-6 grid sm:grid-cols-2 md:grid-cols-3 ${
                  imageList?.length > 3 ? `xl:grid-cols-4` : ""
                } text-sm font-bold items-center border-2 border-gray-100 rounded-xl gap-2`
              : "hidden"
          }
        >
          {imageList?.map((image, i) => {
            return (
              <div
                key={i}
                className="rounded-lg bg-cover no-repeat object-fill aspect-video object-scale-down"
                style={{
                  backgroundImage:
                    typeof image === "string"
                      ? `url(${image})`
                      : `url(${URL.createObjectURL(image)})`,
                }}
              >
                {/* cross-button on top of an image */}
                <button
                  className="m-2 float-right rounded-lg bg-cover no-repeat object-fill aspect-video object-scale-down"
                  onClick={(e) => {
                    const newImages = imageList.filter((thisImage) => {
                      return thisImage !== image;
                    });
                    setImageList(newImages);
                    setEditedPost({
                      ...editedPost,
                      discardedImages: editedPost.discardedImages.push(image),
                    });
                    setEditedPost({
                      ...editedPost,
                      carImages: newImages,
                      vehicleDetails: {
                        ...editedPost.vehicleDetails,
                        carImages: newImages.length,
                      },
                    });
                  }}
                >
                  <ImageDiscardSVG />
                </button>
              </div>
            );
          })}
          {/* Add image button */}
          <button className="flex w-full justify-center items-center outline-dashed outline-1 outline-offset-2 outline-gray-200 rounded-lg aspect-video">
            <label htmlFor="file-upload" className="custom-file-upload-2">
              <ImageAppendSVG />
            </label>

            <input
              id="file-upload"
              type="file"
              accept="image/*"
              multiple={true}
              onChange={(e) => {
                onImageAdd(e);
              }}
            />
          </button>
        </div>

        {/* options to update or discard */}
        <div className="flex space-x-2 my-8 mb-20">
          <Link to="/viewPost" state={{ docRef: editedPost?.docRef }}>
            <Button label="Discard" type="secondary" onClick={() => {}} />
          </Link>
          <Button
            label="Update"
            onClick={async () => {
              await updatePost(editedPost?.docRef, editedPost);
              handleClick();
            }}
          />
        </div>

        {/* footer */}
        <div className="flex items-stretch -ml-9">
          <div className="self-end w-full">
            {/* footer */}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
