import { NavigationPanel } from "../../components/NavigationPanel";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllPosts, getDealerByRef } from "../../services/firebase";
import { Logout } from "../../components/Logout";
import { Footer } from "../../components/Footer";
import { distanceMatrix } from "../../common/maps";
import { useSelector } from "react-redux";
import { SkeletonLoaderGrid } from "../../components/SkeletonLoaderGrid";
import NoResult from "../../assets/images/no-results.png";

export const DealerHome = () => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [allPosts, setAllPosts] = useState("");
  const [currentPosts, setCurrentPosts] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [currentButton] = useState(
    "flex justify-center items-center w-12 h-12 bg-blue-500 rounded-lg  text-white font-bold"
  );
  const [pageButton] = useState(
    "flex justify-center items-center w-12 h-12 rounded-lg  text-dark font-bold"
  );

  // getting height of browser's internal window
  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getAllPosts();
      console.log(response);
      console.log(response.length);
      if (response?.length !== 0) {
        const dealerInfo = await getDealerByRef(userId);
        if (!dealerInfo?.placeId) {
          setAllPosts(response);
          setCurrentPosts(response.slice(0, 6));
          setTotalPages(Math.ceil(response.length / 6));
        }

        let destinations = response.filter((d) => d.postedBy?.placeId);

        if (destinations.length > 25) {
          destinations = destinations.splice(0, 25);
        }

        const distances = await distanceMatrix(
          dealerInfo.placeId,
          destinations.map((d) => ({ placeId: d.postedBy.placeId }))
        );

        const filteredPosts = destinations.filter((d, idx) => {
          if (distances[idx] < 200) {
            d.milesAway = distances[idx];
            return d;
          } else {
            return null;
          }
        });

        setTimeout(() => {
          const sortedPosts = filteredPosts.sort(function (a, b) {
            return new Date(b.postedAt.seconds) - new Date(a.postedAt.seconds);
          });

          setAllPosts(sortedPosts);
          setCurrentPosts(sortedPosts.slice(0, 6));
          setTotalPages(Math.ceil(sortedPosts.length / 6));
        }, 1000);
      } else {
        setCurrentPosts(response);
      }
    };
    fetchPosts();
  }, [userId]);

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const updateContent = async () => {
      if (currentPage === 1) {
        setCurrentPosts(allPosts.slice(0, 6));
      } else if (currentPage === totalPages) {
        setCurrentPosts(allPosts.slice((currentPage - 1) * 6, allPosts.length));
      } else {
        setCurrentPosts(allPosts.slice((currentPage - 1) * 6, currentPage * 6));
      }
    };

    updateContent();
  }, [currentPage, allPosts, totalPages]);

  const pageChange = (page) => {
    setCurrentPage(page);
  };

  const pageButtons = (pages) => {
    let buttons = [];
    for (let i = 0; i < pages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            pageChange(i + 1);
          }}
          className={currentPage === i + 1 ? currentButton : pageButton}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className={currentPosts.length < 4 ? "flex h-screen" : "flex"}>
      <NavigationPanel user="dealer" allCars={true} />
      <div className="flex flex-col w-full h-full px-6 sm:pl-12 sm:pr-10">
        <div className="flex flex-row mt-8 pb-4 items-center border-gray-100 justify-between">
          <div className="mt-20 sm:mt-0">
            {/* page title */}
            <span className="text-3xl font-bold">All Cars</span>
          </div>

          <Logout calledBy={"Dealer"} />
        </div>

        {/* Page Content */}
        <div className="mt-8 flex flex-col h-full overscroll-contain overflow-auto justify-between">
          {currentPosts !== "" ? (
            <>
              {currentPosts.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 grid-flow-row gap-4">
                  {currentPosts.map((post) => (
                    <div
                      key={post?.id}
                      onClick={() => {
                        navigate(`/dealer/carDetails/${post.id}`);
                      }}
                      className="bg-white rounded-lg border border-gray-200 cursor-pointer"
                    >
                      <div className="p-5">
                        <img
                          className="rounded-lg w-full aspect-video"
                          src={post?.coverPhoto}
                          alt=""
                        />
                      </div>
                      <div className="pl-5 pr-5">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                          {`${post?.vehicleDetails.year} ${post?.vehicleDetails.make} ${post?.vehicleDetails.model}`}
                        </h5>
                        <div>
                          <p className="pb-2 pt-3 border-b">
                            <span>Mileage</span>
                            <span className="font-bold float-right">
                              {post?.vehicleDetails?.mileage}
                            </span>
                          </p>
                          <p className="pb-2 pt-3 border-b">
                            <span>Seller Location</span>
                            <span className="font-bold float-right">
                              {post?.postedBy?.address.split(",").length > 1
                                ? post?.postedBy?.address.split(",")[
                                    post?.postedBy?.address.split(",").length -
                                      2
                                  ] +
                                  ", " +
                                  post?.postedBy?.address.split(",")[
                                    post?.postedBy?.address.split(",").length -
                                      1
                                  ]
                                : post?.postedBy?.address.split(",")[0]}
                            </span>
                          </p>
                          <p className="pb-2 pt-3 border-b">
                            <span>Miles away</span>
                            <span className="font-bold float-right">
                              {Math.ceil(post.milesAway)}
                            </span>
                          </p>
                          <p className="pb-4 pt-3">
                            <span>Engine configuration</span>
                            <span className="font-bold float-right">
                              Inline
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div
                    className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
                    style={{
                      marginTop: `${windowHeight / 4}px`,
                    }}
                  >
                    <img className="w-44 " src={NoResult} alt="logo" />
                    <span className="text-4xl font-bold mt-8">
                      No Results Found
                    </span>
                    <span className="text-sm font-normal text-gray-400 mt-4">
                      Please try to search other vehicles
                    </span>
                  </div>
                </>
              )}
            </>
          ) : (
            <SkeletonLoaderGrid />
          )}
          <div className="flex items-stretch h-full self-end w-full py-14 gap-3">
            {pageButtons(totalPages)}
          </div>
          {/* footer */}
          <div className="flex items-stretch -ml-9">
            <div className="self-end w-full">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
