import { ReactComponent as VerificationSVG } from "../../assets/SVG/Verification.svg";
import Logo from "../../assets/images/Logo.png";
import { LoginGraphicsLeft } from "../../components/LoginGraphics";
import { signout } from "../../services/firebase";
import { useDispatch } from "react-redux";
import { setUserId } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const VerificationPage = ({ calledBy, formData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userType = useSelector((state) => state.user.userType);
  const email = useSelector((state) => state.user.sessionEmail);

  const dealerClass1 = "justify-center items-center my-20 sm:my-0";
  const dealerClass2 =
    "grid grid-cols-1 lg:grid-cols-2 justify-center items-center";

  const toSignup = async () => {
    dispatch(setUserId(""));
    await signout();
    navigate("/", { state: { calledFrom: "verification" } });
  };

  return (
    <div className={calledBy === "dealer" ? dealerClass1 : " "}>
      <div className={calledBy === "dealer" ? dealerClass2 : " "}>
        {calledBy === "Seller" && (
          // Logo on tep left
          <div className="absolute top-0 left-0 mt-10 ml-32">
            <img src={Logo} alt="logo" />
          </div>
        )}

        {calledBy === "dealer" && (
          <LoginGraphicsLeft
            text="Already have an account?"
            label="Login"
            path="/login/dealerForm"
          />
        )}

        <div className="flex flex-col justify-center items-center h-screen">
          {/* Graphics on top */}
          <VerificationSVG />

          {/* Headlines */}
          <span className="text-4xl font-bold w-96 mt-8 text-center">
            Verify your email
          </span>
          <span className="font-normal text-sm text-center w-96 text-gray-400 mt-2">
            We have sent a confirmation link to{" "}
            {formData?.email !== undefined ? formData.email : email}
            <br />
            Click on the link to verify your account
          </span>
          {userType === "seller" ? (
            <button
              onClick={() => toSignup()}
              className="mt-8 w-11/12 sm:w-96 h-14 rounded-lg bg-blue-500 text-sm text-white font-bold mb-24"
            >
              Home
            </button>
          ) : (
            <Link to={"/login/dealerForm"} className="w-11/12 sm:w-96">
              <button className="mt-8 w-full h-14 lg:hidden rounded-lg bg-blue-500 text-sm text-white font-bold mb-24">
                Login
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
