import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import { signup, createDealer } from "../../services/firebase";
import { VerificationPage } from "./Verification";
import Logo from "../../assets/images/Logo.png";
import { ReactComponent as AddressLocationSVG } from "../../assets/SVG/addressLocation.svg";
import { ReactComponent as ContactSVG } from "../../assets/SVG/Contact.svg";
import { ReactComponent as DealerShipNameSVG } from "../../assets/SVG/DealershipName.svg";
import { ReactComponent as EmailSVG } from "../../assets/SVG/Email.svg";
import { ReactComponent as FirstNameSVG } from "../../assets/SVG/Person.svg";
import { ReactComponent as LastNameSVG } from "../../assets/SVG/Person.svg";
import { ReactComponent as HidePasswordSVG } from "../../assets/SVG/HidePassword.svg";
import { ReactComponent as PasswordSVG } from "../../assets/SVG/Password.svg";
import { ReactComponent as ShowPasswordSVG } from "../../assets/SVG/ShowPassword.svg";
import { LoginGraphicsLeft } from "../../components/LoginGraphics";
import {
  setSessionEmail,
  setUserId,
  setUserType,
} from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { sendEmailVerification } from "firebase/auth";
import { placePrediction } from "../../common/maps";
import { Combobox } from "@headlessui/react";
import { Link } from "react-router-dom";

export const DealerSignup = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [address, setAddress] = useState("");
  const [dealership, setDealership] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [successfullSignup, setSuccessfullSignup] = useState(false);
  const [confirmationPage, setConfirmationPage] = useState(false);

  const [showLock, setShowLock] = useState(true);

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // Field Animations
  const [emailPlaceholder, setEmailPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [passwordPlaceholder, setPasswordPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [firstNamePlaceholder, setfirstNamePlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [lastNamePlaceholder, setlastNamePlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [contactNumberPlaceholder, setcontactNumberPlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [addressPlaceholder, setAddressPlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [dealershipPlaceholder, setDealershipPlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  const [emailFocused, setEmailFocused] = useState(false);
  const [dealerFocused, setDealerFocused] = useState(false);
  const [addressFocused, setAddressFocused] = useState(false);
  const [fnameFocused, setFnameFocused] = useState(false);
  const [lnameFocused, setLnameFocused] = useState(false);
  const [contactFocused, setContactFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [formData, setFormData] = useState({});

  const [togglePasswordDisplay, setTogglePasswordDisplay] =
    useState("password");

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  // validation alert if any field is left empty
  const missingFields = () => setErrorMessage("Email or Password is missing.");

  // validation alert if invalid email address is provided
  const invalidEmail = () => setErrorMessage("Invalid Email address provided");

  // validation alert if Agreement not checked
  const incompleteAgree = () =>
    setErrorMessage("Please agree to the terms & conditions");

  // validation alert if already registered email address is provided
  const emailInUse = () =>
    setErrorMessage("The Email you provided is already in use");

  const weakPassword = () =>
    setErrorMessage(
      "Weak password provided. Password should be atleast 6 characters long"
    );

  // validation alert in case of failing to sign up
  const signupFailed = () =>
    setErrorMessage("Signup failed. Please try again.");

  // Confirmation Page
  const showConfirmation = (prop) => {
    setConfirmationPage(prop);
  };
  useEffect(() => {
    if (
      firstName?.length <= 1 ||
      lastName?.length <= 1 ||
      contactNumber?.length <= 1 ||
      email?.length <= 1 ||
      password?.length <= 1 ||
      dealership.length <= 1 ||
      selectedLocation === null
    ) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [
    firstName,
    lastName,
    email,
    password,
    contactNumber,
    termsAgree,
    dealership,
    selectedLocation,
  ]);

  // process of signing up the user
  const handleSignup = async () => {
    if (termsAgree) {
      let response = undefined;
      response = await signup(email, password);
      if (!response?.code) {
        sendEmailVerification(response.user);
        const user = {
          contactNumber: contactNumber,
          email: email,
          name: `${firstName} ${lastName}`,
          password: password,
          dealershipName: dealership,
          address: selectedLocation?.desc,
          placeId: selectedLocation?.placeId,
          zipCode: "",
        };
        if (response !== undefined) {
          try {
            const dealerDocRef = uuidv4();
            await createDealer(user, dealerDocRef);
            dispatch(setUserId(dealerDocRef));
            dispatch(setUserType("dealer"));
            dispatch(setSessionEmail(""));
            setSuccessfullSignup(true);
            setAnythingMissing(false);
            setShowLoader(false);
            setFormData(user);
            showConfirmation(true);
          } catch (error) {
            console.log(
              "Something went wrong while doing dealerSignup: ",
              error
            );
            signupFailed();
          }
        }
      } else {
        setShowLoader(false);
        if (response.code === "auth/invalid-email") {
          invalidEmail();
        } else if (response.code === "auth/email-already-in-use") {
          emailInUse();
        } else if (response.code === "auth/weak-password") {
          weakPassword();
        } else {
          signupFailed();
        }
        console.log("error: ", response.code);
      }
    } else {
      incompleteAgree();
    }
  };

  const fetchLocations = async (e) => {
    if (e.length < 4) {
      setLocations(null);
      if (e.length === 0) setSelectedLocation(null);
      return;
    }

    const p = await placePrediction(e);
    setLocations(p);
  };

  const selectLocation = (e) => {
    if (!e) return;
    setSelectedLocation(e);
  };

  return (
    <div className="justify-center items-center my-20 sm:my-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center">
        {/* graphics on the left half */}
        <LoginGraphicsLeft
          text="Already have an account?"
          label="Login"
          path="/login/dealerForm"
        />

        {/* fields on the right half */}
        {!confirmationPage ? (
          <div className="flex flex-col justify-center items-center lg:h-screen lg:h-fit lg:-mt-8">
            {/* Logo */}
            <div className="my-8">
              <img src={Logo} alt="logo" />
            </div>
            {/* Headlines */}
            <span className="text-4xl font-bold text-center">
              Create an account
            </span>
            <span className="font-normal text-sm text-center sm:w-80 text-gray-400 mt-3">
              Enter your details to proceed further
            </span>

            {/* Email input text fields */}
            <div className="items-center justify-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-10 rounded-lg py-2 px-6">
              <div className="w-11/12">
                <span style={{ zIndex: "-1" }} className={emailPlaceholder}>
                  Email
                </span>
                <input
                  onBlur={() => {
                    setEmailFocused(false);
                    if (email.length >= 1) {
                      setEmailPlaceHolder(
                        "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    } else {
                      setEmailPlaceHolder(
                        "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                      );
                    }
                  }}
                  onFocus={() => {
                    setEmailFocused(true);
                    setEmailPlaceHolder(
                      "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="email"
                  title="Email"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              {/* email svg in the email field */}
              <div>
                <EmailSVG
                  fill={emailFocused || email.length > 0 ? "black" : "#9A9EA7"}
                />
              </div>
            </div>

            {/* Full name input field  */}
            <div className="flex w-11/12 sm:w-96 space-x-2">
              {/* First name input field  */}
              <div className="items-center flex w-1/2 sm:w-46 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-4">
                <div className="w-11/12">
                  <span
                    style={{ zIndex: "-1" }}
                    className={firstNamePlaceholder}
                  >
                    First name
                  </span>
                  <input
                    onBlur={() => {
                      setFnameFocused(false);
                      if (firstName.length >= 1) {
                        setfirstNamePlaceholder(
                          "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                        );
                      } else {
                        setfirstNamePlaceholder(
                          "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                        );
                      }
                    }}
                    onFocus={() => {
                      setFnameFocused(true);
                      setfirstNamePlaceholder(
                        "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    }}
                    style={{ background: "transparent" }}
                    className="mt-2 w-full text-sm font-bold text-black focus:outline-0"
                    type="text"
                    title="FirstName"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                {/* first-name svg in the name field */}
                <div>
                  <FirstNameSVG
                    fill={
                      fnameFocused || firstName.length > 0 ? "black" : "#9A9EA7"
                    }
                  />
                </div>
              </div>

              {/* Last name input field  */}
              <div className="items-center flex w-1/2 sm:w-46 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-4">
                <div className="w-11/12">
                  <span
                    style={{ zIndex: "-1" }}
                    className={lastNamePlaceholder}
                  >
                    Last name
                  </span>
                  <input
                    onBlur={() => {
                      setLnameFocused(false);
                      if (lastName.length >= 1) {
                        setlastNamePlaceholder(
                          "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                        );
                      } else {
                        setlastNamePlaceholder(
                          "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                        );
                      }
                    }}
                    onFocus={() => {
                      setLnameFocused(true);
                      setlastNamePlaceholder(
                        "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    }}
                    style={{ background: "transparent" }}
                    className="mt-2 w-full text-sm font-bold text-black focus:outline-0"
                    type="text"
                    title="LastName"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                {/* last-name svg in the name field */}
                <div>
                  <LastNameSVG
                    fill={
                      lnameFocused || lastName.length > 0 ? "black" : "#9A9EA7"
                    }
                  />
                </div>
              </div>
            </div>

            {/* Contact text input field  */}
            <div className="items-center justify-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-6">
              <div className="w-11/12">
                <span
                  style={{ zIndex: "-1" }}
                  className={contactNumberPlaceholder}
                >
                  Contact No
                </span>
                <input
                  onBlur={() => {
                    setContactFocused(false);
                    if (contactNumber.length >= 1) {
                      setcontactNumberPlaceholder(
                        "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    } else {
                      setcontactNumberPlaceholder(
                        "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                      );
                    }
                  }}
                  onFocus={() => {
                    setContactFocused(true);
                    setcontactNumberPlaceholder(
                      "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="text"
                  value={contactNumber}
                  onInput={(e) => {
                    const value = e.target.value;
                    let validatedValue = value.replace(/[^- +()0-9]/g, "");

                    if (
                      validatedValue.length === 1 &&
                      validatedValue[0] !== "+"
                    ) {
                      validatedValue = `+1 (${validatedValue}`;
                    }

                    if (
                      validatedValue.length === 8 &&
                      validatedValue[7] !== ")"
                    ) {
                      validatedValue = `${validatedValue.slice(
                        0,
                        7
                      )}) ${validatedValue.slice(7)}`;
                    }

                    if (
                      validatedValue.length === 13 &&
                      validatedValue[12] !== " "
                    ) {
                      validatedValue = `${validatedValue.slice(
                        0,
                        12
                      )} ${validatedValue.slice(12)}`;
                    }

                    if (validatedValue.length > 17) {
                      validatedValue = validatedValue.slice(0, 17);
                    }

                    e.target.value = validatedValue;
                  }}
                  title="ContactNumber"
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              {/* contact svg in the contact number field */}
              <div>
                <ContactSVG
                  fill={
                    contactFocused || contactNumber.length > 0
                      ? "black"
                      : "#9A9EA7"
                  }
                />
              </div>
            </div>

            {/* Password text input field  */}
            <div className="items-center justify-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-6">
              <div className="w-11/12">
                <span className={passwordPlaceholder} style={{ zIndex: "-1" }}>
                  Password
                </span>
                <input
                  onBlur={() => {
                    setPasswordFocused(false);
                    if (password.length >= 1) {
                      setPasswordPlaceHolder(
                        "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    } else {
                      setPasswordPlaceHolder(
                        "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                      );
                    }
                  }}
                  onFocus={() => {
                    setPasswordFocused(true);
                    setPasswordPlaceHolder(
                      "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type={togglePasswordDisplay}
                  onChange={(e) => {
                    if (e.target?.value?.length < 1) {
                      setShowLock(true);
                    } else {
                      setShowLock(false);
                    }
                    setPassword(e.target.value);
                  }}
                />
              </div>
              {showLock ? (
                <PasswordSVG
                  fill={
                    passwordFocused || password?.length > 0
                      ? "black"
                      : "#9A9EA7"
                  }
                />
              ) : (
                <div style={{ cursor: "pointer" }}>
                  {togglePasswordDisplay === "password" ? (
                    <HidePasswordSVG
                      onClick={() => setTogglePasswordDisplay("text")}
                    />
                  ) : (
                    <ShowPasswordSVG
                      onClick={() => setTogglePasswordDisplay("password")}
                    />
                  )}
                </div>
              )}
            </div>

            {/* Dealer name text input field  */}
            <div className="items-center justify-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-6">
              <div className="w-11/12">
                <span
                  style={{ zIndex: "-1" }}
                  className={dealershipPlaceholder}
                >
                  Dealership name
                </span>
                <input
                  onBlur={() => {
                    setDealerFocused(false);
                    if (dealership.length >= 1) {
                      setDealershipPlaceholder(
                        "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    } else {
                      setDealershipPlaceholder(
                        "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                      );
                    }
                  }}
                  onFocus={() => {
                    setDealerFocused(true);
                    setDealershipPlaceholder(
                      "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  }}
                  style={{ background: "transparent" }}
                  className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                  type="text"
                  title="dealershipName"
                  onChange={(e) => setDealership(e.target.value)}
                />
              </div>
              <DealerShipNameSVG
                fill={
                  dealerFocused || dealership?.length > 0 ? "black" : "#9A9EA7"
                }
              />
            </div>

            {/* Dealer address input field */}
            <Combobox value={selectedLocation} onChange={selectLocation}>
              <div className="items-center justify-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-6">
                <div className="w-11/12">
                  <Combobox.Label
                    className={addressPlaceholder}
                    style={{ zIndex: "-1" }}
                  >
                    Address
                  </Combobox.Label>
                  <Combobox.Input
                    onBlur={() => {
                      setAddressFocused(false);
                      if (address.length >= 1) {
                        setAddressPlaceholder(
                          "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                        );
                      } else {
                        setAddressPlaceholder(
                          "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                        );
                      }
                    }}
                    onFocus={() => {
                      setAddressFocused(true);
                      setAddressPlaceholder(
                        "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    }}
                    style={{ background: "transparent" }}
                    className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                    label="address"
                    onChange={(event) => {
                      setAddress(event.target.value);
                      fetchLocations(event.target.value);
                    }}
                    displayValue={(location) => location?.desc}
                  />
                </div>
                {/* location icon */}
                <AddressLocationSVG
                  fill={
                    addressFocused || address.length > 0 ? "black" : "#9A9EA7"
                  }
                />
              </div>
              {locations && (
                <Combobox.Options className="mt-1 max-h-60 w-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {locations.map((location, i) => (
                    <Combobox.Option
                      key={i}
                      className="select-none py-2 pl-10 pr-4"
                      value={location}
                    >
                      {location.desc}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </Combobox>

            {/* Error Message */}
            <div className="w-11/12 sm:w-96">
              <p style={{ color: "red", fontSize: "15px" }}>{errorMessage}</p>
            </div>

            {/* Remember me and Recover password interactions */}
            <div className="flex justify-between items-center w-11/12 sm:w-96 mt-5 mb-7">
              <div className="relative flex items-start">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 mt-0.5 focus:outline-0"
                  onChange={(e) => setTermsAgree(e.target.checked)}
                />
                <label
                  htmlFor="comments"
                  className="ml-3 text-sm font-bold text-gray-400"
                >
                  I agree with terms & conditions
                </label>
              </div>
            </div>

            {/* Signup button */}
            <button
              onClick={async () => {
                if (anythingMissing) {
                  missingFields();
                } else {
                  setShowLoader(true);
                  await handleSignup();
                  if (!successfullSignup) {
                    setShowLoader(false);
                  }
                }
              }}
              className="flex justify-center items-center w-11/12 sm:w-96 h-14 rounded-xl bg-blue-500 text-sm text-white font-bold"
            >
              <Loader showLoader={showLoader ? "" : "hidden"} />
              Sign Up
            </button>

            {/* Signup link */}
            <div className="flex justify-between w-11/12 sm:w-96 mt-6 mb-7 px-2 sm:px-0 lg:hidden">
              <span className="text-black text-base font-semibold mt-2">
                Already have an account?
              </span>
              <Link to="/login/dealerForm">
                <button className="text-blue-500 text-base font-semibold mt-2 underline">
                  Login
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <VerificationPage calledBy={"Dealer"} formData={formData} />
        )}
      </div>
    </div>
  );
};
