import { useEffect, useState } from "react";
import { getPostsBySellerRef } from "../services/firebase";
import moment from "moment";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { Link } from "react-router-dom";
import NoResult from "../assets/images/no-results.png";
import { Loader } from "../components/Loader";
import { ReactComponent as ViewSVG } from "../assets/SVG/View.svg";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "../components/SkeletonLoader";
import { Logout } from "../components/Logout";

export const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedAll, setSelectedAll] = useState(true);
  const [selectedArchived, setSelectedArchived] = useState(false);
  const [posts, setPosts] = useState("");
  const [allPosts, setAllPosts] = useState("");
  const [archivedPosts, setArchivedPosts] = useState("");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const userId = useSelector((state) => state.user.userId);
  const [currentPosts, setCurrentPosts] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [currentButton] = useState(
    "flex justify-center items-center w-12 h-12 bg-blue-500 rounded-lg  text-white font-bold"
  );
  const [pageButton] = useState(
    "flex justify-center items-center w-12 h-12 rounded-lg  text-dark font-bold"
  );

  // styling class for the tab selected by the user
  const selectedTab =
    "w-32 h-10 rounded-lg bg-blue-50 text-xs text-blue-500 font-bold";

  // styling class for the tab not selected by the user
  const unselectedTab =
    "w-32 h-10 rounded-lg bg-gray-100 text-xs text-gray-400 font-normal";

  // getting height of browser's internal window
  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  // fetching posts to be displayed to the user logged in and categorizing it afterwards
  useEffect(() => {
    const getPosts = async () => {
      let response = await getPostsBySellerRef(userId);

      setTimeout(() => {
        if (response === "") {
          setPosts(false);
        } else {
          response = response.sort(function (a, b) {
            return new Date(b.postedAt.seconds) - new Date(a.postedAt.seconds);
          });
          setPosts(response);
          setCurrentPosts(response.slice(0, 5));
          setAllPosts(response);
          setTotalPages(Math.ceil(response.length / 5));
          let archivedPosts = response?.filter(function (item) {
            return item.archived === true;
          });
          setArchivedPosts(archivedPosts);
        }
      }, 1000);
    };

    if (!posts || posts === "") getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  useEffect(() => {
    const setPagination = async () => {
      if (currentPage === 1) {
        setCurrentPosts(posts.slice(0, 5));
      } else if (currentPage === totalPages) {
        setCurrentPosts(posts.slice((currentPage - 1) * 5, posts.length));
      } else {
        setCurrentPosts(posts.slice((currentPage - 1) * 5, currentPage * 5));
      }
    };

    if (posts !== "") setPagination();
  }, [currentPage, posts, totalPages]);

  const pageChange = (page) => {
    setCurrentPage(page);
  };

  const pageButtons = (pages) => {
    let buttons = [];
    for (let i = 0; i < pages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            pageChange(i + 1);
          }}
          className={currentPage === i + 1 ? currentButton : pageButton}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div
      className={posts === "" || posts.length < 5 ? "flex h-screen" : "flex"}
    >
      {/* left navigation */}
      <NavigationPanel allCars={true} />

      {/* main section */}
      <div className="flex flex-col w-full px-6 sm:pl-12">
        <div className="flex mt-8 pb-4 border-b-2 border-gray-100">
          <div className="w-full mt-20 sm:mt-0">
            {/* page title */}
            <span className="text-3xl font-bold">All Cars</span>

            {/* 3 tabs on top */}
            <div className="flex space-x-2 my-8">
              {/* all posts tab */}
              <button
                className={selectedAll ? selectedTab : unselectedTab}
                onClick={() => {
                  setPosts(allPosts);
                  setCurrentPosts(posts.slice(0, 5));
                  setSelectedAll(true);
                  setSelectedArchived(false);
                }}
              >
                All
              </button>

              {/* archived posts tab */}
              <button
                className={selectedArchived ? selectedTab : unselectedTab}
                onClick={() => {
                  setPosts(archivedPosts);
                  if (archivedPosts.length > 5) {
                    setCurrentPosts(posts.slice(0, 5));
                  } else {
                    setCurrentPosts(archivedPosts);
                  }
                  setSelectedArchived(true);
                  setSelectedAll(false);
                }}
              >
                Archived
              </button>
            </div>

            {/* Car metadata headings */}
            <div className="hidden md:grid grid-cols-8 gap-4 mt-6 text-xs text-gray-400 font-bold">
              <span>
                {(currentPosts?.length || "0") +
                  " out of " +
                  (posts?.length || "0")}
              </span>
              <span>Make</span>
              <span>Year</span>
              <span className="col-span-2">VIN</span>
              <span>Total Offers</span>
              <span>Submitted On</span>
            </div>
          </div>
        </div>

        <Logout calledBy={"Seller"} />

        {/* showing loader while fetching car posts of this user */}
        {posts === "" ? (
          <SkeletonLoader total={5} />
        ) : (
          <>
            {/* showing car posts of this user if they exist */}
            {posts ? (
              <div className="overscroll-contain">
                {currentPosts.map((post, i) =>
                  windowWidth < 768 ? (
                    <Link
                      key={i}
                      to="/viewPost"
                      state={{ docRef: post?.docRef }}
                    >
                      <div
                        key={i}
                        className="mt-8 flex flex-col gap-4 text-sm font-bold items-center border border-gray-200 p-5 rounded-lg"
                      >
                        <img
                          key={Date.now()}
                          src={post?.coverPhoto}
                          alt="cover"
                          className="aspect-video rounded-lg"
                        />
                        <div className="w-full flex flex-col gap-2">
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Make
                            </span>
                            <span>{post?.vehicleDetails?.make}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Year
                            </span>
                            <span>{post?.vehicleDetails?.year}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              VIN
                            </span>
                            <span>{post?.VIN || "testVINtestVINtestVIN"} </span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1 border-b">
                            <span className="text-xs text-gray-400 font-bold">
                              Total Offers
                            </span>
                            <span>{post?.quotesReceived}</span>
                          </div>
                          <div className="flex flex-row justify-between items-center pt-1">
                            <span className="text-xs text-gray-400 font-bold">
                              Submitted On
                            </span>
                            <span>
                              {moment(
                                moment.unix(post?.postedAt?.seconds)
                              ).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div
                      key={i}
                      className="mt-8 grid grid-cols-8 gap-4 text-sm font-bold items-center"
                    >
                      <img
                        key={Date.now()}
                        src={post?.coverPhoto}
                        alt="cover"
                        className="aspect-video h-20 lg:h-24 xl:h-28 2xl:h-auto rounded-lg"
                      />
                      <span>{post?.vehicleDetails?.make}</span>
                      <span>{post?.vehicleDetails?.year}</span>
                      <span className="col-span-2">
                        {post?.VIN || "testVINtestVINtestVIN"}{" "}
                      </span>
                      <span>{post?.quotesReceived}</span>
                      <span>
                        {moment(moment.unix(post?.postedAt?.seconds)).format(
                          "MM/DD/YYYY"
                        )}
                      </span>

                      {/* button for viewing a post */}
                      <div className="absolute right-12">
                        <Link to="/viewPost" state={{ docRef: post?.docRef }}>
                          <ViewSVG />
                        </Link>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <>
                {posts?.length === 0 ? (
                  <>
                    {/* showing no results graphics, if this user doesn't have a car post */}
                    <div
                      className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
                      style={{
                        marginTop: `${windowHeight / 4}px`,
                      }}
                    >
                      <img className="w-44 " src={NoResult} alt="logo" />
                      <span className="text-4xl font-bold mt-8">
                        No Results Found
                      </span>
                      <span className="text-sm font-normal text-gray-400 mt-4">
                        Please try to search other vehicles
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
                    style={{
                      marginTop: `${windowHeight / 3}px`,
                    }}
                  >
                    <Loader showLoader={""} />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="flex items-stretch h-full self-end w-full py-14 gap-3">
          {pageButtons(totalPages)}
        </div>
        {/* footer */}
        <div className="flex items-stretch h-full -ml-4">
          <div className="self-end w-full">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
