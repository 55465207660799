import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { Image } from "../components/Image";
import { Footer } from "../components/Footer";
import Map from "../assets/SVG/Map.svg";
import AboutUsIcon from "../assets/images/AboutUsIcon.png";
import Brand1 from "../assets/images/brand-1.png";
import Brand2 from "../assets/images/brand-2.png";
import Brand3 from "../assets/images/brand-3.png";
import Brand4 from "../assets/images/brand-4.png";
import Brand5 from "../assets/images/brand-5.png";
import Brand6 from "../assets/images/brand-6.png";
import Brand7 from "../assets/images/brand-7.png";
import Brand8 from "../assets/images/brand-8.png";
import Brand9 from "../assets/images/brand-9.png";
import Brand10 from "../assets/images/brand-10.png";
import Brand11 from "../assets/images/brand-11.png";
import Brand12 from "../assets/images/brand-12.png";
import Brand13 from "../assets/images/brand-13.png";
import Brand14 from "../assets/images/brand-14.png";
import Brand15 from "../assets/images/brand-15.png";
import Brand16 from "../assets/images/brand-16.png";
import Brand17 from "../assets/images/brand-17.png";
import Brand18 from "../assets/images/brand-18.png";
import CivicHatchBack from "../assets/images/CivicHatchBack.png";
import BgImage from "../assets/SVG/BG.svg";
import TopSection from "../assets/SVG/TopSection.svg";
import FAuto from "../assets/SVG/FFAuto.svg";
import BPAuto from "../assets/SVG/BulletProofAuto.svg";
import PostCarDetails from "../assets/SVG/PostCarDetails.svg";
import ReceiveQoutations from "../assets/SVG/ReceiveQoutations.svg";
import SellYourCar from "../assets/SVG/SellYourCar.svg";
import { ReactComponent as PlusIconSVG } from "../assets/SVG/PlusIcon.svg";
import { ReactComponent as MinusIconSVG } from "../assets/SVG/MinusIcon.svg";
import { useSelector } from "react-redux";
// styling class for the tab selected by the user
const selectedTab =
  "rounded-t-lg border-b-4 border-transparent border-blue-500 hover:border-blue-500 dark:border-blue-500 p-4";

// styling class for the tab not selected by the user
const unselectedTab =
  "rounded-t-lg border-b-4 border-transparent unSelectedBorder hover:border-blue-500 dark:hover:text-blue-500 p-4";

const questions = [
  {
    question: "Is MyTopDollar free?",
    answer: "YES! Our platform is free for you to use. No fees at all.",
  },
  {
    question: "What is the process?",
    answer:
      "Input your vehicle information and post pictures. After doing so, your vehicle will be posted to our dealer dashboard then a bidding war begins!",
  },
  {
    question: "What happens after I see an offer I want to accept?",
    answer:
      "Accepting an offer is easy! The dealership details will be listed along with a contact person. From there the dealership will set up payment!",
  },
  {
    question: "How long does it take for bids to come in?",
    answer:
      "We send your vehicle to actually dealerships where they review it and put their bid. It typically will take 24-48 hours for all bids to come in. Instant quotes you see online from other dealerships will be computer generated and much more conservative!",
  },
  {
    question: "How many Photos should I post?",
    answer:
      "We encourage a minimum of 15-20 high resolution photos in order for dealerships to bid confidently.",
  },
];

export const HomePage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(3);
  const [expanded, setExpanded] = useState(5);
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);

  useEffect(() => {
    if (userType === "dealer") {
      navigate("/dealer/dashboard");
    } else if (userType === "seller") {
      navigate("/landingpage");
    }
  });

  const nextSellerPage =
    userType === "seller" && userId !== "" ? "/newPost" : "/signup";

  return (
    <div>
      {/* NavBar at the top */}
      <div className="flex justify-center">
        <NavBar />
      </div>

      <div className="grid grid-cols-12 mt-20 lg:mt-40">
        <div className="col-span-11 sm:col-span-12 flex flex-col sm:hidden">
          <Image className="ml-20 h-20" image={BPAuto} />
          <Image className="h-24" image={FAuto} />
          <Image className="mx-6 h-40" image={CivicHatchBack} />
        </div>

        <div className="hidden sm:flex col-span-12">
          <Image className="w-full top-bg mt-36" image={TopSection} />
        </div>

        {/* floating images section */}
        <div className="col-span-1"></div>

        <div className="col-span-10 sm:col-span-4 mx-4 sm:mx-0">
          <h2 className="mt-4 text-2xl font-bold text-blue-500">MyTopDollar</h2>
          <h1 className="mt-6 text-6xl font-bold">
            Hassle free <br /> Car Selling!
          </h1>
          <p className="mt-6 text-gray-400 mb-5">
            Don't waste your time visiting dealerships or receiving generic
            offer online. Receive personalized quotations for your car from the
            comfort of your home.
          </p>
          {/* redirect to newPost */}
          <Link to={nextSellerPage}>
            <button className="w-48 h-14 rounded-lg bg-button text-sm text-white font-bold mb-24">
              Sell my car
            </button>
          </Link>
        </div>
        <div className="hidden sm:flex col-span-6">
          <Image
            className="sm:ml-10 md:ml-24 lg:ml-64 2xl:ml-80 mt-4 top-bg"
            image={BPAuto}
          />
          <Image className="sm:-ml-5 md:ml-4 2xl:ml-28 mt-28" image={FAuto} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid grid-cols-12 mt-0 sm:mt-40 2xl:mt-80">
        {/* group tabs section */}
        <div className="col-span-1"></div>
        <div className="col-span-10 shadow-lg shadow-gray-200">
          <div id="scroller-wrapper">
            <div id="scroller">
              <div className="elem">
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 1 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(1)}
                >
                  <Image image={Brand1} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 2 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(2)}
                >
                  <Image image={Brand2} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 3 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(3)}
                >
                  <Image image={Brand3} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 4 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(4)}
                >
                  <Image image={Brand4} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 5 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(5)}
                >
                  <Image image={Brand5} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 6 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(6)}
                >
                  <Image image={Brand6} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 7 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(7)}
                >
                  <Image image={Brand7} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 8 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(8)}
                >
                  <Image image={Brand8} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 9 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(9)}
                >
                  <Image image={Brand9} />
                </button>
                <button
                  className={`col-span-1 px-8 pt-8 ${
                    selected === 10 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(10)}
                >
                  <Image image={Brand10} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 11 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(11)}
                >
                  <Image image={Brand11} />
                </button>
                <button
                  className={`col-span-1 px-8 pt-8 ${
                    selected === 12 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(12)}
                >
                  <Image image={Brand12} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 13 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(13)}
                >
                  <Image image={Brand13} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 14 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(14)}
                >
                  <Image image={Brand14} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 15 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(15)}
                >
                  <Image image={Brand15} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 16 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(16)}
                >
                  <Image image={Brand16} />
                </button>
                <button
                  className={`col-span-1 px-12 pt-8 ${
                    selected === 17 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(17)}
                >
                  <Image image={Brand17} />
                </button>
                <button
                  className={`col-span-1 pt-8 ${
                    selected === 18 ? selectedTab : unselectedTab
                  }`}
                  onClick={() => setSelected(18)}
                >
                  <Image image={Brand18} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid grid-cols-12 mt-32 text-center">
        {/* MTD workflow display */}
        <div className="col-span-12 mb-16">
          <center>
            <span className="font-bold text-4xl">How MyTopDollar works?</span>
          </center>
        </div>

        <div className="col-span-1"></div>
        <div className="col-span-2">
          <Image image={PostCarDetails} />
          <p className="font-bold text-2xl mt-4">Post Car Details</p>
          <p className="text-gray-500 mt-4">
            Post your car details which will be sent to all nearby car
            dealerships.
          </p>
        </div>
        <div className="col-span-2">
          <div className="text-gray-500 border-dashed border-b-2 mid"></div>
        </div>
        <div className="col-span-2">
          <Image image={ReceiveQoutations} />
          <p className="font-bold text-2xl mt-4">Receive Quotations</p>
          <p className="text-gray-500 mt-4">
            Receive personalized quotations for your car from dealerships near
            you.
          </p>
        </div>
        <div className="col-span-2">
          <div className="text-gray-500 border-dashed border-b-2 mid"></div>
        </div>
        <div className="col-span-2">
          <Image image={SellYourCar} />
          <p className="font-bold text-2xl mt-4">Sell your car</p>
          <p className="text-gray-500 mt-4">
            Contact the dealers you like and confidently sell your car.
          </p>
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid grid-cols-12 md:mt-32">
        <div className="col-span-12">
          {/* Bg image */}
          <Image
            className="questions-bg w-full mt-80 md:mt-0"
            image={BgImage}
          />
        </div>
        {/* Frequently asked questions */}
        <div className="col-span-1"></div>
        <div className="col-span-11 md:col-span-3 mt-72">
          <span className="font-bold text-4xl">
            Frequently<br></br>Asked Questions
          </span>
        </div>
        <div className="col-span-11 md:col-span-7 pt-12">
          {questions?.map((question, i) => {
            return (
              <div key={i} className="faqBox mb-3 py-6 pl-8 mx-4 md:mx-0">
                <div className="grid grid-cols-12">
                  <div className="col-span-11">
                    <p className="font-bold pt-2 text-lg queColor">
                      {question["question"]}
                    </p>
                  </div>

                  {/* expand/retract button on right */}
                  <div className="col-span-1" onClick={() => setOpen(!open)}>
                    <button
                      className="flex justify-center items-center w-10 h-10"
                      onClick={() => setExpanded(i)}
                    >
                      {expanded === i ? (
                        open ? (
                          <MinusIconSVG />
                        ) : (
                          <PlusIconSVG />
                        )
                      ) : (
                        <PlusIconSVG />
                      )}
                    </button>
                  </div>
                </div>

                {/* answer to display against each question */}
                {expanded === i && open && (
                  <div className="mt-2">
                    <p className="text-sm ansColor pr-32">
                      {question["answer"]}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="col-span-1"></div>
      </div>

      {/* About Us Section */}
      <div className="grid grid-cols-12 mt-40 py-20 bg-about w-full">
        <div className="col-span-1"></div>
        <div className="col-span-10 sm:col-span-5">
          <Image image={AboutUsIcon} />
        </div>
        <div className="col-span-10 sm:col-span-5 mt-14 pl-20">
          <h1 className="about-color font-bold text-2xl">About Us</h1>
          <p className="mt-5 about-text">
            MyTopDollar was started after going through the struggle of going to
            various dealerships to sell my car. I began dreading the process of
            selling my vehicles. From waiting hours at certain dealerships just
            to get a lowball offer or having to hear salesman try to convince
            you your vehicle is not worth as much as you think it is. Another
            issue is instant online offers. Those typically are conservative and
            leaves a lot of money on the table we can be getting for our
            vehicles.
            <br />
            <br /> This will be free to use. I created this with one vision in
            mind which was to help you get TOP DOLLAR!!
          </p>
        </div>
      </div>

      <div className="grid grid-cols-12 sm:mt-24">
        {/* MTD workflow display */}
        <div className="col-span-12 mb-6">
          <Image image={Map} />
        </div>
      </div>

      {/* footer */}
      <div className="sm:pl-40">
        <Footer />
      </div>
    </div>
  );
};
