import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";

export const Bids = () => {
  return (
    <div>
      {/* left navigation */}
      <NavigationPanel bids={true} />

      {/* main section */}
      <div>
        {/* page title */}
        <div className="flex mt-8 pb-4 pl-10">
          <div className="w-full">
            <span className="text-3xl font-bold">Bids</span>
          </div>
        </div>

        {/* footer */}
        <Footer />
      </div>
    </div>
  );
};
