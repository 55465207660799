import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { emailIsVerified, login } from "../../services/firebase";
import Logo from "../../assets/images/Logo.png";
import { ReactComponent as EmailSVG } from "../../assets/SVG/Email.svg";
import { ReactComponent as PasswordSVG } from "../../assets/SVG/Password.svg";
import { ReactComponent as HidePasswordSVG } from "../../assets/SVG/HidePassword.svg";
import { ReactComponent as ShowPasswordSVG } from "../../assets/SVG/ShowPassword.svg";
import { useDispatch, useSelector } from "react-redux";
import { setUserId, setUserType } from "../../redux/slices/userSlice";
import { LoginGraphicsLeft } from "../../components/LoginGraphics";
import { setNotificationView } from "../../redux/slices/userSlice";
import { setSessionEmail } from "../../redux/slices/userSlice";

export const SellerLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [successfullLogin, setSuccessfullLogin] = useState(false);
  const [showLock, setShowLock] = useState(true);
  const [togglePasswordDisplay, setTogglePasswordDisplay] =
    useState("password");
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  // Field Animations
  const [emailPlaceholder, setEmailPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [passwordPlaceholder, setPasswordPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if any field is left empty
  const missingFields = () => setErrorMessage("Email or Password in missing.");

  // validation alert if invalid email address is provided
  const invalidEmail = () => setErrorMessage("Invalid Email address provided");

  // validation alert if invalid password is provided
  const invalidPassword = () => setErrorMessage("Wrong password provided.");

  // validation alert if User is not found
  const userNotFound = () => setErrorMessage("User not found.");

  // updating if anything is missing or not, whenever user inputs something
  useEffect(() => {
    if (email?.length <= 1 || password?.length <= 1) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [email, password]);

  useEffect(() => {
    if (userId && emailIsVerified()) {
      if (userType === "seller") navigate("/landingPage");
      else if (userType === "dealer") navigate("/dealer/dashboard");
    }
  }, [navigate, userId, userType]);

  // process of signing in the user
  const handleSignin = async () => {
    let sellerDocRef = undefined;
    sellerDocRef = await login(email, password, rememberMe, "seller");
    if (sellerDocRef === undefined) {
      userNotFound();
    }
    if (!sellerDocRef?.code) {
      dispatch(setUserId(sellerDocRef));
      dispatch(setUserType("seller"));
      dispatch(setSessionEmail(email));
      dispatch(setNotificationView(false));
      setSuccessfullLogin(true);
      setAnythingMissing(false);
      setShowLoader(false);
      if (sellerDocRef !== undefined) {
        navigate("/landingPage");
      }
    } else {
      setShowLoader(false);
      setSuccessfullLogin(false);
      if (sellerDocRef.code === "auth/invalid-email") {
        invalidEmail();
      }
      if (sellerDocRef.code === "auth/wrong-password") {
        invalidPassword();
      }
      if (sellerDocRef.code === "auth/user-not-found") {
        userNotFound();
      }
      console.log("error: ", sellerDocRef.code);
    }
  };

  return (
    <div className="justify-center items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center">
        {/* graphics on the left half */}
        <LoginGraphicsLeft
          text="Don't have an account?"
          label="Sign up"
          path="/signup"
        />

        {/* fields on the right half */}
        <div className="flex flex-col justify-center items-center h-screen lg:h-fit lg:-mt-8">
          {/* Logo */}
          <div className="my-8">
            <img src={Logo} alt="logo" />
          </div>

          {/* Headlines */}
          <span className="text-3xl sm:text-4xl font-bold text-center">
            Login to your account
          </span>
          <span className="font-normal text-sm text-center sm:w-80 text-gray-400 mt-3">
            Enter your details to proceed further
          </span>

          {/* Email input text fields */}
          <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-10 rounded-lg py-2 px-4 justify-between">
            <div className="w-11/12">
              <span className={emailPlaceholder} style={{ zIndex: "-1" }}>
                Email
              </span>
              <input
                onBlur={() => {
                  setEmailFocused(false);
                  if (email.length >= 1) {
                    setEmailPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setEmailPlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setEmailFocused(true);
                  setEmailPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type="text"
                title="Email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            {/* email icon */}
            <div>
              <EmailSVG
                fill={emailFocused || email?.length > 0 ? "black" : "#9A9EA7"}
              />
            </div>
          </div>

          {/* Password text input field  */}
          <div className="items-center flex w-11/12 sm:w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-4 justify-between">
            <div className="w-11/12">
              <span className={passwordPlaceholder} style={{ zIndex: "-1" }}>
                Password
              </span>
              <input
                onBlur={() => {
                  setPasswordFocused(false);
                  if (password.length >= 1) {
                    setPasswordPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setPasswordPlaceHolder(
                      "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setPasswordFocused(true);
                  setPasswordPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type={togglePasswordDisplay}
                onChange={(e) => {
                  if (e.target?.value?.length < 1) {
                    setShowLock(true);
                  } else {
                    setShowLock(false);
                  }
                  setPassword(e.target.value);
                }}
              />
            </div>

            {showLock ? (
              <PasswordSVG
                fill={
                  passwordFocused || password?.length > 0 ? "black" : "#9A9EA7"
                }
              />
            ) : (
              <>
                {togglePasswordDisplay === "password" ? (
                  <HidePasswordSVG
                    onClick={() => setTogglePasswordDisplay("text")}
                  />
                ) : (
                  <ShowPasswordSVG
                    onClick={() => setTogglePasswordDisplay("password")}
                  />
                )}
              </>
            )}
          </div>

          {/* Error Message */}
          <div className="w-11/12 sm:w-96">
            <p style={{ color: "red", fontSize: "15px" }}>{errorMessage}</p>
          </div>

          {/* Remember me and Recover password interactions */}
          <div className="flex justify-between w-11/12 sm:w-96 mt-5 mb-7">
            <div className="relative flex items-start">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 mt-0.5 focus:outline-0"
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="comments" className="ml-3 text-sm font-bold">
                Remember me
              </label>
            </div>
            <Link to="/login/recoverPassword">
              <span className="text-sm font-semibold text-blue-500">
                Recover password
              </span>
            </Link>
          </div>

          {/* Login button */}
          <button
            onClick={async () => {
              if (anythingMissing) {
                missingFields();
              } else {
                setShowLoader(true);
                await handleSignin();
                if (!successfullLogin) {
                  setShowLoader(false);
                }
              }
            }}
            className="flex justify-center items-center w-11/12 sm:w-96 h-14 rounded-xl bg-blue-500 text-sm text-white font-bold"
          >
            <Loader showLoader={showLoader ? "" : "hidden"} />
            Login
          </button>

          {/* Signup link */}
          <div className="flex justify-between w-11/12 sm:w-96 mt-6 mb-7 px-2 sm:px-0 lg:hidden">
            <span className="text-black text-base font-semibold mt-2">
              Don't have an account?
            </span>
            <Link to="/signup">
              <button className="text-blue-500 text-base font-semibold mt-2 underline">
                Sign up
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
