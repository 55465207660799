import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { ActionList } from "../components/ActionList";
import { ReactComponent as ViewSVG } from "../assets/SVG/View.svg";
import {
  getPostBids,
  getPostByRef,
  getDealerByRef,
  getSellerByRef,
} from "../services/firebase";
import { Logout } from "../components/Logout";
import PLImage from "../assets/images/bg.png";
import AllImagesModal from "./DealerDashboard/Modals/AllImagesModal";
import { Loader } from "../components/Loader";
import { distanceMatrix } from "../common/maps";

export const ViewPost = () => {
  const [post, setPost] = useState("");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [postBids, setPostBids] = useState([]);
  const location = useLocation();
  const [cPhoto, setCPhoto] = useState("");
  const [imageList, setImageList] = useState([]);

  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  const featureDetails = (features, flag) => {
    let stringArray = [];
    if (features) {
      let keys = Object.keys(features);
      for (let x of keys) {
        if (features[x] === true || features[x] === "Yes") {
          stringArray.push(x.charAt(0).toUpperCase() + x.slice(1));
        }
      }
      stringArray = stringArray.join(", ");
    }

    if (flag && stringArray.length) {
      const nonDefectsArray = [
        "FrameDamage, ",
        ", FrameDamage",
        "SeatBurnsRipsTear, ",
        ", SeatBurnsRipsTear",
        "ReplaceWindSheild, ",
        ", ReplaceWindSheild",
        "SmokedIn, ",
        ", SmokedIn",
      ];

      nonDefectsArray.forEach(getDefectsOnly);
      function getDefectsOnly(item) {
        stringArray = stringArray.replace(item, "");
      }
    }

    return stringArray.length ? stringArray : "N/A";
  };

  useEffect(() => {
    if (location?.state?.docRef) {
      const fetchPost = async () => {
        const response = await getPostByRef(location.state.docRef);
        response.docRef = location.state.docRef;
        setTimeout(() => {
          setPost(() => response);
          setImageList(Object.values(response.vehicleDetails.carImageURLs));
          setCPhoto(() => response?.coverPhoto);
        }, 1500);
      };

      if (post === "") fetchPost();
    }
  }, [post, location, cPhoto]);

  useEffect(() => {
    const getBids = async () => {
      let bids = await getPostBids(post?.docRef);

      const newBids = await Promise.all(
        bids.map(async (bid) => {
          const dealer = await getDealerByRef(bid.dealer);
          const seller = await getSellerByRef(bid.seller);

          bid.milesAway = await getDistance(dealer, seller);
          return bid;
        })
      );

      setPostBids(newBids);
    };

    if (post) getBids();
  }, [post]);

  const getDistance = async (dealer, seller) => {
    const distance = await distanceMatrix(seller?.placeId, [
      { placeId: dealer?.placeId },
    ]);

    const milesAway = Math.ceil(distance[0]);

    return milesAway;
  };

  return (
    <div className={post === "" ? "flex h-screen" : "flex"}>
      {/* left navigation */}
      <NavigationPanel allCars={true} />

      {/* main section */}
      <div className="w-full flex flex-col px-6 sm:pl-12 sm:pr-10">
        {/* page title */}
        <div className="flex mt-8 pb-4">
          <div className="w-full mt-20 sm:mt-0">
            <span className="text-3xl font-bold">All Cars / View Post</span>
          </div>
        </div>

        <Logout calledBy={"Seller"} />
        {/* car images section */}
        <div className="flex flex-col h-full justify-between">
          {post === "" ? (
            <div
              className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
              style={{
                marginTop: `${windowHeight / 3}px`,
              }}
            >
              <Loader showLoader={""} />
            </div>
          ) : (
            <>
              {post && (
                <>
                  <div className="mt-8 py-4 px-6 border-2 border-gray-100 rounded-xl">
                    <div className="grid grid-cols-12 items-center mb-5 gap-2">
                      <img
                        src={post?.coverPhoto}
                        alt="cover"
                        className="w-full col-span-12 sm:col-span-4 border-0 rounded-lg h-full mr-2 bg-no-repeat bg-cover"
                      />

                      <div className="grid grid-cols-4 sm:grid-cols-8 col-span-12 sm:col-span-8 gap-2 ">
                        {imageList?.map((imageURL, i) => (
                          <>
                            {imageURL !== post?.coverPhoto && i < 7 && (
                              <img
                                key={i}
                                src={imageURL || PLImage}
                                alt="cover"
                                className="w-full col-span-2 border-0 h-full rounded-lg bg-no-repeat bg-cover"
                              />
                            )}
                          </>
                        ))}
                        <div className="col-span-4 sm:col-span-2">
                          <AllImagesModal images={imageList} />
                        </div>
                      </div>
                    </div>

                    {/* Car intro and edit button below car images */}
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col">
                        <span className="text-lg font-bold">
                          {post?.vehicleDetails?.["year"] +
                            " " +
                            post?.vehicleDetails?.["make"] +
                            " " +
                            post?.vehicleDetails?.["model"]}
                        </span>
                        <p className="text-sm font-normal">
                          Mileage:&nbsp; {post?.vehicleDetails?.["mileage"]}
                          <span
                            style={{ fontSize: "35px", fontWeight: "10px" }}
                          >
                            &nbsp;.{" "}
                          </span>
                          {post?.plateNumber || post?.VIN}
                        </p>
                      </div>

                      {/* car post actions actions */}
                      <ActionList post={post} setPost={setPost} />
                    </div>

                    {/* in-depth details of car */}
                    <div className="grid grid-rows mt-6">
                      {/* row 1 */}
                      <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                        {/* features column on left */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Features
                          </span>
                          <span className="float-right text-sm font-bold">
                            {featureDetails(
                              post?.vehicleDetails?.["features"],
                              false
                            )}
                          </span>
                        </div>

                        {/* visible defects column on right */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Visible Defects
                          </span>
                          <span className="float-right text-sm font-bold">
                            {featureDetails(
                              post?.exteriorInteriorCondition,
                              true
                            )}
                          </span>
                        </div>
                      </div>

                      {/* row 2 */}
                      <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                        {/* windsheild damage column on left */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Windshield replacement
                          </span>
                          <span className="float-right text-sm font-bold">
                            {
                              post?.exteriorInteriorCondition?.[
                                "replaceWindSheild"
                              ]
                            }
                          </span>
                        </div>

                        {/* frame damage column on right */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Frame Damage
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.exteriorInteriorCondition?.["frameDamage"]}
                          </span>
                        </div>
                      </div>

                      {/* row 3 */}
                      <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                        {/* burns or stains column on left */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Burns or stains
                          </span>
                          <span className="float-right text-sm font-bold">
                            {
                              post?.exteriorInteriorCondition?.[
                                "seatBurnsRipsTear"
                              ]
                            }
                          </span>
                        </div>

                        {/* vehicle smoked column on right */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Vehicle been smoked in?
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.exteriorInteriorCondition?.["smokedIn"]}
                          </span>
                        </div>
                      </div>

                      {/* row 4 */}
                      <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                        {/* transmission type column on left */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Transmission type
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.vehicleDetails?.["transmission"]}
                          </span>
                        </div>

                        {/* drive type column on left */}
                        <div className="border-b-2 border-gray-100 pb-2">
                          <span className="float-left text-sm font-normal">
                            Drive type
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.vehicleDetails?.["drive"]}
                          </span>
                        </div>
                      </div>

                      {/* row 5 */}
                      <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                        {/* dashboard warnings column on left */}
                        <div className="border-b-2 border-gray-100 xl:border-0 pb-2">
                          <span className="float-left text-sm font-normal">
                            Dashboard Warning
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.mechanicalCondition?.["warningLights"]}
                          </span>
                        </div>

                        {/* odomenter column on right */}
                        <div className="pb-2">
                          <span className="float-left text-sm font-normal">
                            Odometer replaced or modified?
                          </span>
                          <span className="float-right text-sm font-bold">
                            {post?.mechanicalCondition?.["odometerReplace"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Received bids heading */}
                  <div className="flex mt-8 pb-4">
                    <div className="w-full">
                      <span className="text-2xl font-bold">Received Bids</span>
                    </div>
                  </div>

                  {/* Received bids breakdown */}
                  <div className="mt-4 py-4 px-6 mb-40 border-2 border-gray-100 rounded-xl">
                    {/* meta data of received bids */}
                    <div className="hidden md:grid grid-cols-11 gap-4 py-6 border-b-2 borday-gray-100">
                      <span className="col-span-2 text-xs font-bold text-gray-400">
                        Buyer Name
                      </span>
                      <span className="col-span-3 text-xs font-bold text-gray-400">
                        Buyer Email
                      </span>
                      <span className="col-span-2 text-xs font-bold text-gray-400">
                        Offered on
                      </span>
                      <span className="col-span-2 text-xs font-bold text-gray-400">
                        Price offered
                      </span>
                      <span className="col-span-2 text-xs font-bold text-gray-400">
                        Miles away
                      </span>
                    </div>

                    <div className="grid grid-rows mt-2">
                      {postBids?.map((bid, j) => {
                        return windowWidth < 768 ? (
                          <div
                            key={j}
                            className="w-full flex flex-col gap-2 mt-2"
                          >
                            <div className="flex flex-row justify-between items-center py-1 border-b">
                              <span className="text-xs text-gray-400 font-bold">
                                Buyer Name
                              </span>
                              <span> {bid?.dealerName}</span>
                            </div>
                            <div className="flex flex-row justify-between items-center py-1 border-b">
                              <span className="text-xs text-gray-400 font-bold">
                                Buyer Email
                              </span>
                              <span>{bid?.dealerEmail}</span>
                            </div>
                            <div className="flex flex-row justify-between items-center py-1 border-b">
                              <span className="text-xs text-gray-400 font-bold">
                                Offered On
                              </span>
                              <span>
                                {new Date(
                                  bid?.offeredOn?.seconds * 1000
                                ).toLocaleDateString("en-US")}
                              </span>
                            </div>
                            <div className="flex flex-row justify-between items-center py-1 border-b">
                              <span className="text-xs text-gray-400 font-bold">
                                Price Offered
                              </span>
                              <span>{bid?.amount}</span>
                            </div>
                            <div className="flex flex-row justify-between items-center py-1 border-b">
                              <span className="text-xs text-gray-400 font-bold">
                                Miles Away
                              </span>
                              <span>{bid?.milesAway}</span>
                            </div>
                            <div className="flex flex-row justify-between items-center pt-1">
                              <span className="text-xs text-gray-400 font-bold">
                                Buyer Details
                              </span>
                              <button>
                                <Link to="/buyerDetails" state={{ bid }}>
                                  <ViewSVG />
                                </Link>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="py-4 text-sm font-bold grid grid-cols-11 gap-4 mt-3"
                            key={j}
                          >
                            <p className="col-span-2">{bid?.dealerName}</p>
                            <p className="col-span-3">{bid?.dealerEmail}</p>
                            <p className="col-span-2">
                              {new Date(
                                bid?.offeredOn?.seconds * 1000
                              ).toLocaleDateString("en-US")}
                            </p>
                            <p className="col-span-2">{bid?.amount}</p>
                            <p>{bid?.milesAway}</p>
                            <div className="flex -mt-2 absolute right-12">
                              <button>
                                <Link to="/buyerDetails" state={{ bid }}>
                                  <ViewSVG />
                                </Link>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="flex items-stretch mt-10 -ml-9">
            <div className="self-end w-full">
              {/* footer */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
