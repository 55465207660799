import BgImage from "../assets/images/bg.png";

// appears on the multi-steps form where theres an image of the car and summary of its specs
export const QuickViewBanner = ({ formData }) => {
  const carImage = formData.coverPhoto;
  const imageClass = carImage === "" ? "" : "xl:text-left";

  return (
    <div
      // blue background image
      className={`flex flex-row bg-no-repeat bg-cover w-full h-72 sm:h-64 text-center items-center justify-center ${imageClass}`}
      style={{
        backgroundImage: `url(${BgImage})`,
      }}
    >
      {/* actual car image on left */}
      {carImage !== "" && (
        <img
          className="h-0 xl:h-64 -ml-8 mr-4 bg-white"
          src={carImage}
          alt="carImage"
        />
      )}

      {/* textual details/specs summary on the right */}
      <div className="p-3 space-y-6">
        {/* make */}
        <div className="">
          <span className="text-white text-sm font-bold">{formData?.make}</span>
        </div>

        <div className="flex flex-col space-y-2">
          {/* model */}
          <span className="text-white text-lg font-bold">
            {formData?.year} {formData?.make} {formData?.model}{" "}
          </span>
          {/* VIN */}
          <span className="mt-6 text-white text-xs font-bold">
            {formData?.VIN}
          </span>
        </div>

        <div className="grid grid-cols-2 -mr-24 sm:mr-0 sm:flex text-left">
          {/* body type */}
          <div className="flex flex-row">
            <div className="flex flex-col mt-4">
              <span className="text-white text-sm font-bold">
                {formData?.body}
              </span>
              <span className="text-white text-xs mt-2">Body</span>
            </div>
            <div className="mt-4">
              <span className="text-white text-4xl font-thin mx-6 xl:mx-12">
                /
              </span>
            </div>
          </div>

          {/* vehicle type */}
          <div className="flex flex-row">
            <div className="flex flex-col mt-4">
              <span className="text-white text-sm font-bold">
                {formData?.vehicleType}
              </span>
              <span className="text-white text-xs mt-2">Vehicle Type</span>
            </div>
            <div className="hidden sm:flex mt-4">
              <span className="text-white text-4xl font-thin mx-6 xl:mx-12">
                /
              </span>
            </div>
          </div>

          {/* cylinders */}
          <div className="flex flex-row">
            <div className="flex flex-col mt-4">
              <span className="text-white text-sm font-bold">
                {formData?.engineCylinders}
              </span>
              <span className="text-white text-xs mt-2">Engine</span>
            </div>
            <div className="mt-4">
              <span className="text-white text-4xl font-thin mx-6 xl:mx-12">
                /
              </span>
            </div>
          </div>

          {/* fuel type */}
          <div className="flex flex-row">
            <div className="flex flex-col mt-4">
              <span className="w-full text-white text-sm font-bold">
                {formData?.fuel}
              </span>
              <span className="w-full text-white text-xs mt-2">Fuel Type</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
