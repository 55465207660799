import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { NavigationPanel } from "../components/NavigationPanel";
import { Loader } from "../components/Loader";
import { Logout } from "../components/Logout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getDealerByRef } from "../services/firebase";

export const BuyerDetails = () => {
  const [bid, setBid] = useState("");
  const [dealership, setDealership] = useState("");
  const location = useLocation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  window.addEventListener("resize", function () {
    setWindowHeight(window.innerHeight);
  });

  useEffect(() => {
    if (bid === "") setBid(location.state.bid);
  }, [bid, location]);

  useEffect(() => {
    const getDealer = async () => {
      const dealer = await getDealerByRef(bid.dealer);
      setDealership(dealer.dealershipName);
    };
    if (bid !== "") getDealer();
  }, [bid]);

  return (
    <>
      {bid === "" ? (
        <div
          className={`flex flex-col justify-center items-center h-[${window.outerHeight}px]`}
          style={{
            marginTop: `${windowHeight / 3}px`,
          }}
        >
          <Loader showLoader={""} />
        </div>
      ) : (
        <>
          <div className="flex h-screen">
            {/* left navigation */}
            <NavigationPanel bids={true} />

            {/* main section */}
            <div className="flex flex-col w-full sm:pl-5">
              {/* page title */}
              <div className="flex flex-col mt-8 px-6">
                <div className="w-full mt-20 sm:mt-0">
                  <span className="text-3xl font-bold">
                    Bids Received / Buyer Details
                  </span>
                </div>
              </div>

              <Logout calledBy={"Seller"} />

              <div className="mr-8 mt-4 py-4 px-6">
                {/* price offered section */}
                <div className="w-full border-0 rounded-xl mb-5 bgImage py-14 pl-10">
                  <p className="text-white font-bold text-2xl">
                    Price Offered: ${bid?.amount}
                  </p>
                </div>

                {/* buyer details heading */}
                <div className="flex mt-8">
                  <div className="w-full">
                    <span className="text-3xl font-bold">Buyer Details</span>
                  </div>
                </div>

                {/* in-depth details of car */}
                <div className="grid grid-rows mt-6">
                  {/* row 1 */}
                  <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                    {/* buyer's name column on left */}
                    <div className="border-b-2 border-gray-100 pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Buyer's Name
                      </p>
                      <p className="pt-2 text-m font-bold">{bid?.dealerName}</p>
                    </div>

                    {/* buyer's contact column on right */}
                    <div className="border-b-2 border-gray-100 pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Buyer's Contact
                      </p>
                      <p className="pt-2 text-m font-bold">
                        {bid?.dealerContact}
                      </p>
                    </div>
                  </div>

                  {/* row 2 */}
                  <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                    {/* buyer's email column on left */}
                    <div className="border-b-2 border-gray-100 pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Buyer's Email
                      </p>
                      <p className="pt-2 text-m font-bold">
                        {bid?.dealerEmail}
                      </p>
                    </div>

                    {/* buyer's address column on right */}
                    <div className="border-b-2 border-gray-100 pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Buyer's Address
                      </p>
                      <p className="pt-2 text-m font-bold">
                        {bid?.dealerAddress}
                      </p>
                    </div>
                  </div>

                  {/* row 3 */}
                  <div className="grid grid-rows xl:grid-cols-2 gap-6 xl:gap-10 mt-6">
                    {/* dealership name column on left */}
                    <div className="border-b-2 border-gray-100 pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Dealership Name
                      </p>
                      <p className="pt-2 text-m font-bold">{dealership}</p>
                    </div>
                  </div>

                  {/* row 4 */}
                  <div className="grid mt-6">
                    {/* buyer's message */}
                    <div className="pb-2">
                      <p className="text-sm text-gray-400 font-normal">
                        Buyer's Message
                      </p>
                      <ReactQuill
                        value={bid?.message}
                        readOnly={true}
                        theme={"bubble"}
                        className="font-bold"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className="flex items-stretch h-full -ml-4">
                <div className="self-end w-full">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
