import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import comSlice, { initComState } from "./slices/comSlice";
import mainSlice, { initMainState } from "./slices/mainSlice";
import userSlice, { initUserState } from "./slices/userSlice";
import postSlice, { initPostState } from "./slices/postSlice";

const persistConfig = {
  key: "root",
  storage,
  migrate: (state) => {
    const { _persist = {} } = state || {};
    const com = { ...initComState, ...(state?.com || {}) };
    const main = { ...initMainState, ...(state?.main || {}) };
    const user = { ...initUserState, ...(state?.user || {}) };
    const post = { ...initPostState, ...(state?.post || {}) };
    return Promise.resolve({ _persist, com, main, user, post });
  },
};

const rootReducer = combineReducers({
  main: mainSlice,
  com: comSlice,
  user: userSlice,
  post: postSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const generateMiddleware = (def) => {
  return [...def({ serializableCheck: false })];
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: generateMiddleware,
});

export const persistor = persistStore(store);
