import { createSlice } from "@reduxjs/toolkit";

export const initComState = {
  carDetails: {
    // Car info (page 0) states
    plateNumber: "",
    registrationPlace: "",
    VIN: "",
    zipCode: "",
    coverPhoto: "",
    // Body details (page 1) states
    carMileage: "",
    carStyle: "4 Door",
    driveType: "All wheel drive",
    transmissionType: "Manual",
    bluetooth: false,
    navigation: false,
    speakers: false,
    AC: false,
    childLock: false,
    ABS: false,
    backupCamera: false,
    wingMirrors: false,
    carAccidentState: "No",
    floodDamageState: "No",
    frameDamageState: "No",
    dents: false,
    scuffs: false,
    scratches: false,
    rust: false,
    chippedPaint: false,
    hailDamage: false,
    dings: false,
    bulges: false,
    windsheildState: "No",
    seatBurnsState: "No",
    smokedState: "No",
    // Generic details (page 2) states
    expectedPrice: "",
    drive: "Yes",
    warningLights: "No",
    ifWarningLights: "",
    odometerReplaced: "No",
    repairsNeeded: "No",
    ifRepairsNeeded: "",
    milesOnTyres: "<5,000 miles",
    keys: "1",
    aftermarksParts: "No",
    newVehicle: "Yes",
    // Upload photos (page 3) states
    carImages: [],
  },
  personalDetails: {
    // Personal details (page 4) states
    name: "",
    contactNumber: "",
    email: "",
    password: "",
  },
};

const comSlice = createSlice({
  name: "com",
  initialState: initComState,
  reducers: {
    setCarDetails: (state, action) => {
      state.carDetails = action.payload;
    },
    setPersonalDetails: (state, action) => {
      state.personalDetails = action.payload;
    },
    resetCarDetails: (state) => {
      state.carDetails = initComState.carDetails;
      state.personalDetails = initComState.personalDetails;
    },
  },
});

export const { setCarDetails, setPersonalDetails, resetCarDetails } =
  comSlice.actions;

export default comSlice.reducer;
