import { TopBar } from "../TopBar";
import { QuickViewBanner } from "../QuickViewBanner";
import { useEffect, useState } from "react";
import { Asterisk } from "../Asterisk";
import { ReactComponent as ProgressSVG } from "../../assets/SVG/Progress1of3.svg";
import { FormSteps } from "../FormSteps";
import Button from "../Button";
import { NumericFormat } from "react-number-format";

const carStyle = [
  { id: "4door", title: "4 Door" },
  { id: "1.8L", title: "1.8L" },
];

const typeOfDrive = [
  { id: "awd", title: "All wheel drive" },
  { id: "fwd", title: "Front wheel drive" },
  { id: "rwd", title: "Rear wheel drive" },
  { id: "4wd", title: "4 wheel drive" },
];

const typeOfTransmission = [
  { id: "manual", title: "Manual" },
  { id: "automatic", title: "Automatic" },
];

const carAccident = [
  { id: "q1yes", title: "Yes" },
  { id: "q1no", title: "No" },
  { id: "q1maybe", title: "Maybe" },
];

const floodDamage = [
  { id: "q2yes", title: "Yes" },
  { id: "q2no", title: "No" },
  { id: "q2maybe", title: "Maybe" },
];

const frameDamage = [
  { id: "q3yes", title: "Yes" },
  { id: "q3no", title: "No" },
  { id: "q3maybe", title: "Maybe" },
];

const windsheild = [
  { id: "q4yes", title: "Yes" },
  { id: "q4no", title: "No" },
  { id: "q4maybe", title: "Maybe" },
];

const seatBurns = [
  { id: "q5yes", title: "Yes" },
  { id: "q5no", title: "No" },
  { id: "q5maybe", title: "Maybe" },
];

const smoked = [
  { id: "q6yes", title: "Yes" },
  { id: "q6no", title: "No" },
  { id: "q6maybe", title: "Maybe" },
];

export const VehicleDetailsPage = ({
  calledFrom,
  formData,
  setFormData,
  stateChanger,
  ...rest
}) => {
  const [anythingMissing, setAnythingMissing] = useState(true);

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // Field Animations
  const [mileagePlaceholder, setMileagePlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // validation alert if required field is left empty
  const missingFields = () =>
    setErrorMessage("Please enter mileage of your car");

  // scrolling to the top
  useEffect(() => {
    if (errorMessage === "Please enter mileage of your car") {
      document.documentElement.click();
      window.scrollTo(0, 0);
    }
  }, [errorMessage]);

  // updating if anything is missing or not, whenever user inputs something
  useEffect(() => {
    if (formData.carMileage?.length <= 1) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [formData.carMileage]);

  return (
    <div>
      {/* Logo and Cancel button */}
      <TopBar calledFrom={calledFrom} />

      <div className="mt-32 md:mt-40 flex flex-col justify-center items-center h-fit">
        {/* Headlines */}
        <span className="text-4xl font-bold w-96 text-center">
          Enter your car details
        </span>
        <span className="font-normal text-sm text-center text-gray-400 mt-6 mb-10">
          Kindly fill your car details below
        </span>

        {/* Quick view banner */}
        <QuickViewBanner formData={formData} />

        {/* Multi-steps indicator 'graphics' */}
        <ProgressSVG />

        {/* Multi-steps indicator 'texts' */}
        <FormSteps />

        <div className="w-11/12 sm:w-3/5 mt-14">
          <div className="items-center flex h-20 border-2 border-gray-100 mt-2 rounded-lg p-4">
            {/* car mileage number field */}
            <div className="w-full">
              <span className={mileagePlaceholder} style={{ zIndex: "-1" }}>
                Enter Mileage of your car (miles)
              </span>
              <NumericFormat
                onBlur={() => {
                  if (formData?.carMileage?.length >= 1) {
                    setMileagePlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setMileagePlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setMileagePlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 text-sm w-full font-bold text-gray-500 focus:outline-0"
                value={formData?.carMileage.toLocaleString()}
                title="mileage"
                thousandSeparator=","
                onChange={(e) => {
                  setErrorMessage("");
                  setFormData({
                    ...formData,
                    carMileage: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>

        {/* Error Message */}
        <div className="w-11/12 sm:w-3/5">
          <p style={{ color: "red", fontSize: "15px" }}>{errorMessage}</p>
        </div>

        {/* car style radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Please select the style of car
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Style of car</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {carStyle.map((carStyle) => (
                <div key={carStyle.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={carStyle.id}
                      name="carStyle"
                      type="radio"
                      defaultChecked={carStyle.id === "4door"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          carStyle: carStyle.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={carStyle.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {carStyle.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Drive type radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Please select the type of drive
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Type of drive</legend>
            <div className="space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
              {typeOfDrive.map((driveType) => (
                <div key={driveType.id} className="flex items-center lg:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={driveType.id}
                      name="typeOfDrive"
                      type="radio"
                      defaultChecked={driveType.id === "awd"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          driveType: driveType.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={driveType.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {driveType.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Transmission type radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Please select the transmission type of your car
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Type of transmission</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {typeOfTransmission.map((transmissionType) => (
                <div
                  key={transmissionType.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={transmissionType.id}
                      name="typeOfTransmission"
                      type="radio"
                      defaultChecked={transmissionType.id === "manual"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          transmissionType: transmissionType.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={transmissionType.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {transmissionType.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Car features check boxes */}
        <div className="text-sm font-normal text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <fieldset>
            <p>
              Please select the features of your car
              <Asterisk />
            </p>

            <div className="flex lg:flex-col">
              {/* first row of checkboxes */}
              <div className="mr-24 lg:mr-0 mt-4 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {/* bluetooth checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="bluetooth"
                        aria-describedby="bluetooth-description"
                        name="bluetooth"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            bluetooth: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="bluetooth"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Bluetooth
                      </span>
                    </label>
                  </div>
                </div>

                {/* navigation checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="navigation"
                        aria-describedby="navigation-description"
                        name="navigation"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            navigation: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="navigation"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Navigation
                      </span>
                    </label>
                  </div>
                </div>

                {/* speakers checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="speakers"
                        aria-describedby="speakers-description"
                        name="speakers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            speakers: e.target.checked,
                          });
                        }}
                        checked={formData.speakers}
                      />
                      <span
                        htmlFor="speakers"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Speakers
                      </span>
                    </label>
                  </div>
                </div>

                {/* AC checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="ac"
                        aria-describedby="ac-description"
                        name="ac"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            AC: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="ac"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        AC
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {/* second row of check boxes */}
              <div className="mt-4 lg:mt-3 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {/* child lock checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="childLock"
                        aria-describedby="childLock-description"
                        name="childLock"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            childLock: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="childLock"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Child Lock
                      </span>
                    </label>
                  </div>
                </div>

                {/* ABS checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="abs"
                        aria-describedby="abs-description"
                        name="abs"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ABS: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="abs"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Anti Lock Braking
                      </span>
                    </label>
                  </div>
                </div>

                {/* backup camera checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="backupCamera"
                        aria-describedby="backupCamera-description"
                        name="backupCamera"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      />
                      <span
                        htmlFor="backupCamera"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Backup Camera
                      </span>
                    </label>
                  </div>
                </div>

                {/* wing mirrors checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="wingMirrors"
                        aria-describedby="wingMirrors-description"
                        name="wingMirrors"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            wingMirrors: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="wingMirrors"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Wing Mirrors
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        {/* car accident question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Has the car met with any accident?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">car accident</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {carAccident.map((carAccident) => (
                <div
                  key={carAccident.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={carAccident.id}
                      name="carAccident"
                      type="radio"
                      defaultChecked={carAccident.id === "q1no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          carAccidentState: carAccident.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={carAccident.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {carAccident.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* flood damage question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Is there any flood damage?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">flood damage</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {floodDamage.map((floodDamage) => (
                <div
                  key={floodDamage.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={floodDamage.id}
                      name="floodDamage"
                      type="radio"
                      defaultChecked={floodDamage.id === "q2no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          floodDamageState: floodDamage.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={floodDamage.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {floodDamage.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* frame damage question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Is there any frame damage?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">frame damage</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {frameDamage.map((frameDamage) => (
                <div
                  key={frameDamage.id}
                  className="flex items-center sm:w-1/4"
                >
                  <label className="flex items-center">
                    <input
                      id={frameDamage.id}
                      name="frameDamage"
                      type="radio"
                      defaultChecked={frameDamage.id === "q3no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          frameDamageState: frameDamage.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={frameDamage.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {frameDamage.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Car defects check boxes */}
        <div className="text-sm font normal text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <fieldset>
            <legend className="sr-only">Notifications</legend>
            <p>
              Are there visible defects on the exterior? If yes, please select
              if applicable?
            </p>

            <div className="flex lg:flex-col">
              {/* first row of check boxes */}
              <div className="mr-24 lg:mr-0 mt-4 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {/* dents checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="dents"
                        aria-describedby="dents-description"
                        name="dents"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            dents: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="dents"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Dents
                      </span>
                    </label>
                  </div>
                </div>

                {/* scuffs checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="scuffs"
                        aria-describedby="scuffs-description"
                        name="scuffs"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            scuffs: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="scuffs"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Scuffs
                      </span>
                    </label>
                  </div>
                </div>

                {/* scratches checkbox */}
                <div className="flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="scratches"
                        aria-describedby="scratches-description"
                        name="scratches"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            scratches: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="scratches"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Scratches
                      </span>
                    </label>
                  </div>
                </div>

                {/* rust checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="rust"
                        aria-describedby="rust-description"
                        name="rust"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            rust: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="rust"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Rust
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {/* second row of check boxes */}
              <div className="mt-4 lg:mt-3 lg:space-x-8 space-y-4 lg:flex lg:items-center lg:space-y-0 lg:space-x-10">
                {/* chipped paint checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="chippedPaint"
                        aria-describedby="chippedPaint-description"
                        name="chippedPaint"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            chippedPaint: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="chippedPaint"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Chipped Paint
                      </span>
                    </label>
                  </div>
                </div>

                {/* hail damage checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="hailDamage"
                        aria-describedby="hailDamage-description"
                        name="hailDamage"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hailDamage: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="hailDamage"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Hail Damage
                      </span>
                    </label>
                  </div>
                </div>

                {/* dings checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <label className="flex items-center">
                      <input
                        id="dings"
                        aria-describedby="dings-description"
                        name="dings"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            dings: e.target.checked,
                          });
                        }}
                      />
                      <span
                        htmlFor="dings"
                        className="ml-3 block text-sm font-normal text-gray-400"
                      >
                        Dings
                      </span>
                    </label>
                  </div>
                </div>

                {/* bulges checkbox */}
                <div className=" flex items-start lg:w-1/4">
                  <div className="flex h-5 items-center">
                    <input
                      id="bulges"
                      aria-describedby="bulges-description"
                      name="bulges"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          bulges: e.target.checked,
                        });
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="bulges">Bulges</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        {/* Windsheild question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Please select the windsheild needs to be replaced?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">Windsheild</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {windsheild.map((windsheild) => (
                <div key={windsheild.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={windsheild.id}
                      name="windsheild"
                      type="radio"
                      defaultChecked={windsheild.id === "q4no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          windsheildState: windsheild.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={windsheild.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {windsheild.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Seat burns question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Do you have any seat burns, rips or tears?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">seat burns</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {seatBurns.map((seatBurns) => (
                <div key={seatBurns.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={seatBurns.id}
                      name="seatBurns"
                      type="radio"
                      defaultChecked={seatBurns.id === "q5no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          seatBurnsState: seatBurns.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={seatBurns.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {seatBurns.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Smoked question radio buttons */}
        <div className="text-sm text-gray-400 w-11/12 sm:w-3/5 h-fit border-2 border-gray-100 mt-5 rounded-lg p-5">
          <span className="text-sm text-gray-400">
            Has the vehicle been smoked in?
            <Asterisk />
          </span>
          <fieldset className="mt-4">
            <legend className="sr-only">smoked</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {smoked.map((smoked) => (
                <div key={smoked.id} className="flex items-center sm:w-1/4">
                  <label className="flex items-center">
                    <input
                      id={smoked.id}
                      name="smoked"
                      type="radio"
                      defaultChecked={smoked.id === "q6no"}
                      className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          smokedState: smoked.title,
                        });
                      }}
                    />
                    <span
                      htmlFor={smoked.id}
                      className="ml-3 block text-sm font-normal text-gray-400"
                    >
                      {smoked.title}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {/* Back and Next buttons on the bottom*/}
        <div className="flex justify-between sm:justify-end w-11/12 sm:w-3/5 mt-8 mb-24">
          <Button
            label="Back"
            className="mr-8"
            onClick={() => stateChanger(0)}
            type="secondary"
          />

          <Button
            label="Next"
            onClick={() => {
              if (anythingMissing) {
                missingFields();
              } else {
                stateChanger(2);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
