import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { unarchivePost } from "../services/firebase";
import { ReactComponent as ActionsSVG } from "../assets/SVG/Actions.svg";
import { ArchivePostModal } from "./ArchivePostModal";
import { DeletePostModal } from "./DeletePostModal";

// appears whenever user clicks on the dropdown (3-dots) graphic on the main landing page, to view actions he can perform
export const ActionList = ({ post, setPost }) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const turnUnarchive = async () => {
    post.archived = false;
    setPost({
      ...post,
      archived: false,
    });
    await unarchivePost(post?.docRef);
  };

  // show archive post modal
  const openArchiveModal = () => {
    setShowArchiveModal(true);
  };

  // show delete post modal
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  if (post?.archived) {
    return (
      <button
        className="w-32 h-10 rounded-lg bg-gray-100 text-sm text-gray-400 font-bold"
        onClick={async () => await turnUnarchive()}
      >
        Unarchive
      </button>
    );
  } else {
    return (
      <div>
        <Menu as="div" className="relative inline-block text-left">
          {/* dropdown menu button graphic*/}
          <div>
            <Menu.Button>
              {/* actions svg on 'ALL Cars / View Post' page */}
              <ActionsSVG />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-5 top-5 z-10 mt-2 w-28 origin-top-right rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="my-2 text-sm text-gray-400 font-bold">
                {/* delete post option */}
                <Menu.Item>
                  <button
                    className="w-full text-left hover:bg-gray-100 pl-4 pt-2"
                    onClick={openDeleteModal}
                  >
                    Delete
                  </button>
                </Menu.Item>

                {/* edit post option */}
                <Menu.Item>
                  <Link to="/editPost" state={{ post }}>
                    <button className="w-full text-left hover:bg-gray-100 pl-4 pt-2">
                      Edit
                    </button>
                  </Link>
                </Menu.Item>

                {/* archive/unarchive post option */}
                <Menu.Item>
                  <div>
                    <button
                      className="w-full text-left hover:bg-gray-100 pl-4 py-2"
                      onClick={openArchiveModal}
                    >
                      Archive
                    </button>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <ArchivePostModal
          open={showArchiveModal}
          setOpen={setShowArchiveModal}
          post={post}
          setPost={setPost}
        />
        
        <DeletePostModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          post={post}
          setPost={setPost}
        />
      </div>
    );
  }
};
