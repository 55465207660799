import { CarInfoPage } from "../../components/CarPosting/CarInfo";
import { VehicleDetailsPage } from "../../components/CarPosting/VehicleDetails";
import { AdditionalDetailsPage } from "../../components/CarPosting/AdditionalDetails";
import { UploadPhotosPage } from "./UploadPhotos";
import { ConfirmationPage } from "./Confirmation";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCarDetails } from "../../redux/slices/comSlice";

export const NewPost = () => {
  const dispatch = useDispatch();
  const carDetails = useSelector((state) => state.com.carDetails);

  const [page, setPage] = useState(0);
  const [width, setWidth] = useState("30%");

  const updateFormData = (payload) => {
    dispatch(setCarDetails(payload));
  };

  // switching between pages to be visible based on user intercations
  const conditionalRendering = () => {
    switch (page) {
      // showing car info page, that have license plate and VIN interfaces in it
      case 0:
        return (
          <CarInfoPage
            calledFrom={"/landingPage"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing vehicle details page to user, first step in sign up
      case 1:
        return (
          <VehicleDetailsPage
            calledFrom={"/landingPage"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing additional details page to user, second step in sign up
      case 2:
        return (
          <AdditionalDetailsPage
            calledFrom={"/landingPage"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing images uploading page to user, third step in sign up
      case 3:
        return (
          <UploadPhotosPage
            calledFrom={"/landingPage"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing confirmation page to user, last step in sign up
      case 4:
        return <ConfirmationPage />;
      // going back to car info page, first step in sign up
      default:
        return <CarInfoPage stateChanger={setPage} />;
    }
  };

  // settting value for progress indicator
  useEffect(() => {
    if (page === 0 || page === 1 || page === 2 || page === 3) {
      setWidth("45%");
    } else if (page === 4) {
      setWidth("100%");
    }
  }, [page]);

  return (
    <>
      {/* Multi-step form */}
      {conditionalRendering()}

      {/* Progress indicator */}
      <ProgressIndicator sections={2} width={width} />
    </>
  );
};
