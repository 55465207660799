import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ReactComponent as NextImageSVG } from "../../../assets/SVG/ImageNext.svg";
import { ReactComponent as PreviousImageSVG } from "../../../assets/SVG/ImagePrevious.svg";
import { ReactComponent as CloseGallerySVG } from "../../../assets/SVG/CloseGallery.svg";
import PLImage from "../../../assets/images/bg.png";

const AllImagesModal = ({ images }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // getting width of browser's internal window
  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <button
        onClick={onClick}
        style={{
          ...style,
          top: windowWidth > 640 ? "50%" : "110%",
          left: windowWidth > 640 ? "-12%" : "-2%",
          position: "absolute",
        }}
      >
        <PreviousImageSVG />
      </button>
    );
  }
  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <button
        onClick={onClick}
        style={{
          ...style,
          top: windowWidth > 640 ? "50%" : "110%",
          right: windowWidth > 640 ? "-12%" : "-2%",
          position: "absolute",
        }}
      >
        <NextImageSVG />
      </button>
    );
  }

  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    beforeChange: (current, next) => setCurrentImage(current),
  };
  return (
    <>
      <div className="w-full h-full relative rounded-lg">
        <img
          src={PLImage}
          alt="sample"
          className="inset-0 h-full w-full object-cover object-center rounded-lg opacity-75 hover:opacity-100 "
        />
        <div
          onClick={() => setShowImageModal(true)}
          className="absolute rounded-lg w-full h-full backdrop-blur-md hover:backdrop-blur-sm cursor-pointer flex flex-col justify-center items-center top-0"
        >
          <h1 className="absolute xl:text-2xl 2xl:text-3xl lg:text-xl md:text-sm text-white">
            All Images
          </h1>
        </div>
      </div>

      {showImageModal ? (
        <>
          <div
            className="fixed z-10 overflow-y-auto top-0 left-0 w-full"
            id="modal"
          >
            <div className="flex items-center justify-center pt-4 px-4 pb-20 h-full text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block h-screen align-center bg-white text-left overflow-hidden shadow-xl transform transition-all w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="flex text-center p-5 rounded-t flex-col">
                  <div className="w-full p-5">
                    <p className="float-left">
                      <strong>{currentImage + 1}</strong>/{images.length}
                    </p>
                    <button
                      type="button"
                      className="text-gray-400 rounded-lg bg-gray-50 hover:bg-gray-100 flex items-center justify-center py-3 px-6 float-right"
                      data-modal-toggle="small-modal"
                      onClick={() => {
                        setShowImageModal(false);
                      }}
                    >
                      <CloseGallerySVG />
                      <span className="pl-2 text-sm font-bold">Close</span>
                    </button>
                  </div>
                  <div
                    className="pt-5"
                    style={{
                      width: "75%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Slider {...settings}>
                        {images.map((img) => {
                          return (
                            <div key={img}>
                              <img
                                className="w-full aspect-video rounded-xl"
                                alt=""
                                src={img}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AllImagesModal;
