import { TopBar } from "../../components/TopBar";
import { ReactComponent as PersonSVG } from "../../assets/SVG/Person.svg";
import { ReactComponent as ContactSVG } from "../../assets/SVG/Contact.svg";
import { ReactComponent as EmailSVG } from "../../assets/SVG/Email.svg";
import { ReactComponent as PasswordSVG } from "../../assets/SVG/Password.svg";
import { ReactComponent as HidePasswordSVG } from "../../assets/SVG/HidePassword.svg";
import { ReactComponent as ShowPasswordSVG } from "../../assets/SVG/ShowPassword.svg";
import { ReactComponent as AddressLocationSVG } from "../../assets/SVG/addressLocation.svg";
import { useEffect, useState } from "react";
import {
  signup,
  createSeller,
  createPost,
  uploadImages,
} from "../../services/firebase";
import { sendEmailVerification } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import {
  setSessionEmail,
  setUserId,
  setUserType,
} from "../../redux/slices/userSlice";
import { placePrediction } from "../../common/maps";
import { Combobox } from "@headlessui/react";
import { setPostId } from "../../redux/slices/postSlice";

export const PersonalDetailsPage = ({
  calledFrom,
  formData,
  setFormData,
  stateChanger,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [successfullSignup, setSuccessfullSignup] = useState(false);
  const [anythingMissing, setAnythingMissing] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showLock, setShowLock] = useState(true);
  const [togglePasswordDisplay, setTogglePasswordDisplay] =
    useState("password");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  // Field Focus
  const [nameFocused, setNameFocused] = useState(false);
  const [contactFocused, setContactFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [addressFocused, setAddressFocused] = useState(false);

  // Field Animations
  const [namePlaceholder, setNamePlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [contactPlaceholder, setContactPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [emailPlaceholder, setEmailPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [passwordPlaceholder, setPasswordPlaceHolder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );
  const [addressPlaceholder, setAddressPlaceholder] = useState(
    "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
  );

  // Error Message
  const [errorMessage, setErrorMessage] = useState("");

  // validation alert if required fields are left empty
  const missingFields = () => setErrorMessage("Add missing fields first");

  // validation alert if invalid email address is provided
  const invalidEmail = () => setErrorMessage("Invalid Email address provided");

  // validation alert if already registered email address is provided
  const emailInUse = () =>
    setErrorMessage("The Email you provided is already in use");

  // validation alert if the provided password doesn't follow password setting protocols
  const weakPassword = () =>
    setErrorMessage(
      "Weak password provided. Password should be atleast 6 characters long"
    );

  // validation alert in case of failing to sign up
  const signupFailed = () =>
    setErrorMessage("Signup failed. Please try again.");

  // updating if anything is missing or not, whenever user inputs something
  useEffect(() => {
    if (
      formData.name?.length <= 1 ||
      formData.contactNumber?.length <= 1 ||
      formData.email?.length <= 1 ||
      formData.password?.length <= 1 ||
      formData.address?.length <= 1
    ) {
      setAnythingMissing(true);
    } else {
      setAnythingMissing(false);
    }
  }, [
    formData.name,
    formData.contactNumber,
    formData.email,
    formData.password,
    formData.address,
  ]);

  // scrolling to the top
  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
  }, []);

  // process of user sign up, post creation, and uploading images to the firebase storage (its not in its final form)
  const handleSignup = async () => {
    let response = undefined;
    response = await signup(formData?.email, formData?.password);
    if (!response?.code) {
      sendEmailVerification(response.user);
      // seller data
      const sellerDocRef = uuidv4();
      const user = {
        name: formData?.name,
        contactNumber: formData?.contactNumber,
        email: formData?.email,
        address: formData?.address,
        placeId: formData?.placeId,
        // zipCode: formData.zipCode,
        zipCode: "test zipcode",
      };

      // car post data
      const postDocRef = uuidv4();
      const postData = {
        plateNumber: formData?.plateNumber,
        registrationPlace: formData?.registrationPlace,
        VIN: formData?.VIN,
        zipCode: formData?.zipCode,
        vehicleDetails: {
          year: formData?.year,
          make: formData?.make,
          model: formData?.model,
          // trim: formData?.trim,
          trim: "benz",
          style: formData?.carStyle,
          drive: formData?.driveType,
          transmission: formData?.transmissionType,
          mileage: formData?.carMileage,
          features: {
            bluetooth: formData?.bluetooth,
            navigation: formData?.navigation,
            speakers: formData?.speakers,
            AC: formData?.AC,
            childLock: formData?.childLock,
            ABS: formData?.ABS,
            wingMirrors: formData?.wingMirrors,
          },

          additionalInformation: {
            accident: formData?.carAccidentState,
            frameDamage: formData?.frameDamageState,
            floodDamage: formData?.floodDamageState,
          },
          carImages: formData?.carImages?.length,
          carImageURLs: {},
          optional: {
            expectedPrice: formData?.expectedPrice,
          },
        },
        postedAt: new Date(),
        exteriorInteriorCondition: {
          dents: formData?.dents,
          dings: formData?.dings,
          scuffs: formData?.scuffs,
          scratches: formData?.scratches,
          chippedPaint: formData?.chippedPaint,
          rust: formData?.rust,
          hailDamage: formData?.hailDamage,
          replaceWindSheild: formData?.windsheildState,
          frameDamage: formData?.frameDamageState,
          seatBurnsRipsTear: formData?.seatBurnsState,
          smokedIn: formData?.smokedState,
        },
        mechanicalCondition: {
          drive: formData?.drive,
          warningLights: formData?.warningLights,
          ifWarningLights: formData?.ifWarningLights,
          odometerReplace: formData?.odometerReplaced,
          repairsNeeded: formData?.repairsNeeded,
          ifRepairsNeeded: formData?.ifRepairsNeeded,
        },
        additionalInformation: {
          milesOnTyres: formData?.milesOnTyres,
          keys: formData?.keys,
          aftermarksParts: formData?.aftermarksParts,
          newVehicle: formData?.newVehicle,
        },
        postedBy: {
          id: sellerDocRef,
          name: formData?.name,
          contactNumber: formData?.contactNumber,
          email: formData?.email,
          placeId: formData?.placeId,
          zipCode: "12345",
          address: formData?.address,
        },
        removed: false,
        removeReason: "",
        archived: false,
      };

      if (response !== undefined) {
        try {
          await createSeller(user, sellerDocRef);
          await createPost(postData, postDocRef);
          await uploadImages(formData.carImages, postDocRef);
          setSuccessfullSignup(true);
          setAnythingMissing(false);
          setShowLoader(false);
          dispatch(setUserId(sellerDocRef));
          dispatch(setUserType("seller"));
          dispatch(setSessionEmail(""));
          dispatch(setPostId(postDocRef));
          stateChanger(5);
        } catch (error) {
          console.log("Something went wrong while doing sellerSignup: ", error);
          signupFailed();
        }
      }
    } else {
      setShowLoader(false);
      setSuccessfullSignup(false);
      if (response.code === "auth/invalid-email") {
        invalidEmail();
      } else if (response.code === "auth/email-already-in-use") {
        emailInUse();
      } else if (response.code === "auth/weak-password") {
        weakPassword();
      } else {
        signupFailed();
      }
      console.log("Error: ", response.code);
    }
  };

  const fetchLocations = async (e) => {
    if (e.length < 4) return;

    const p = await placePrediction(e);
    setLocations(p);
  };

  const selectLocation = (e) => {
    if (!e) return;
    setSelectedLocation(e);
    setFormData({
      ...formData,
      address: e.desc,
      placeId: e.placeId,
    });
  };

  return (
    <div>
      {/* Logo and Cancel button */}
      <TopBar calledFrom={calledFrom} />

      <div className="h-screen flex items-center justify-center my-40 md:my-64 lg:my-44 2xl:my-20">
        <div className="flex flex-col items-center">
          {/* Headlines */}
          <span className="text-4xl font-bold w-96 text-center">
            Tell us about yourself
          </span>
          <span className="font-normal text-sm text-center text-gray-400 my-4">
            Kindly fill your personal details so dealers can contact you
          </span>

          {/* Name text input field  */}
          <div className="items-center justify-center flex w-96 h-20 border-2 border-gray-100 mt-6 rounded-lg py-2 px-4">
            <div>
              <span className={namePlaceholder} style={{ zIndex: "-1" }}>
                Name
              </span>
              <input
                onBlur={() => {
                  setNameFocused(false);
                  if (name.length >= 1) {
                    setNamePlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setNamePlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setNameFocused(true);
                  setNamePlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type="text"
                title="Name"
                onChange={(e) => {
                  setName(e.target.value);
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            {/* person svg in the name field */}
            <div>
              <PersonSVG
                fill={nameFocused || name.length > 0 ? "black" : "#9A9EA7"}
              />
            </div>
          </div>

          {/* Contact number input field  */}
          <div className="items-center justify-center flex w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
            <div>
              <span className={contactPlaceholder} style={{ zIndex: "-1" }}>
                Contact No
              </span>
              <input
                onBlur={() => {
                  setContactFocused(false);
                  if (contact.length >= 1) {
                    setContactPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setContactPlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setContactFocused(true);
                  setContactPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                onInput={(e) => {
                  const value = e.target.value;
                  let validatedValue = value.replace(/[^- +()0-9]/g, "");

                  if (
                    validatedValue.length === 1 &&
                    validatedValue[0] !== "+"
                  ) {
                    validatedValue = `+1 (${validatedValue}`;
                  }

                  if (
                    validatedValue.length === 8 &&
                    validatedValue[7] !== ")"
                  ) {
                    validatedValue = `${validatedValue.slice(
                      0,
                      7
                    )}) ${validatedValue.slice(7)}`;
                  }

                  if (
                    validatedValue.length === 13 &&
                    validatedValue[12] !== " "
                  ) {
                    validatedValue = `${validatedValue.slice(
                      0,
                      12
                    )} ${validatedValue.slice(12)}`;
                  }

                  if (validatedValue.length > 17) {
                    validatedValue = validatedValue.slice(0, 17);
                  }

                  e.target.value = validatedValue;
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type="text"
                title="Contact-No"
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                  setFormData({
                    ...formData,
                    contactNumber: e.target.value,
                  });
                }}
              />
            </div>
            {/* contact svg in the contact number field */}
            <div>
              <ContactSVG
                fill={
                  contactFocused || contact.length > 0 ? "black" : "#9A9EA7"
                }
              />
            </div>
          </div>

          {/* Email ID text input field  */}
          <div className="items-center justify-center flex w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
            <div>
              <span className={emailPlaceholder} style={{ zIndex: "-1" }}>
                Email
              </span>
              <input
                onBlur={() => {
                  setEmailFocused(false);
                  if (email.length >= 1) {
                    setEmailPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setEmailPlaceHolder(
                      "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setEmailFocused(true);
                  setEmailPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type="text"
                title="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            {/* email svg in the email field */}
            <div>
              <EmailSVG
                fill={emailFocused || email.length > 0 ? "black" : "#9A9EA7"}
              />
            </div>
          </div>

          {/* Password text input field  */}
          <div className="items-center justify-center flex w-96 h-20 border-2 border-gray-100 mt-4 rounded-lg py-2 px-4">
            <div>
              <span className={passwordPlaceholder} style={{ zIndex: "-1" }}>
                Password
              </span>
              <input
                onBlur={() => {
                  setPasswordFocused(false);
                  if (password.length >= 1) {
                    setPasswordPlaceHolder(
                      "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  } else {
                    setPasswordPlaceHolder(
                      "absolute  font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                    );
                  }
                }}
                onFocus={() => {
                  setPasswordFocused(true);
                  setPasswordPlaceHolder(
                    "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                  );
                }}
                style={{ background: "transparent" }}
                className="mt-2 w-80 text-sm font-bold text-black focus:outline-0"
                type={togglePasswordDisplay}
                onChange={(e) => {
                  if (e.target?.value?.length < 1) {
                    setShowLock(true);
                  } else {
                    setShowLock(false);
                  }
                  setPassword(e.target.value);
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });
                }}
              />
            </div>

            {/* password svgs in the password field */}
            {showLock ? (
              <PasswordSVG
                fill={
                  passwordFocused || password.length > 0 ? "black" : "#9A9EA7"
                }
              />
            ) : (
              <>
                {togglePasswordDisplay === "password" ? (
                  <HidePasswordSVG
                    onClick={() => setTogglePasswordDisplay("text")}
                  />
                ) : (
                  <ShowPasswordSVG
                    onClick={() => setTogglePasswordDisplay("password")}
                  />
                )}
              </>
            )}
          </div>

          {/* Seller address input field */}
          <Combobox value={selectedLocation} onChange={selectLocation}>
            <div className="items-center justify-center flex w-96 h-20 border-2 border-gray-100 mt-2 rounded-lg py-2 px-6">
              <div>
                <Combobox.Label
                  className={addressPlaceholder}
                  style={{ zIndex: "-1" }}
                >
                  Address
                </Combobox.Label>
                <Combobox.Input
                  onBlur={() => {
                    setAddressFocused(false);
                    if (address.length >= 1) {
                      setAddressPlaceholder(
                        "absolute transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                      );
                    } else {
                      setAddressPlaceholder(
                        "absolute font-bold transition-all duration-300 ease-in-out text-md text-gray-400 translate-y-0"
                      );
                    }
                  }}
                  onFocus={() => {
                    setAddressFocused(true);
                    setAddressPlaceholder(
                      "absolute transition-all duration-200 ease-in-out text-md text-gray-400 translate-y-[-1.25rem]"
                    );
                  }}
                  style={{ background: "transparent" }}
                  className={`mt-2 w-80 text-sm font-bold ${
                    address.length === 0 ? "text-transparent" : "text-black"
                  } focus:outline-0`}
                  label="address"
                  onChange={(event) => {
                    setAddress(event.target.value);
                    fetchLocations(event.target.value);
                  }}
                  displayValue={(location) => location?.desc}
                />
              </div>
              {/* location icon */}
              <AddressLocationSVG
                fill={
                  addressFocused || address.length > 0 ? "black" : "#9A9EA7"
                }
              />
            </div>
            {locations && (
              <Combobox.Options className="mt-1 max-h-60 w-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {locations.map((location, i) => (
                  <Combobox.Option
                    key={i}
                    className="select-none py-2 pl-10 pr-4"
                    value={location}
                  >
                    {location.desc}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </Combobox>

          {/* Error Message */}
          <div className="w-96 my-2">
            <p style={{ color: "red", fontSize: "15px" }}>{errorMessage}</p>
          </div>

          {/* Back and Next buttons on the bottom*/}
          <div className="flex justify-between w-96 mt-12">
            <Button
              label="Back"
              className="mr-8"
              onClick={() => stateChanger(3)}
              type="secondary"
            />

            <Button
              label="Next"
              className="flex justify-center items-center"
              onClick={async () => {
                if (anythingMissing) {
                  missingFields();
                } else {
                  setShowLoader(true);
                  await handleSignup();
                  if (!successfullSignup) {
                    setShowLoader(false);
                  }
                }
              }}
              loader={showLoader}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
