import { Link } from "react-router-dom";
import { ReactComponent as ConfirmationSVG } from "../../assets/SVG/Confirmation.svg";
import { ReactComponent as VerificationSVG } from "../../assets/SVG/Verification.svg";
import Logo from "../../assets/images/Logo.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { verifyUserEmail } from "../../services/firebase";

export const ConfirmationPage = () => {
  const docRef = useSelector((state) => state.post.postId);
  const userType = useSelector((state) => state.user.userType);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const mode = queryParams.get("mode");
      const oobCode = queryParams.get("oobCode");
      if (mode === "verifyEmail") {
        const response = await verifyUserEmail(oobCode);
        setError(!response);
        setLoading(false);
      } else if (mode === "resetPassword") {
        const url = window.location.search;
        const params = url.split("?")[1];
        window.location.href = `https://my-top-dollar.firebaseapp.com/__/auth/action?${params}`;
        return;
      }
      // TODO: handle password reset scenario
    };

    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Logo */}
      <div className="absolute top-0 left-0 mt-6 sm:mt-10 ml-6 sm:ml-32">
        <img src={Logo} alt="logo" />
      </div>

      <div className="my-20 sm:my-0 flex flex-col justify-center items-center h-screen">
        {loading ? (
          <span className="text-4xl font-bold w-96 mt-8 text-center">
            Verifying...
          </span>
        ) : (
          <>
            {/* Graphics on top */}
            {error ? <VerificationSVG /> : <ConfirmationSVG />}

            {/* Headlines */}
            <span className="text-4xl font-bold w-96 mt-8 text-center">
              {!error
                ? "Email Verified Successfuly"
                : "Error verifying Email. Please try again"}
            </span>
            {!error && (
              <div className="flex flex-col text-center">
                <span className="font-normal text-sm text-center w-96 text-gray-400 p-4 mt-2">
                  {userType === "seller"
                    ? "Your car post is created."
                    : "Your account has been successfully created please continue!."}
                </span>

                {/* View post button on the bottom */}
                <Link
                  to={userType === "seller" ? "/viewPost" : "/dealer/dashboard"}
                  state={{ docRef }}
                >
                  <button className="mt-8 w-11/12 sm:w-96 h-14 rounded-lg bg-blue-500 text-sm text-white font-bold mb-24">
                    {userType === "seller" ? "View posted car" : "continue"}
                  </button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
