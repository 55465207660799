import { CarInfoPage } from "../../components/CarPosting/CarInfo";
import { VehicleDetailsPage } from "../../components/CarPosting/VehicleDetails";
import { AdditionalDetailsPage } from "../../components/CarPosting/AdditionalDetails";
import { UploadPhotosPage } from "./UploadPhotos";
import { PersonalDetailsPage } from "./PersonalDetails";
import { ConfirmationPage } from "./Confirmation";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCarDetails } from "../../redux/slices/comSlice";
import { VerificationPage } from "./Verification";

export const SignUp = () => {
  const dispatch = useDispatch();

  const carDetails = useSelector((state) => state.com.carDetails);

  const [page, setPage] = useState(0);
  const [width, setWidth] = useState("30%");

  const updateFormData = (payload) => {
    dispatch(setCarDetails(payload));
  };

  // switching between pages to be visible based on user intercations
  const conditionalRendering = () => {
    switch (page) {
      // showing car info page, that have license plate and VIN interfaces in it
      case 0:
        return (
          <CarInfoPage
            calledFrom={"/"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing vehicle details page to user, first step in sign up
      case 1:
        return (
          <VehicleDetailsPage
            calledFrom={"/"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing additional details page to user, second step in sign up
      case 2:
        return (
          <AdditionalDetailsPage
            calledFrom={"/"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing images uploading page to user, third step in sign up
      case 3:
        return (
          <UploadPhotosPage
            calledFrom={"/"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing personal details page to user, fourth step in sign up
      case 4:
        return (
          <PersonalDetailsPage
            calledFrom={"/"}
            formData={carDetails}
            setFormData={updateFormData}
            stateChanger={setPage}
          />
        );
      // showing verfication page to user, fifth step in sign up
      case 5:
        return <VerificationPage formData={carDetails} calledBy={"Seller"} />;
      // showing confirmation page to user, last step in sign up
      case 6:
        return <ConfirmationPage />;
      // going back to car info page, first step in sign up
      default:
        return <CarInfoPage stateChanger={setPage} />;
    }
  };

  // settting value for progress indicator
  useEffect(() => {
    if (page === 0 || page === 1 || page === 2 || page === 3) {
      setWidth("30%");
    } else if (page === 4 || page === 5) {
      setWidth("60%");
    } else if (page === 6) {
      setWidth("100%");
    }
  }, [page]);

  return (
    <>
      {/* Multi-step form */}
      {conditionalRendering()}

      {/* Progress indicator */}
      <ProgressIndicator width={width} />
    </>
  );
};
