import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth } from "../services/firebase";

export const PrivateRoute = ({ children, routeType }) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.user.userId);
  const userType = useSelector((state) => state.user.userType);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user !== null && !user?.emailVerified) {
        navigate("/emailVerification");
      }
      // ToDo: show toast if email is unverified
    });

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (routeType !== userType || userId === "") {
    if (routeType === "seller") {
      return <Navigate to="/login/form" replace />;
    } else if (routeType === "dealer") {
      return <Navigate to="/login/dealerForm" replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  } else if (userType === "seller") {
    return children;
  } else if (userType === "dealer") {
    return children;
  }
};
