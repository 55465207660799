import Modal from "./Modal";
import { deletePost } from "../services/firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "./Loader";

// appears when a user clicks on 'Delete' option from the dropdown menu on viewPost page
export const DeletePostModal = ({ open, setOpen, post, setPost }) => {
  const navigate = useNavigate();

  const [reason1, setReason1] = useState(false);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);
  const [reason4, setReason4] = useState(false);
  const [reason5, setReason5] = useState(false);
  const [showLoader, setShowLoader] = useState("hidden");

  const removePost = async (removeReason) => {
    try {
      setPost({
        ...post,
        removeReason: removeReason,
      });
      post.removed = true;
      setPost({
        ...post,
        removed: true,
      });
      await deletePost(post?.docRef, removeReason);
      setShowLoader("hidden");
      navigate("/landingPage");
    } catch (error) {
      console.log(
        "Something went wrong in components/DeletePostModal/removePost function: ",
        error
      );
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {/* Text lines */}
      <div className="text-center">
        <span className="text-lg font-bold">Delete Post</span>
        <hr className="mt-4 lg:mt-8 w-full text-gray-400" />
      </div>

      {/* checkboxes for deleting reason */}
      <div className="mt-8 space-y-6">
        <label className="flex items-center">
          <input
            id="reason1"
            aria-describedby="reason1"
            name="reason1"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            onChange={() => setReason1(!reason1)}
          />
          <span
            htmlFor="reason1"
            className="ml-3 block text-sm font-normal text-gray-400"
          >
            Found a good dealer through our platform
          </span>
        </label>

        <label className="flex items-center">
          <input
            id="reason2"
            aria-describedby="reason2"
            name="reason2"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            onChange={() => setReason2(!reason2)}
          />
          <span
            htmlFor="reason2"
            className="ml-3 block text-sm font-normal text-gray-400"
          >
            Found a good dealer elsewhere
          </span>
        </label>

        <label className="flex items-center">
          <input
            id="reason3"
            aria-describedby="reason3"
            name="reason3"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            onChange={() => setReason3(!reason3)}
          />
          <span
            htmlFor="reason3"
            className="ml-3 block text-sm font-normal text-gray-400"
          >
            Changed my mind, and not selling right now
          </span>
        </label>

        <label className="flex items-center">
          <input
            id="reason4"
            aria-describedby="reason4"
            name="reason4"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            onChange={() => setReason4(!reason4)}
          />
          <span
            htmlFor="reason4"
            className="ml-3 block text-sm font-normal text-gray-400"
          >
            Didn't receive quotations as per my expectations
          </span>
        </label>

        <label className="flex items-center">
          <input
            id="reason5"
            aria-describedby="reason5"
            name="reason5"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            onChange={() => setReason5(!reason5)}
          />
          <span
            htmlFor="reason5"
            className="ml-3 block text-sm font-normal text-gray-400"
          >
            Didn't like my experience with this platform
          </span>
        </label>
      </div>

      {/* button at the bottom of the modal */}
      <div className="flex mt-8 mb-4 justify-center items-center">
        <button
          className="flex flex-cols bg-blue-500 text-white w-full h-14 rounded-lg text-sm font-bold justify-center items-center"
          onClick={async () => {
            setShowLoader("ml-2");
            let removeReason = "";
            if (reason1) {
              removeReason += "Found a good dealer through our platform, ";
            }
            if (reason2) {
              removeReason += "Found a good dealer elsewhere, ";
            }
            if (reason3) {
              removeReason += "Changed my mind and not selling right now, ";
            }
            if (reason4) {
              removeReason +=
                "Didn't receive quotations as per my expectations, ";
            }
            if (reason5) {
              removeReason += "Didn't like my experience with this platform, ";
            }
            removeReason =
              removeReason.substring(0, removeReason.length - 2) + ".";
            await removePost(removeReason);
          }}
        >
          Confirm Delete
          <Loader showLoader={showLoader} />
        </button>
      </div>
    </Modal>
  );
};
