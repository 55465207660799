import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import { HomePage } from "../pages/Home";
import { SignUp } from "../pages/SignUp";
import { SellerLoginPage } from "../pages/Login/SellerLogin";
import { RecoverPasswordPage } from "../pages/Login/RecoverPassword";
import { LandingPage } from "../pages/LandingPage";
import { ViewPost } from "../pages/ViewPost";
import { EditPost } from "../pages/EditPost";
import { Bids } from "../pages/Bids";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsOfUse } from "../pages/TermsOfUse";
import { DealerSignup } from "../pages/SignUp/DealerSignup";
import { DealerLoginPage } from "../pages/Login/DealerLogin";
import { DealerHome } from "../pages/DealerDashboard/DealerHome";
import { CarDetails } from "../pages/DealerDashboard/CarDetails";
import { NewPost } from "../pages/NewPost";
import { AllBids } from "../pages/AllBids";
import { MyBids } from "../pages/DealerDashboard/MyBids";
import { BuyerDetails } from "../pages/BuyerDetails";
import { PrivateRoute } from "../components/PrivateRoute";
import { Chats } from "../pages/Chat/Chats";
import ChatWindow from "../pages/Chat/ChatWindow";
import Inbox from "../pages/Chat/Inbox";
import { SellerNotifications } from "../pages/SellerNotifications";
import { DealerNotifications } from "../pages/DealerNotifications";
import { ConfirmationPage } from "../pages/SignUp/Confirmation";
import { VerificationPage } from "../pages/SignUp/Verification";
import { useSelector } from "react-redux";

export default function Routing() {
  const userType = useSelector((state) => state.user.userType);

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="dealerSignup" element={<DealerSignup />} />
        <Route path="login">
          <Route path="form" element={<SellerLoginPage />} />
          <Route path="dealerForm" element={<DealerLoginPage />} />
          <Route path="recoverPassword" element={<RecoverPasswordPage />} />
        </Route>
        <Route path="emailConfirmation" element={<ConfirmationPage />} />
        <Route
          path="emailVerification"
          element={<VerificationPage calledBy={userType} />}
        />
        <Route path="dealer">
          <Route
            path="dashboard"
            element={
              <PrivateRoute routeType={"dealer"}>
                <DealerHome />
              </PrivateRoute>
            }
          />
          <Route
            path="carDetails/:id"
            element={
              <PrivateRoute routeType={"dealer"}>
                <CarDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="myBids"
            element={
              <PrivateRoute routeType={"dealer"}>
                <MyBids />
              </PrivateRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <PrivateRoute routeType={"dealer"}>
                <DealerNotifications />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="inbox" element={<Inbox />} />
        <Route
          path="chats"
          element={
            <PrivateRoute>
              <Chats />
            </PrivateRoute>
          }
        />
        <Route
          path="chats/:id"
          element={
            <PrivateRoute>
              <ChatWindow />
            </PrivateRoute>
          }
        />
        <Route
          path="landingPage"
          element={
            <PrivateRoute routeType={"seller"}>
              <LandingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="newPost"
          element={
            <PrivateRoute routeType={"seller"}>
              <NewPost />
            </PrivateRoute>
          }
        />
        <Route
          path="viewPost"
          element={
            <PrivateRoute routeType={"seller"}>
              <ViewPost />
            </PrivateRoute>
          }
        />
        <Route
          path="editPost"
          element={
            <PrivateRoute routeType={"seller"}>
              <EditPost />
            </PrivateRoute>
          }
        />
        <Route
          path="bids"
          element={
            <PrivateRoute routeType={"dealer"}>
              <Bids />
            </PrivateRoute>
          }
        />
        <Route
          path="allBids"
          element={
            <PrivateRoute routeType={"seller"}>
              <AllBids />
            </PrivateRoute>
          }
        />
        <Route
          path="seller/notifications"
          element={
            <PrivateRoute routeType={"seller"}>
              <SellerNotifications />
            </PrivateRoute>
          }
        />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="termsOfUse" element={<TermsOfUse />} />
        <Route path="buyerDetails" element={<BuyerDetails />} />
      </Routes>
    </Suspense>
  );
}
