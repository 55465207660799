import React, { useState } from "react";
import UserIcon from "../assets/images/user.png";
import { LogoutModal } from "../components/LogoutModal";
import { Link } from "react-router-dom";
import NotificationIcon from "../assets/SVG/bell.svg";
import { useSelector } from "react-redux";

// appears on top-right of pages, to prvide functionality of logging-out
export const Logout = ({ calledBy }) => {
  const [showSignOut, setShowSignOut] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const notifications = useSelector((state) => state.user.notificationView);

  // show logout modal
  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };

  return (
    <div className="absolute top-9 right-11 space-x-4">
      <Link
        to={`/${calledBy}/notifications`}
        className="inline-flex justify-center rounded-full border-white focus:border-gray-300 bg-white text-sm font-medium text-gray-700 hover:border-2 border-2"
      >
        <img
          alt=""
          src={NotificationIcon}
          className="w-10 rounded-full h-10 p-1"
        />
        {notifications ? (
          <></>
        ) : (
          <>
            <div className="top-0 right-0 -mr-2 -mt-1 w-2 h-2 rounded-full bg-red-300"></div>
            <div
              className={`top-0 right-0 -mr-1 -mt-1 w-2 h-2 rounded-full bg-red-300 ${
                notifications ? "" : "animate-ping"
              }`}
            ></div>
          </>
        )}
      </Link>
      <button
        className="inline-flex justify-center rounded-full border-white focus:border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:border-2 border-2"
        onClick={() => setShowSignOut(!showSignOut)}
      >
        <img alt="" src={UserIcon} className="w-10 h-10" />
      </button>
      {showSignOut === true ? (
        <div
          className="absolute right-5 z-10 w-32 origin-top-right rounded-md bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -mt-1"
          tabIndex="-1"
        >
          <button
            className="text-gray-400 font-bold block w-full px-4 py-3 text-left text-sm hover:text-gray-600"
            onClick={openLogoutModal}
          >
            Logout
          </button>
          <LogoutModal
            open={showLogoutModal}
            setOpen={setShowLogoutModal}
            calledBy={calledBy}
          />
        </div>
      ) : null}
    </div>
  );
};
