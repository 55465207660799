import { Link } from "react-router-dom";
import BgImage from "../assets/images/bg1.png";

export const LoginGraphicsLeft = ({ text, label, path }) => {
  return (
    <div className="hidden lg:block justify-center items-center h-screen">
      {/* Bg image */}
      <img className="w-full h-full p-5 xl:p-8" src={BgImage} alt="logo" />

      {/* Headline on image top */}
      <span className="w-2/5 text-5xl font-bold text-white absolute lg:top-32 lg:left-12 px-10">
        Buy and Sell cars at best prices
      </span>

      {/* Text and button on image bottom */}
      <div className="relative absolute bottom-32 bg-black py-8 xl:-mt-2 2xl:-mt-3 mx-5 xl:mx-8 rounded-bl-3xl rounded-br-3xl">
        <div className="w-full flex justify-between px-10 xl:px-16">
          <span className="text-white text-base font-semibold mt-2">
            {text}
          </span>
          <Link to={path}>
            <button className="sm:w-32 sm:h-10 rounded-lg bg-blue-500 text-sm text-white font-bold">
              {label}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
