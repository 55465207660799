import React from "react";
import { useSelector } from "react-redux";
import { NavigationPanel } from "../components/NavigationPanel";

const MainTemplate = ({ children }) => {
  const userType = useSelector((state) => state.user.userType);
  return (
    <div className="flex h-screen">
      <NavigationPanel user={userType} inbox={true} />

      <div className="flex flex-col w-full">
        <div className="w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default MainTemplate;
